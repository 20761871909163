import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import { clearAuth } from "../../store/modules/auth";
import logo from "../../images/logo2.png";

const Container = styled.div`
  width: 100%;
  background: #495057;
  /* background: linear-gradient(to bottom, #052c33, #282828); */
  height: 56px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 10;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  .menu {
    transition: 0.1s all ease-out;
    color: white;
    width: 80px;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 56px;
    font-size: 14px;
    font-weight: 600;
    &:hover {
      background: #515960;
    }
  }
`;
const Right = styled.div`
  display: flex;
  align-items: center;
`;
const SecondMenu = styled.div`
  transition: 0.15s all ease-out;
  -webkit-transition: 0.15s all ease-out;
  position: absolute;
  top: 56px;
  left: ${props => props.left};
  background: #495057;
  width: 80px;
  z-index: 2;
  opacity: ${props => (props.visible ? "1" : "0.3")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  text-align: center;
  .menu-small {
    width: 100%;
    font-size: 12px;
    padding: 0.5rem 0;
    border-top: 1px solid #495057;
    color: white;
    &:hover {
      background: #515960;
    }
  }
`;

const Header = props => {
  const [mouseOn, setMouseOn] = useState(null);
  const { user } = useSelector(state => state.auth, []);
  const dispatch = useDispatch();
  const { history } = props;
  const { id, level, email } = user;
  const name = user.company;

  return (
    <>
      <Container>
        <Left>
          <Link to="/main/home">
            <img
              src={logo}
              style={{ width: "95px", marginRight: "5px" }}
              alt=""
            />
          </Link>
          <Link to="/main/listpublish">
            <div
              className="menu"
              onMouseOver={() => {
                setMouseOn(1);
              }}
              onMouseOut={() => {
                setMouseOn(null);
              }}
            >
              <div> 협력사관리 </div>
            </div>
          </Link>
          <Link to="/main/liststore">
            <div className="menu">
              <div>가맹점관리</div>
            </div>
          </Link>
          <div
            className="menu"
            onMouseOver={() => {
              setMouseOn(3);
            }}
            onMouseOut={() => {
              setMouseOn(null);
            }}
          >
            <div>주문관리</div>

            <SecondMenu visible={mouseOn === 3} left="280px">
              <Link to="/main/ordermonth">
                <div className="menu-small">주문조회</div>
              </Link>
              <Link to="/main/orderpub">
                <div className="menu-small">협력사통계</div>
              </Link>
              <Link to="/main/orderstore">
                <div className="menu-small">가맹점통계</div>
              </Link>
            </SecondMenu>
          </div>

          <div
            className="menu"
            onMouseOver={() => {
              setMouseOn(4);
            }}
            onMouseOut={() => {
              setMouseOn(null);
            }}
          >
            <div>게시판</div>
            <SecondMenu visible={mouseOn === 4} left="360px">
              <Link to="/main/notice">
                <div className="menu-small">공지사항</div>
              </Link>
              <Link to="/main/ask">
                <div className="menu-small">문의사항</div>
              </Link>
            </SecondMenu>
          </div>
          {user.level === "관리자" && (
            <Link to="/main/admin">
              <div className="menu">
                <div>어드민</div>
              </div>
            </Link>
          )}
        </Left>
        <Right>
          <div
            style={{
              fontWeight: 600,
              color: "white",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              height: "50px"
            }}
            onMouseOver={() => {
              setMouseOn(5);
            }}
            onMouseOut={() => {
              setMouseOn(null);
            }}
          >
            <Icon name="user circle" style={{ fontSize: "18px" }} />{" "}
            <span>{id}</span> <Icon name="caret down" />
            <SecondMenu
              visible={mouseOn === 5}
              style={{ right: 0, width: "fit-content", minWidth: "120px" }}
            >
              <div style={{ padding: "1rem 1rem", fontSize: "12px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Icon name="user circle" style={{ fontSize: "20px" }} />
                  <div style={{ textAlign: "left" }}>
                    <div>
                      [{level}] {name}
                    </div>
                    <div>{id}</div>
                    <div>{email}</div>
                    <Link to={`/main/publish/${id}`}>프로필 수정</Link>
                  </div>
                </div>
              </div>
              <Link to="/main/changepw">
                <div className="menu-small">
                  <Icon name="key" />
                  비밀번호변경
                </div>
              </Link>

              <div
                className="menu-small"
                onClick={() => {
                  dispatch(clearAuth());
                  history.push("/");
                }}
              >
                <Icon name="sign-out" />
                로그아웃
              </div>
            </SecondMenu>
          </div>
        </Right>
      </Container>
    </>
  );
};

export default Header;
