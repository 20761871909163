import React from "react";
import styled from "styled-components";
import logo from "../../images/logo_black.png";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1.3px solid #dfdfdf;
  padding: 1.2rem 1rem;
  color: grey;
  font-size: 11px;
  position: fixed;
  bottom: 0;
  z-index: 10;
  background: #f4f8f9;
`;

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Container>
      <div
        style={{
          margin: "0 auto",
          fontSize: "13px",
          textAlign: "left",
          marginBottom: "1rem",
          lineHeight: 1.25
        }}
      >
        <h5>Contact Us</h5>
        <b> 퍼블릭스 주식회사 (대표 정성진) · K배달 관리자시스템</b>
        <br /> 사업자등록번호 163-88-00051 · 통신판매업신고번호 제
        2019-서울강남-00508호 <br /> 서울특별시 강남구 테헤란로 79길 6 3층 | 3F
        6, Teheran-ro 79-gil, Gangnam-gu, Seoul
        <br />
        Tel 02-577-0420 · Fax 050-4014-5898 · E-Mail tech@publics.co.kr <br />
        K배달 관리자시스템은 크롬(Chrome) 브라우저에 최적화 되어있습니다.
        <div
          style={{
            marginTop: "-35px"
          }}
        />
        <br />
      </div>
      <a href="http://publics.co.kr/" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="" style={{ width: "130px" }} />
      </a>

      <div
        style={{
          fontSize: "10px",
          marginTop: "-15px",
          marginBottom: "6px",
          lineHeight: 1.25
        }}
      >
        Copyright © {year} Publics Inc. All Right Reserved.
      </div>
    </Container>
  );
};

export default Footer;
