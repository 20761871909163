import React from "react";
import StatisticCharts from "./StatisticCharts";
import styled from "styled-components";
import { withFirebase } from "../../firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`;

const StatisticRegion = props => {
  const { user } = props;

  return (
    <Container>
      {user.level !== "관리자" ? (
        <div>접근 권한이 없습니다.</div>
      ) : (
        <StatisticCharts />
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(StatisticRegion);
