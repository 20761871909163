import React, { Component } from "react";
import styled from "styled-components";
import { Button, Icon, Loader } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import Table from "../../components/Table/Table";
import { sortBy } from "lodash";

const Container = styled.div`
  width: 85%;
  margin-bottom: 2rem;
  padding: 3rem 2rem;
  .flex {
    display: flex;
    align-items: center;
  }
`;

class Ask extends Component {
  state = {
    data: null
  };
  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { user, firebase } = this.props;

    const setData = snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id });
      });
      this.setState({
        data: sortBy(data, ["date"]).reverse()
      });
    };

    // 관리자용 전체
    if (user.level === "관리자" || user.level === "본사") {
      firebase.db
        .collection("ask")
        .get()
        .then(snapshots => {
          setData(snapshots);
        });
    } else {
      // 나머지는 본인것만 조회
      firebase.db
        .collection("ask")
        .where("userId", "==", user.id)
        .get()
        .then(snapshots => {
          setData(snapshots);
        });
    }
  };

  render() {
    const { data } = this.state;
    return (
      <Container>
        <div
          style={{
            fontSize: "16px",
            marginBottom: "2rem",
            fontWeight: "bold",
            color: "#0c8599",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div>
            <Icon name="question circle" style={{ marginRight: "1rem" }} />
            <span>문의사항</span>
          </div>
        </div>

        <div
          className="flex"
          style={{ justifyContent: "flex-end", marginBottom: "1rem" }}
        >
          <Link to="/main/addask">
            <Button size="small" style={{ fontWeight: "600" }}>
              <Icon name="add" /> 글쓰기
            </Button>
          </Link>
        </div>
        {data ? (
          <Table
            data={data}
            index
            perPage={15}
            headers={[
              {
                title: "제목",
                value: "title",
                render: item => (
                  <Link to={`/main/ask/${item.id}`}>{item.title}</Link>
                )
              },
              {
                title: "작성자",
                value: "user",
                width: 120
              },
              {
                title: "작성일",
                notSearch: true,
                width: 120,
                value: "date",
                render: item => item.date.toDate().toLocaleDateString()
              },
              {
                title: "처리상태",
                notSearch: true,
                align: "center",
                value: "status",
                width: 80,
                render: item =>
                  item.comments && item.comments.length > 0
                    ? "답변완료"
                    : "미처리"
              }
            ]}
          />
        ) : (
          // <Table celled style={{ textAlign: "center" }}>
          //   <Table.Header>
          //     <Table.Row>
          //       <Table.HeaderCell width={1}>No.</Table.HeaderCell>
          //       <Table.HeaderCell width={7}>제목</Table.HeaderCell>
          //       <Table.HeaderCell width={2}>작성자</Table.HeaderCell>
          //       <Table.HeaderCell width={4}>작성일</Table.HeaderCell>
          //       <Table.HeaderCell width={1}>처리상태</Table.HeaderCell>
          //     </Table.Row>
          //   </Table.Header>

          //   <Table.Body>
          //     {data.map((item, index) => (
          //       <Row
          //         key={index}
          //         onClick={() => {
          //           this.props.history.push(`/main/ask/${item.id}`);
          //         }}
          //       >
          //         <Table.Cell>{data.length - index}</Table.Cell>
          //         <Table.Cell>{item.title}</Table.Cell>
          //         <Table.Cell>{item.user}</Table.Cell>
          //         <Table.Cell>
          //           {item.date.toDate().toLocaleDateString()}
          //         </Table.Cell>
          //         <Table.Cell>
          //           {item.comments && item.comments.length > 0
          //             ? "답변완료"
          //             : "미처리"}
          //         </Table.Cell>
          //       </Row>
          //     ))}
          //   </Table.Body>

          //   <Table.Footer>
          //     <Table.Row>
          //       <Table.HeaderCell
          //         colSpan="10"
          //         style={{ textAlign: "center" }}
          //       />
          //     </Table.Row>
          //   </Table.Footer>
          // </Table>
          <Loader active />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(Ask);
