import React from "react";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  background: white;
  border-radius: 4px;
  margin-top: 1rem;
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #dfdfdf;
  .th {
    background: #f9fafb;
    padding: 0.3rem 0.5rem;
    font-size: 13px;
    width: 6rem;
  }
  .td {
    padding: 0.3rem 0.5rem;
    color: black;

    width: 6rem;
  }
  .orange {
    color: #e34e4b;
  }
  .hov {
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .title {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background: #f9fafb;
  }
`;

const CustomTable = ({ data, title }) => {
  return (
    <Container>
      <div className="title">{title}</div>
      {data &&
        data.map((item, index) => (
          <div className="hov" key={index}>
            <div className="th">{item.title}</div>
            <div className={index !== data.length - 1 ? "td" : "td orange"}>
              {item.value}
            </div>
          </div>
        ))}
    </Container>
  );
};

export default CustomTable;
