import React, { useEffect, useState, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Table from '../../components/Table/Table';
import CustomTable from '../../components/Table/CustomTable';
import { Icon, Loader, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';
import { formatPhone } from '../../common/format';
import { jsonToSheet } from '../Admin/Admin';

const Container = styled.div`
  width: 96%;
  padding: 3rem 2%;
  .flex {
    display: flex;
    align-items: center;
  }
  .header {
    font-weight: bold;
  }
`;

const OrderPublish = props => {
  const { db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);
  const [data, setData] = useState(null);
  const { history } = props;

  useEffect(() => {
    const loadUserData = () => {
      return db
        .collection('users')
        .get()
        .then(snapshots => {
          let data = [];
          snapshots.forEach(doc => data.push({ ...doc.data(), pw: '' }));

          // const headQuarterList = data
          //   .filter(item => item.level === "본사")
          //   .filter(item => item.id === user.id);
          // const publisherList = data.filter(item => item.level === "총판");
          let filtered = data
            .filter(item => item.level !== '관리자')
            .filter(item => item.level !== '본사');

          if (user.level === '본사') {
            filtered = filtered.filter(item => item.headquarter.id === user.id);
          } else if (user.level === '총판') {
            filtered = filtered.filter(item => item.publisher.id === user.id);
          }
          return filtered;
        });
    };

    const loadStatisticData = () => {
      const today = new Date();
      const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
      return db
        .collection('statistic')
        .where('date', '>=', yesterday)
        .get()
        .then(snapshots => {
          let statisticData = [];
          snapshots.forEach(doc => statisticData.push(doc.data()));
          return {
            todayData: statisticData.filter(
              item => item.date.toDate().getDate() === today.getDate()
            ),
            yesterdayData: statisticData.filter(
              item => item.date.toDate().getDate() !== today.getDate()
            )
          };
        });
    };

    const loadCounterData = () => {
      return db
        .collection('counters')
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push(doc.data()));
          return result;
        });
    };

    const countData = (data, id, type) => {
      const countData = data.filter(item => item.id === id);
      const date = new Date();

      if (type === 'all') {
        let counter = 0;
        let counter_app = 0;
        countData.forEach(item => (counter += item.allCount || 0));
        countData.forEach(item => (counter_app += item.allAppCount || 0));
        return { counter, counter_app };
      } else if (type === 'current') {
        let counter = 0;
        let counter_app = 0;
        let findDate = `${date.getFullYear()}-${date.getMonth() + 1}`;

        countData.forEach(item => item[findDate] && (counter += item[findDate]));
        countData.forEach(
          item => item[findDate + '-APP'] && (counter_app += item[findDate + '-APP'])
        );

        return { counter, counter_app };
      } else if (type === 'one') {
        let counter = 0;
        let counter_app = 0;
        let findDate;
        if (date.getMonth() === 0) {
          // 1월 일 경우 년도를 전년도로 설정
          findDate = `${date.getFullYear() - 1}-12`;
        } else {
          findDate = `${date.getFullYear()}-${date.getMonth()}`;
        }

        console.log(findDate);

        countData.forEach(item => item[findDate] && (counter += item[findDate]));
        countData.forEach(
          item => item[findDate + '-APP'] && (counter_app += item[findDate + '-APP'])
        );
        return { counter, counter_app };
      } else if (type === 'two') {
        let counter = 0;
        let counter_app = 0;
        let findDate;
        if (date.getMonth() === 0) {
          // 1월 일 경우 년도를 전년도로 설정
          findDate = `${date.getFullYear() - 1}-11`;
        } else {
          findDate = `${date.getFullYear()}-${date.getMonth() - 1}`;
        }

        countData.forEach(item => item[findDate] && (counter += item[findDate]));
        countData.forEach(
          item => item[findDate + '-APP'] && (counter_app += item[findDate + '-APP'])
        );
        return { counter, counter_app };
      }
    };

    const combineData = (userData, counterData, yesterdayData, todayData) => {
      const newData = [];
      userData &&
        userData.forEach(item =>
          newData.push({
            ...item,
            one: countData(counterData, item.id, 'one'),
            two: countData(counterData, item.id, 'two'),
            current: countData(counterData, item.id, 'current'),
            all: countData(counterData, item.id, 'all'),
            today: todayData.filter(doc => item.id === doc.publisher.id).length || 0,
            today_app:
              todayData
                .filter(doc => item.id === doc.publisher.id)
                .filter(call => call.callType === 'app').length || 0,
            yesterday:
              yesterdayData
                .filter(doc => item.id === doc.publisher.id)
                .filter(call => call.callType === 'book' || !call.callType).length || 0,
            yesterday_app:
              yesterdayData
                .filter(doc => item.id === doc.publisher.id)
                .filter(doc => doc.callType === 'app').length || 0
          })
        );
      return newData;
    };

    const loadData = async () => {
      const userData = await loadUserData();
      const statisticData = await loadStatisticData();
      const counterData = await loadCounterData();

      const data = combineData(
        userData,
        counterData,
        statisticData.yesterdayData,
        statisticData.todayData
      );

      setData(data);
    };

    if (user.level === '대리점') {
      history.push('/main/order_pub_one');
    } else {
      loadData();
    }
  }, []);

  const exportExcel = () => {
    const arr = [];
    data &&
      data.forEach(item =>
        arr.push({
          협력사명: item.company,
          대표자: item.ceo,
          전화번호: formatPhone(item.phone),
          전일: item.yesterday,
          [`전일(앱)`]: item.yesterday_app,
          당일: item.today,
          [`당일(앱)`]: item.today_app,
          [`2개월전`]: item.two.counter,
          [`2개월전(앱)`]: item.two.counter_app,
          [`1개월전`]: item.one.counter,
          [`1개월전(앱)`]: item.one.counter_app,
          [`당월`]: item.current.counter,
          [`당월(앱)`]: item.current.counter_app,
          [`누적`]: item.all.counter,
          [`누적(앱)`]: item.all.counter_app
        })
      );
    jsonToSheet(arr, 'K배달_주문조회_협력사통계');
  };

  return (
    <Container>
      <Helmet>
        <title>협력사 통계 - K배달 관리자시스템</title>
      </Helmet>
      <div
        style={{
          fontSize: '18px',
          fontWeight: '600',
          color: '#0c8599',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div>
          <Icon name="call" style={{ marginRight: '0.5rem' }} />
          <span>주문 조회 - 협력사 통계</span>
        </div>

        <div
          style={{
            marginLeft: '2rem',
            fontSize: '12px',
            color: '#fa5252'
          }}
        >
          ※ 통화실패, 통화시간 0초 건은 제외됩니다. 1시간 마다 업데이트 됩니다.
        </div>
        <Button
          size="small"
          style={{
            fontWeight: '600',
            background: 'white',
            color: 'gray',
            marginLeft: '2rem'
          }}
          onClick={exportExcel}
        >
          <Icon name="file excel" /> 엑셀 저장
        </Button>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '1rem'
        }}
      >
        {data && (
          <CustomTable
            title="총계"
            data={[
              {
                title: '전일',
                value: data.reduce((acc, item) => acc + (item.yesterday + item.yesterday_app), 0)
              },
              {
                title: '당일',
                value: data.reduce((acc, item) => acc + (item.today + item.today_app), 0)
              },
              {
                title: '2개월전',
                value: data.reduce(
                  (acc, item) => acc + (item.two.counter + item.two.counter_app),
                  0
                )
              },
              {
                title: '1개월전',
                value: data.reduce(
                  (acc, item) => acc + (item.one.counter + item.one.counter_app),
                  0
                )
              },
              {
                title: '당월',
                value: data.reduce(
                  (acc, item) => acc + (item.current.counter + item.current.counter_app),
                  0
                )
              },
              {
                title: '누적',
                value: data.reduce(
                  (acc, item) => acc + (item.all.counter + item.all.counter_app),
                  0
                )
              }
            ]}
          />
        )}
      </div>

      {data ? (
        <Table
          data={data}
          index
          perPage={20}
          headers={[
            {
              title: '협력사명',
              value: 'company',

              render: item => <Link to={`/main/orderstore/${item.id}`}>{item.company}</Link>
            },
            {
              title: '대표자',
              value: 'ceo',
              width: 90
            },
            {
              title: '전화번호',
              value: 'phone',
              render: item => <span>{item.phone && formatPhone(item.phone)}</span>
            },
            {
              title: '전일call',
              value: 'yesterday',
              render: item =>
                `${item.yesterday + item.yesterday_app} (${item.yesterday}/${item.yesterday_app})`,
              notSearch: true
            },
            {
              title: '당일call',
              value: 'today',
              render: item => `${item.today + item.today_app} (${item.today}/${item.today_app})`,
              notSearch: true
            },
            {
              title: '2개월전',
              value: 'two',
              render: item =>
                `${item.two.counter + item.two.counter_app} (${item.two.counter}/${
                  item.two.counter_app
                })`,
              notSearch: true
            },
            {
              title: '1개월전',
              value: 'one',
              render: item =>
                `${item.one.counter + item.one.counter_app} (${item.one.counter}/${
                  item.one.counter_app
                })`,
              notSearch: true
            },
            {
              title: '당월',
              value: 'current',
              render: item =>
                `${item.current.counter + item.current.counter_app} (${item.current.counter}/${
                  item.current.counter_app
                })`,
              notSearch: true
            },
            {
              title: '누적',
              value: 'all',
              render: item =>
                `${item.all.counter + item.all.counter_app} (${item.all.counter}/${
                  item.all.counter_app
                })`,
              notSearch: true
            }
          ]}
        />
      ) : (
        <Loader active />
      )}
    </Container>
  );
};

export default OrderPublish;
