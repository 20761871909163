import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Firebase, { FirebaseContext } from "./firebase";
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./store/modules";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

const persistConfig = {
  key: "root",
  storage
};

const enhancedReducer = persistReducer(persistConfig, rootReducer);

let store;

if (process.env.NODE_ENV === "development") {
  store = createStore(enhancedReducer, composeWithDevTools());
} else {
  store = createStore(enhancedReducer);
}
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <FirebaseContext.Provider value={new Firebase()}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </FirebaseContext.Provider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
