import React, { Component } from "react";
import styled from "styled-components";
import { Icon, Table, Dropdown } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  width: 98%;
  background: white;
  margin-bottom: 2rem;
  min-height: 35rem;
  padding: 3rem 2rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #dfdfdf;
  .flex {
    display: flex;
    align-items: center;
  }
`;
const Row = styled(Table.Row)`
  &:hover {
    background: #e7f5ff;
  }
`;

class OrderOneStore extends Component {
  state = {
    keyword: "",
    activePage: 1,
    year: new Date().getFullYear()
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { code } = this.props.match.params;
    this.props.firebase.db
      .collection("stores")
      .doc(code)
      .get()
      .then(doc => {
        this.setState({
          data: doc.data()
        });
      });

    this.props.firebase.db
      .collection("counters")
      .where("code", "==", code)
      .get()
      .then(snapshots => {
        this.setState({
          counterData: snapshots.docs[0] && snapshots.docs[0].data()
        });
      });
  };

  onSelectChange = (e, { name, value }) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const { data, counterData } = this.state;
    let dropDownOptions = [];
    const thisYear = new Date().getFullYear();
    for (let i = 0; i <= thisYear - 2019; i++) {
      dropDownOptions.push({ key: i, text: 2019 + i, value: 2019 + i });
    }
    return (
      <Container>
        <div
          style={{
            borderBottom: "1.5px solid lightgray",
            padding: "1rem 3rem",
            fontSize: "17px",
            marginBottom: "2rem",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div>
            <Icon name="call" style={{ marginRight: "1rem" }} />
            <span>누적 call 통계</span>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className="flex" style={{ justifyContent: "center" }} />
          <div
            className="flex"
            style={{ justifyContent: "center", marginTop: "1rem" }}
          >
            <div>선택 연도 </div>
            <Dropdown
              onChange={this.onSelectChange}
              defaultValue={this.state.year}
              options={dropDownOptions}
              placeholder="연도를 선택하세요"
              selection
              style={{ width: "50px", margin: "0 0.5rem" }}
              name="year"
            />
          </div>
        </div>
        <div
          style={{
            margin: "1rem 1rem",
            borderTop: "1px solid #efefef",
            padding: "1rem 0",
            fontSize: "15px"
          }}
        >
          가맹점: {data && `${data.company} [${data.code}]`}
        </div>
        <Table celled style={{ textAlign: "center" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>1</Table.HeaderCell>
              <Table.HeaderCell>2</Table.HeaderCell>
              <Table.HeaderCell>3</Table.HeaderCell>
              <Table.HeaderCell>4</Table.HeaderCell>
              <Table.HeaderCell>5</Table.HeaderCell>
              <Table.HeaderCell>6</Table.HeaderCell>
              <Table.HeaderCell>7</Table.HeaderCell>
              <Table.HeaderCell>8</Table.HeaderCell>
              <Table.HeaderCell>9</Table.HeaderCell>
              <Table.HeaderCell>10</Table.HeaderCell>
              <Table.HeaderCell>11</Table.HeaderCell>
              <Table.HeaderCell>12</Table.HeaderCell>
              <Table.HeaderCell>합계</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {counterData && (
              <Row style={{ fontSize: "12px" }}>
                <Table.Cell>
                  {counterData[`${this.state.year}-1`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-2`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-3`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-4`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-5`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-6`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-7`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-8`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-9`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-10`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-11`] || 0}
                </Table.Cell>
                <Table.Cell>
                  {counterData[`${this.state.year}-12`] || 0}
                </Table.Cell>
                <Table.Cell>{counterData["allCount"] || 0}</Table.Cell>
              </Row>
            )}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(OrderOneStore);
