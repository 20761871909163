import React, { Component } from "react";
import styled from "styled-components";
import { Input, Button, Icon, Loader, TextArea, Form } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";

const Container = styled.div`
  width: 98%;
  background: white;
  margin-bottom: 2rem;
  padding: 3rem 2rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #dfdfdf;
  .flex {
    display: flex;
    align-items: center;
  }
`;

class AddAsk extends Component {
  state = {
    fileLoading: "WAIT",
    fileURL: "",
    user: this.props.user.level,
    userId: this.props.user.id
  };

  onSubmit = () => {
    const { title, fileURL, content, user, userId } = this.state;
    this.props.firebase.db
      .collection("ask")
      .doc()
      .set({
        title,
        fileURL,
        content,
        user,
        userId,
        date: new Date(),
        status: true
      })
      .then(() => {
        this.props.history.push(`/main/ask`);
      });
  };

  onChange = (e, { value, name }) => {
    this.setState({
      [name]: value
    });
  };

  onContentChange = (e, { value }) => {
    this.setState({
      content: value.replace(/\n/g, "<br/>")
    });
  };

  fileUpload = e => {
    const storageRef = this.props.firebase.storage.ref();
    let filelist = e.target.files;
    let file = filelist[0];

    // 파일 용량체크
    if (file.size > 10 * 1024 * 1024) {
      alert("첨부 파일의 최대 사이즈는 10MB 입니다.");
      return null;
    }

    let uploadTask = storageRef.child("extra/" + file.name).put(file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        this.setState({
          fileLoading: "LOADING"
        });
      },
      err => {
        this.setState({
          fileLoading: "FAIL"
        });
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(url => {
          this.setState({
            fileURL: url,
            fileLoading: "DONE"
          });
        });
      }
    );
  };
  render() {
    const { user } = this.props;
    const { fileLoading } = this.state;
    return (
      <Container>
        <div
          style={{
            borderBottom: "1.5px solid lightgray",
            padding: "1rem 3rem",
            fontSize: "17px",
            marginBottom: "2rem",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div>
            <Icon name="add" style={{ marginRight: "1rem" }} />
            <span>문의사항 등록</span>
          </div>
        </div>
        <div>
          <div className="flex" style={{ marginBottom: "1rem" }}>
            <span style={{ marginRight: "1rem" }}>제목</span>
            <Input
              onChange={this.onChange}
              name="title"
              style={{ minWidth: "75%", marginRight: "1rem" }}
            />
            <span style={{ marginRight: "1rem" }}>작성자</span>
            <span>{user.level}</span>
          </div>

          <Form>
            <div>내용</div>
            <TextArea
              onChange={this.onContentChange}
              name="content"
              style={{ margin: "1rem 0", minHeight: "10rem" }}
            />
          </Form>
          <div
            className="flex"
            style={{ marginLeft: "1rem", marginBottom: "2rem" }}
          >
            <div style={{ marginLeft: "1rem" }}>첨부파일</div>
            <div style={{ marginLeft: "1rem" }}>
              <input type="file" name="file" onChange={this.fileUpload} />
              <div>
                {fileLoading === "LOADING" && (
                  <Loader active inline size="mini" />
                )}
                {fileLoading === "DONE" && <>업로드 완료!</>}
                {fileLoading === "FAIL" && <>업로드 실패 다시 시도해주세요.</>}
              </div>
            </div>
          </div>
          <div className="flex" style={{ marginBottom: "1rem" }}>
            <Button
              size="small"
              primary
              onClick={() => {
                this.onSubmit();
              }}
            >
              <Icon name="add" />
              등록하기
            </Button>
            <Link to={`/main/notice`}>
              <Button size="small">
                <Icon name="th list" />
                목록으로
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(AddAsk);
