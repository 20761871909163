import React, { Component } from "react";
import styled from "styled-components";
import { Button, Icon, Loader, Modal, Image, Table } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";

import { formatPhone, dateFormat } from "../../common/format";

const Container = styled.div`
  width: 90%;
  margin-top: 50px;
  padding: 1rem 2rem;
  border-radius: 4px;
  .flex {
    display: flex;
  }
`;
const Row = styled(Table.Row)`
  &:hover {
    background: #e7f5ff;
  }
`;

class StoreDetail extends Component {
  state = {
    isLoading: false,
    data: null
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id) {
      this.setState({
        data: null,
        sameStore: null
      });
      setTimeout(() => {
        this.loadData();
      }, 100);
    }
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id === ":id") {
      this.props.history.push(`/main/liststore`);
    } else {
      this.loadData();
    }
  }
  loadData = () => {
    const { id } = this.props.match.params;
    const stores = this.props.firebase.db.collection("stores");
    stores
      .doc(id)
      .get()
      .then(doc => {
        const businessNumber = doc.data().businessNumber;
        let sameStore = [];
        stores
          .where("businessNumber", "==", businessNumber)
          .get()
          .then(snapshots => {
            snapshots.forEach(doc => sameStore.push({ ...doc.data() }));

            this.setState({
              data: doc.data(),
              sameStore: sameStore.filter(item => item.code !== doc.data().code)
            });
          });
      })
      .catch(err => console.log(err));
  };

  setAccess = flag => {
    const existLogArr = this.state.data.changeLog_app || [];
    const changeLog_app = [
      ...existLogArr,
      {
        createdAt: new Date(),
        detail: "상태변경",
        status: flag ? "접수완료" : "반려",
        storeName: this.state.data.company,
        editedUser: this.props.user.id
      }
    ];
    const { db } = this.props.firebase;

    db.collection("stores")
      .doc(this.state.data.code)
      .update({ changeLog_app })
      .then(() => {
        this.setState({
          isLoading: false
        });
        alert(`${flag ? "접수완료" : "반려"} 처리 되었습니다.`);
        this.props.history.push(`/main/store/${this.state.data.code}`);
      })
      .catch(err => console.log(err));
  };

  render() {
    const { data } = this.state;
    return (
      <Container>
        <div
          style={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#0c8599",
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
            paddingBottom: "0.5rem",
            borderBottom: "2px solid #ccc"
          }}
        >
          <span style={{ marginLeft: "1rem" }}>가맹점 상세</span>
        </div>
        {data ? (
          <>
            <div className="flex">
              <InputSet
                name="소속 본사"
                value={data.headquarter && data.headquarter.company}
              />
              <InputSet
                name="소속 총판"
                value={data.publisher && data.publisher.company}
              />
            </div>
            <div className="flex">
              <InputSet name="영업담당자" value={data.salesman} />
            </div>
            <div className="flex">
              <InputSet name="가맹점명" value={data.company} />
              <InputSet name="가맹점코드" value={data.code} />
            </div>
            <div className="flex">
              <InputSet
                name="050전화번호"
                value={
                  data.phone050 &&
                  data.phone050.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3")
                }
              />
              <InputSet
                name="책자 상태"
                value={
                  data.changeLog
                    ? data.changeLog[data.changeLog.length - 1].status
                    : "미사용"
                }
              />
            </div>
            <div className="flex">
              <InputSet
                name="050전화번호 (콕콕114)"
                value={
                  data.phone050_app &&
                  data.phone050_app.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3")
                }
              />
              <InputSet
                name="콕콕114 상태"
                value={
                  data.changeLog_app
                    ? data.changeLog_app[data.changeLog_app.length - 1].status
                    : "미사용"
                }
              />
            </div>
            <div className="flex">
              <InputSet name="일반전화번호" value={data.tel} />
              <InputSet name="사업자번호" value={data.businessNumber} />
            </div>
            <div className="flex">
              <InputSetLarge name="주소" value={data.address} />
            </div>
            <div className="flex">
              <InputSetLarge name="이메일주소" value={data.email} />
            </div>
            <div className="flex">
              <InputSet name="대표자명" value={data.ceo} />
              <InputSet name="휴대폰번호" value={data.phone} />
            </div>
            <div className="flex">
              <InputSet
                name="가입일"
                value={
                  data.createdAt && data.createdAt.toDate().toLocaleDateString()
                }
              />
              <InputSet
                name="수정일"
                value={
                  data.editedAt && data.editedAt.toDate().toLocaleDateString()
                }
              />
            </div>
            <div className="flex">
              <InputSet
                name="콕콕114 상태"
                value={
                  data.changeLog_app &&
                  data.changeLog_app[data.changeLog_app.length - 1].status
                }
              />

              {this.props.user.level === "관리자" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    size="small"
                    onClick={() => {
                      this.setAccess(true);
                    }}
                  >
                    접수완료
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      this.setAccess(false);
                    }}
                  >
                    반려
                  </Button>
                </div>
              )}
            </div>
            <div className="flex" style={{ marginTop: "1rem" }}>
              <div
                style={{ color: "gray", marginLeft: "5%", marginRight: "1rem" }}
              >
                메뉴판
              </div>
              <div>
                {data.photo ? (
                  <Modal trigger={<Button size="small">상세보기</Button>}>
                    <Image centered size="big" src={data.photo} />
                  </Modal>
                ) : (
                  "없음"
                )}
              </div>
            </div>
            <div className="flex" style={{ marginTop: "1rem" }}>
              <div
                style={{ color: "gray", marginLeft: "5%", marginRight: "1rem" }}
              >
                신청서
              </div>
              <div>
                {data.applicationForm ? (
                  <Modal trigger={<Button size="small">상세보기</Button>}>
                    <Image centered size="big" src={data.applicationForm} />
                  </Modal>
                ) : (
                  "없음"
                )}
              </div>
            </div>
            <div className="flex" style={{ marginTop: "1rem" }}>
              <div
                style={{ color: "gray", marginLeft: "5%", marginRight: "1rem" }}
              >
                사업자 등록증
              </div>
              <div>
                {data.businessRegistForm ? (
                  <Modal trigger={<Button size="small">상세보기</Button>}>
                    <Image centered size="big" src={data.businessRegistForm} />
                  </Modal>
                ) : (
                  "없음"
                )}
              </div>
            </div>
            <div className="flex" style={{ marginTop: "1rem" }}>
              <div
                style={{ color: "gray", marginLeft: "5%", marginRight: "1rem" }}
              >
                통장사본
              </div>
              <div>
                {data.copiedBankBook ? (
                  <Modal trigger={<Button size="small">상세보기</Button>}>
                    <Image centered size="big" src={data.copiedBankBook} />
                  </Modal>
                ) : (
                  "없음"
                )}
              </div>
            </div>

            <div
              className="flex"
              style={{ justifyContent: "center", marginTop: "2rem" }}
            >
              <Link to={`/main/editstore/${data.code}`}>
                <Button size="small" style={{ marginRight: "1rem" }}>
                  <Icon name="edit outline" />
                  수정하기
                </Button>
              </Link>
              <Link to={`/main/liststore`}>
                <Button size="small">
                  <Icon name="th list" />
                  목록으로
                </Button>
              </Link>
            </div>
          </>
        ) : (
          <div
            className="flex"
            style={{ justifyContent: "center", marginTop: "2rem" }}
          >
            <Loader active />
          </div>
        )}
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #dfdfdf",
            paddingTop: "1rem",
            marginTop: "1rem"
          }}
        >
          <div style={{ marginLeft: "2rem" }}>
            <b>동일업체</b>(사업자번호 동일 업체)
          </div>
          {this.state.sameStore && (
            <Table celled style={{ textAlign: "center" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>No.</Table.HeaderCell>
                  <Table.HeaderCell>협력사명</Table.HeaderCell>
                  <Table.HeaderCell>가맹점코드</Table.HeaderCell>
                  <Table.HeaderCell>가맹점명</Table.HeaderCell>
                  <Table.HeaderCell>대표자</Table.HeaderCell>
                  <Table.HeaderCell>일반전화번호</Table.HeaderCell>
                  <Table.HeaderCell>050전화번호</Table.HeaderCell>
                  <Table.HeaderCell>050가입일자</Table.HeaderCell>
                  <Table.HeaderCell>상태</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <TableItem data={this.state.sameStore} />
              </Table.Body>
            </Table>
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(StoreDetail);

const InputSet = ({ name, value }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "50%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      <div style={{ color: "gray" }}>{name}</div>
      <div
        style={{
          width: "75%",
          borderBottom: "1px solid lightgrey",
          textAlign: "center",
          marginLeft: "1rem",
          paddingBottom: "0.3rem",
          minHeight: "20px"
        }}
      >
        {value}
      </div>
    </div>
  );
};

const InputSetLarge = ({ name, value }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      {" "}
      <div style={{ color: "gray" }}>{name}</div>
      <div
        style={{
          minWidth: "200px",
          width: "88%",
          borderBottom: "1px solid lightgrey",
          textAlign: "left",
          paddingLeft: "11%",
          marginLeft: "1rem",
          paddingBottom: "0.3rem",
          minHeight: "20px"
        }}
      >
        {value}
      </div>
    </div>
  );
};

const TableItem = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <Row style={{ fontSize: "12px" }} key={index}>
          <Table.Cell>{index + 1}</Table.Cell>
          <Table.Cell>
            {item.publisher.company || `본사/${item.headquarter.company}`}
          </Table.Cell>
          <Table.Cell>{item.code}</Table.Cell>
          <Table.Cell>
            <Link to={`/main/store/${item.code}`}>{item.company}</Link>
          </Table.Cell>

          <Table.Cell>{item.ceo}</Table.Cell>
          <Table.Cell>{item.tel}</Table.Cell>
          <Table.Cell>{formatPhone(item.phone050)}</Table.Cell>

          <Table.Cell>
            {item.changeLog &&
              dateFormat(item.changeLog[0].createdAt).split(" ")[0]}
          </Table.Cell>
          <Table.Cell>
            <Link to={`/main/liststore/${item.code}`}>
              {item.changeLog
                ? item.changeLog[item.changeLog.length - 1].status
                : "미사용"}
            </Link>
          </Table.Cell>
        </Row>
      ))}
    </>
  );
};
