import React, { Component } from "react";
import styled from "styled-components";
import { Table, Pagination, Icon, Loader } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { formatPhone, dateFormat } from "../../common/format";

const Container = styled.div`
  width: 98%;
  background: white;
  margin-bottom: 2rem;
  padding: 3rem 2rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #dfdfdf;
  .flex {
    display: flex;
    align-items: center;
  }
`;
const Row = styled(Table.Row)`
  &:hover {
    background: #e7f5ff;
  }
`;

class StoreStatusList extends Component {
  state = {
    isLoading: false,
    activePage: 1
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id === ":id") {
      this.props.history.push(`/main/liststore`);
    } else {
      this.loadData();
    }
  }

  loadData = () => {
    const { id } = this.props.match.params;
    this.props.firebase.db
      .collection("stores")
      .doc(id)
      .get()
      .then(doc => {
        const data = doc.data().changeLog;
        this.setState({
          allData: data,
          data
        });
        this.paginationFilter(data);
      });
  };

  paginationFilter = data => {
    if (!data) {
      this.setState({
        paginationData: [[{ storeName: "사용이력이없습니다." }]]
      });
      return null;
    }
    let result = [];
    const dataPerPage = 20;
    const last = Math.ceil(data.length / dataPerPage);
    for (let i = 0; i < last; i++) {
      if (i === 0) {
        result.push(
          data.slice(i + i * dataPerPage, dataPerPage + i * dataPerPage)
        );
      } else {
        result.push(data.slice(i * dataPerPage, dataPerPage + i * dataPerPage));
      }
    }
    this.setState({
      paginationData: result
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({
      activePage
    });
  };

  render() {
    return (
      <Container>
        <div
          style={{
            borderBottom: "1.5px solid lightgray",
            padding: "1rem 3rem",
            fontSize: "17px",
            marginBottom: "2rem",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div>
            <Icon name="th list" style={{ marginRight: "1rem" }} />
            <span>사용 이력</span>
          </div>
        </div>

        <Table celled style={{ textAlign: "center" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>가맹점명</Table.HeaderCell>
              <Table.HeaderCell>일반번호</Table.HeaderCell>
              <Table.HeaderCell>050번호</Table.HeaderCell>
              <Table.HeaderCell>상태</Table.HeaderCell>
              <Table.HeaderCell>상태변경일</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.paginationData ? (
              <TableItem
                data={this.state.paginationData[this.state.activePage - 1]}
              />
            ) : (
              <Table.Row>
                <Loader active />
              </Table.Row>
            )}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="10" style={{ textAlign: "center" }}>
                {this.state.data && (
                  <Pagination
                    activePage={this.state.activePage}
                    onPageChange={this.handlePaginationChange}
                    totalPages={Math.ceil(this.state.data.length / 20)}
                  />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(StoreStatusList);

const TableItem = ({ data }) => {
  return (
    <>
      {data &&
        data.length > 0 &&
        data.reverse().map((item, index) => (
          <Row style={{ fontSize: "12px" }} key={index}>
            <Table.Cell>{data.length - index}</Table.Cell>
            <Table.Cell>{item.storeName}</Table.Cell>
            <Table.Cell>{item.tel}</Table.Cell>

            <Table.Cell>
              {item.phone050 && formatPhone(item.phone050)}
            </Table.Cell>
            <Table.Cell>{item.status}</Table.Cell>
            <Table.Cell>
              {item.createdAt && dateFormat(item.createdAt)}
            </Table.Cell>
          </Row>
        ))}
    </>
  );
};
