import React, { useState, useEffect } from "react";
import { Input, Icon, Button, Loader } from "semantic-ui-react";
import styled from "styled-components";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import logo from "../images/logo3.png";
import { setAuth } from "../store/modules/auth";

const Container = styled.div`
  width: 100%;
  position: fixed;
  min-height: 100vh;
  background: white;
  top: -10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media screen and (max-width: 580px) {
    flex-direction: column;
  }
  .logo {
    width: 180px;
    @media screen and (max-width: 580px) {
      width: 210px;
    }
  }
`;

const Login = props => {
  const [form, setForm] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, path } = useSelector(state => state.auth, []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      path ? props.history.push(path) : props.history.push("/main/home");
    }
  }, []);

  const onChangeInput = e => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onLogin = () => {
    const { id, pw } = form;
    if (!id) {
      alert("아이디를 입력해주세요.");
      return null;
    } else if (!pw) {
      alert("비밀번호를 입력해주세요");
      return null;
    }
    setIsLoading(true);

    const userInfo = { id, pw };
    axios.defaults.baseURL =
      "https://us-central1-phone-fa49a.cloudfunctions.net/api";

    axios
      .post("/login", userInfo)
      .then(res => {
        if (res.data.success) {
          const user = res.data.payload;
          if (!user.status) {
            setIsLoading(false);
            setError("영업 중지 된 계정입니다.");
            return;
          }
          dispatch(setAuth({ ...user, loginAt: new Date().getTime() }));
          setIsLoading(false);
          setError("");

          props.history.push("/main/home");
        } else {
          setIsLoading(false);
          setError(res.data.error);
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <Container>
      <div style={{ marginRight: "2rem" }}>
        <img src={logo} className="logo" alt="" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "300px",
          alignItems: "center"
        }}
      >
        <Input
          iconPosition="left"
          placeholder="아이디"
          style={{
            width: "100%",
            marginTop: "1.5rem",
            maxWidth: "350px"
          }}
        >
          <Icon name="user" />
          <input name="id" onChange={onChangeInput} />
        </Input>
        <Input
          iconPosition="left"
          placeholder="비밀번호"
          style={{ marginTop: "1rem", width: "100%", maxWidth: "350px" }}
        >
          <Icon name="lock" />
          <input
            type="password"
            name="pw"
            onChange={onChangeInput}
            id="pw"
            onKeyDown={e => {
              if (e.keyCode === 13) {
                onLogin();
              }
            }}
          />
        </Input>
        <div
          style={{
            width: "100%",
            height: "1rem",
            color: "red",
            textAlign: "center"
          }}
        >
          {error}
        </div>
        <Button
          style={{
            marginTop: "1rem",
            width: "100%",
            marginBottom: "1.5rem",
            background: "#e34f4b",
            color: "white",
            maxWidth: "350px"
          }}
          onClick={onLogin}
          disabled={isLoading}
        >
          {isLoading ? <Loader active inline size="mini" inverted /> : `로그인`}
        </Button>
      </div>
    </Container>
  );
};

export default withRouter(Login);
