import React, { useEffect, useState, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Table from '../../components/Table/Table';
import CustomTable from '../../components/Table/CustomTable';
import { Icon, Loader, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';
import { formatPhone } from '../../common/format';
import { jsonToSheet } from '../Admin/Admin';
import _ from 'lodash';

const Container = styled.div`
  width: 96%;
  padding: 3rem 2%;
  .flex {
    display: flex;
    align-items: center;
  }
  .header {
    font-weight: bold;
  }
`;

const OrderPublish = props => {
  const { db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);
  const [data, setData] = useState(null);
  const { history } = props;

  useEffect(() => {
    const loadUserData = () => {
      return db
        .collection('users')
        .where('headquarter.id', '==', user.id)
        .get()
        .then(snapshots => {
          let data = [];
          snapshots.forEach(doc => data.push({ ...doc.data(), pw: null }));

          const publishIds = data.map(item => item.id);

          return publishIds;
        });
    };

    const loadStatisticData = () => {
      const today = new Date();
      const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
      return db
        .collection('statistic')
        .where('date', '>=', yesterday)
        .get()
        .then(snapshots => {
          let statisticData = [];
          snapshots.forEach(doc => statisticData.push(doc.data()));
          return {
            todayData: statisticData.filter(
              item => item.date.toDate().getDate() === today.getDate()
            ),
            yesterdayData: statisticData.filter(
              item => item.date.toDate().getDate() !== today.getDate()
            )
          };
        });
    };

    const loadCounterData = () => {
      return db
        .collection('counters')
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push(doc.data()));
          return result;
        });
    };

    const loadStoreData = () => {
      return db
        .collection('stores')
        .get()
        .then(snapshots => {
          let data = [];
          snapshots.forEach(doc =>
            data.push({
              ...doc.data(),
              publisherName: doc.data().publisher.company
            })
          );

          let filtered = data;
          if (user.level === '총판') {
            filtered = data.filter(item => item.distributor.id === user.id);
          } else if (user.level === '대리점') {
            filtered = data.filter(item => item.publisher.id === user.id);
          }

          return filtered;
        });
    };

    const countData = (data, code, type) => {
      const countData = data.filter(item => item.code === code);
      const date = new Date();

      if (type === 'all') {
        let counter = 0;
        let counter_app = 0;
        countData.forEach(item => (counter += item.allCount || 0));
        countData.forEach(item => (counter_app += item.allAppCount || 0));
        return { counter, counter_app };
      } else if (type === 'current') {
        let counter = 0;
        let counter_app = 0;
        let findDate = `${date.getFullYear()}-${date.getMonth() + 1}`;

        countData.forEach(item => item[findDate] && (counter += item[findDate]));
        countData.forEach(
          item => item[findDate + '-APP'] && (counter_app += item[findDate + '-APP'])
        );

        return { counter, counter_app };
      } else if (type === 'one') {
        let counter = 0;
        let counter_app = 0;
        let findDate;
        if (date.getMonth() === 0) {
          // 1월 일 경우 년도를 전년도로 설정
          findDate = `${date.getFullYear() - 1}-12}`;
        } else {
          findDate = `${date.getFullYear()}-${date.getMonth()}`;
        }

        countData.forEach(item => item[findDate] && (counter += item[findDate]));
        countData.forEach(
          item => item[findDate + '-APP'] && (counter_app += item[findDate + '-APP'])
        );
        return { counter, counter_app };
      } else if (type === 'two') {
        let counter = 0;
        let counter_app = 0;
        let findDate;
        if (date.getMonth() === 0) {
          // 1월 일 경우 년도를 전년도로 설정
          findDate = `${date.getFullYear() - 1}-11`;
        } else {
          findDate = `${date.getFullYear()}-${date.getMonth() - 1}`;
        }

        countData.forEach(item => item[findDate] && (counter += item[findDate]));
        countData.forEach(
          item => item[findDate + '-APP'] && (counter_app += item[findDate + '-APP'])
        );
        return { counter, counter_app };
      }
    };

    const combineData = (userData, counterData, yesterdayData, todayData) => {
      const newData = [];
      userData &&
        userData.forEach(item =>
          newData.push({
            ...item,
            one: countData(counterData, item.code, 'one'),
            two: countData(counterData, item.code, 'two'),
            current: countData(counterData, item.code, 'current'),
            all: countData(counterData, item.code, 'all'),
            today: todayData.filter(doc => item.code === doc.code).length || 0,
            today_app:
              todayData
                .filter(doc => item.code === doc.code)
                .filter(call => call.callType === 'app').length || 0,
            yesterday:
              yesterdayData
                .filter(doc => item.code === doc.code)
                .filter(call => call.callType === 'book' || !call.callType).length || 0,
            yesterday_app:
              yesterdayData
                .filter(doc => item.code === doc.code)
                .filter(doc => doc.callType === 'app').length || 0
          })
        );
      return newData;
    };

    const loadData = async () => {
      const publishIds = await loadUserData();
      const statisticData = await loadStatisticData();
      const counterData = await loadCounterData();
      const storeData = await loadStoreData();

      let resultArray = [];
      publishIds.map(id =>
        storeData.filter(store => store.publisher.id === id).map(item => resultArray.push(item))
      );

      const data = combineData(
        _.sortBy(resultArray, ['createdAt']).reverse(),
        counterData,
        statisticData.yesterdayData,
        statisticData.todayData
      );

      setData(data);
    };

    if (user.level !== '본사') {
      history.push('/main/orderstore');
    } else {
      loadData();
    }
  }, []);

  const exportExcel = () => {
    const arr = [];
    data &&
      data.forEach(item =>
        arr.push({
          협력사명: item.publisherName,
          가맹점명: item.company,
          가맹점코드: item.code,
          전화번호: formatPhone(item.phone),
          전일: item.yesterday,
          [`전일(앱)`]: item.yesterday_app,
          당일: item.today,
          [`당일(앱)`]: item.today_app,
          [`2개월전`]: item.two.counter,
          [`2개월전(앱)`]: item.two.counter_app,
          [`1개월전`]: item.one.counter,
          [`1개월전(앱)`]: item.one.counter_app,
          [`당월`]: item.current.counter,
          [`당월(앱)`]: item.current.counter_app,
          [`누적`]: item.all.counter,
          [`누적(앱)`]: item.all.counter_app
        })
      );
    jsonToSheet(arr, 'K배달_주문조회_가맹점통계');
  };

  return (
    <Container>
      <Helmet>
        <title>가맹점 통계 - K배달 관리자시스템</title>
      </Helmet>
      <div
        style={{
          fontSize: '18px',
          fontWeight: '600',
          color: '#0c8599',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div>
          <Icon name="call" style={{ marginRight: '0.5rem' }} />
          <span>주문 조회 - 가맹점 통계</span>
        </div>

        <div
          style={{
            marginLeft: '2rem',
            fontSize: '12px',
            color: '#fa5252'
          }}
        >
          ※ 통화실패, 통화시간 0초 건은 제외됩니다. 1시간 마다 업데이트 됩니다.
        </div>
        <Button
          size="small"
          style={{
            fontWeight: '600',
            background: 'white',
            color: 'gray',
            marginLeft: '2rem'
          }}
          onClick={exportExcel}
        >
          <Icon name="file excel" /> 엑셀 저장
        </Button>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '1rem'
        }}
      >
        {data && (
          <CustomTable
            title="총계"
            data={[
              {
                title: '전일',
                value: data.reduce((acc, item) => acc + (item.yesterday + item.yesterday_app), 0)
              },
              {
                title: '당일',
                value: data.reduce((acc, item) => acc + (item.today + item.today_app), 0)
              },
              {
                title: '2개월전',
                value: data.reduce(
                  (acc, item) => acc + (item.two.counter + item.two.counter_app),
                  0
                )
              },
              {
                title: '1개월전',
                value: data.reduce(
                  (acc, item) => acc + (item.one.counter + item.one.counter_app),
                  0
                )
              },
              {
                title: '당월',
                value: data.reduce(
                  (acc, item) => acc + (item.current.counter + item.current.counter_app),
                  0
                )
              },
              {
                title: '누적',
                value: data.reduce(
                  (acc, item) => acc + (item.all.counter + item.all.counter_app),
                  0
                )
              }
            ]}
          />
        )}
      </div>

      {data ? (
        <Table
          data={data}
          index
          perPage={20}
          headers={[
            { title: '협력사명', value: 'publisherName' },
            {
              title: '가맹점명',
              value: 'company',
              render: item => (
                <>
                  <Link to={`/main/order/${item.code}`}>
                    <div>{item.company}</div>
                  </Link>
                  <div style={{ color: 'gray', fontSize: '12px' }}>[{item.code}]</div>
                </>
              )
            },
            {
              title: '가맹점코드',
              value: 'code',
              noRender: true
            },
            {
              title: '전화번호',
              value: 'phone',
              noRender: true
            },
            {
              title: '전일call',
              value: 'yesterday',
              render: item =>
                `${item.yesterday + item.yesterday_app} (${item.yesterday}/${item.yesterday_app})`,
              notSearch: true
            },
            {
              title: '당일call',
              value: 'today',
              render: item => `${item.today + item.today_app} (${item.today}/${item.today_app})`,
              notSearch: true
            },
            {
              title: '2개월전',
              value: 'two.counter',
              render: item =>
                `${item.two.counter + item.two.counter_app} (${item.two.counter}/${
                  item.two.counter_app
                })`,
              notSearch: true
            },
            {
              title: '1개월전',
              value: 'one.counter',
              render: item =>
                `${item.one.counter + item.one.counter_app} (${item.one.counter}/${
                  item.one.counter_app
                })`,
              notSearch: true
            },
            {
              title: '당월',
              value: 'current.counter',
              render: item =>
                `${item.current.counter + item.current.counter_app} (${item.current.counter}/${
                  item.current.counter_app
                })`,
              notSearch: true
            },
            {
              title: '누적',
              value: 'all.counter',
              render: item =>
                `${item.all.counter + item.all.counter_app} (${item.all.counter}/${
                  item.all.counter_app
                })`,
              notSearch: true
            }
          ]}
        />
      ) : (
        <Loader active />
      )}
    </Container>
  );
};

export default OrderPublish;

// import React, { Component } from "react";
// import styled from "styled-components";
// import { Input, Button, Icon, Dropdown } from "semantic-ui-react";
// import { withFirebase } from "../../firebase";
// import { connect } from "react-redux";
// import { compose } from "redux";
// import { withRouter, Link } from "react-router-dom";
// import { formatPhone } from "../../common/format";
// import ReactTable from "react-table";
// import CustomTable from "../Table/CustomTable";

// const Container = styled.div`
//   width: 98%;
//   background: white;
//   margin-bottom: 2rem;
//   padding: 3rem 2rem;
//   border-radius: 4px;
//   box-shadow: 1px 1px 1px #dfdfdf;
//   .flex {
//     display: flex;
//     align-items: center;
//   }
//   .header {
//     font-weight: bold;
//   }
// `;

// class OrderStore_head extends Component {
//   state = {
//     keyword: "",
//     activePage: 1
//   };

//   componentDidMount() {
//     if (this.props.user.level !== "본사") {
//       this.props.history.push("/main/orderstore");
//     } else {
//       this.loadData();
//     }
//   }

//   search = () => {
//     const { keyword, allData, condition } = this.state;
//     let newData = allData;
//     if (condition) {
//       if (condition === "publisher") {
//         newData = allData.filter(
//           item =>
//             item.publisher && item.publisher.company.includes(keyword) === true
//         );
//       } else {
//         newData = allData.filter(
//           item => item[condition] && item[condition].includes(keyword) === true
//         );
//       }
//       if (this.state.selectedPublisher) {
//         newData = newData.filter(
//           item =>
//             item.distributor &&
//             item.distributor.company === this.state.selectedPublisher
//         );
//       }
//     } else {
//       if (this.state.selectedPublisher) {
//         newData = newData.filter(
//           item =>
//             item.distributor &&
//             item.distributor.company === this.state.selectedPublisher
//         );
//       }
//     }

//     this.setState({
//       data: newData
//     });
//   };

//   onChange = e => {
//     this.setState({
//       [e.target.name]: e.target.value
//     });
//   };

//   loadData = () => {
//     const { db } = this.props.firebase;

//     db.collection("users")
//       .where("headquarter.id", "==", this.props.user.id)
//       .get()
//       .then(snapshots => {
//         let array = [];
//         snapshots.forEach(doc => array.push({ ...doc.data(), pw: "" }));
//         const publishIds = array.map(item => item.id);
//         const headQuarterList = [{ ...this.props.user }];
//         const publisherList = array.filter(item => item.level === "총판");
//         this.setState({
//           headQuarterList,
//           publisherList
//         });

//         db.collection("stores")
//           .get()
//           .then(snapshots => {
//             let storeData = [];
//             snapshots.forEach(doc => {
//               storeData.push(doc.data());
//             });

//             let resultArray = [];
//             publishIds.map(id =>
//               storeData
//                 .filter(store => store.publisher.id === id)
//                 .map(item => resultArray.push(item))
//             );
//             this.setState({
//               allData: resultArray,
//               data: resultArray
//             });

//             let counterData = [];
//             db.collection("counters")
//               .get()
//               .then(snapshots => {
//                 snapshots.forEach(doc => counterData.push(doc.data()));
//                 this.setState({
//                   counterData
//                 });
//               });

//             const date = new Date();
//             date.setDate(date.getDate() - 1);
//             const yesterday = new Date(
//               date.getFullYear(),
//               date.getMonth(),
//               date.getDate()
//             );

//             let statisticData = [];
//             this.props.firebase.db
//               .collection("statistic")
//               .where("date", ">=", yesterday)
//               .get()
//               .then(snapshots => {
//                 snapshots.forEach(doc => statisticData.push(doc.data()));
//                 this.setState({
//                   todayData: statisticData.filter(
//                     item =>
//                       item.date.toDate().getDate() === new Date().getDate()
//                   ),
//                   yesterdayData: statisticData.filter(
//                     item =>
//                       item.date.toDate().getDate() !== new Date().getDate()
//                   )
//                 });
//               });
//           });
//       });
//   };

//   countData = (data, code, type) => {
//     const count = data.filter(item => item.code === code);
//     const date = new Date();
//     if (type === "all") {
//       let result = 0;
//       count.forEach(item => (result += item.allCount));
//       return result;
//     } else if (type === "current") {
//       let result = 0;
//       let findDate = `${date.getFullYear()}-${date.getMonth() + 1}`;

//       count.forEach(item => item[findDate] && (result += item[findDate]));
//       return result;
//     } else if (type === "one") {
//       let result = 0;
//       let findDate;
//       if (date.getMonth() === 0) {
//         // 1월 일 경우 년도를 전년도로 설정
//         findDate = `${date.getFullYear() - 1}-${date.getMonth()}`;
//       } else {
//         findDate = `${date.getFullYear()}-${date.getMonth()}`;
//       }

//       count.forEach(item => item[findDate] && (result += item[findDate]));
//       return result;
//     } else if (type === "two") {
//       let result = 0;
//       let findDate;
//       if (date.getMonth() === 0) {
//         // 1월 일 경우 년도를 전년도로 설정
//         findDate = `${date.getFullYear() - 1}-12`;
//       } else {
//         findDate = `${date.getFullYear()}-${date.getMonth() - 1}`;
//       }

//       count.forEach(item => item[findDate] && (result += item[findDate]));
//       return result;
//     }
//   };

//   combineData = () => {
//     const { data, counterData, yesterdayData, todayData } = this.state;
//     const newData = [];
//     data &&
//       data.forEach(item =>
//         newData.push({
//           ...item,
//           one: this.countData(counterData, item.code, "one"),
//           two: this.countData(counterData, item.code, "two"),
//           current: this.countData(counterData, item.code, "current"),
//           all: this.countData(counterData, item.code, "all"),
//           today: todayData.filter(doc => item.code === doc.code).length || 0,
//           yesterday:
//             yesterdayData.filter(doc => item.code === doc.code).length || 0
//         })
//       );
//     return newData;
//   };

//   onHeadquarterChange = (e, { value }) => {
//     let publishOptions = [];
//     this.state.publisherList
//       .filter(item => item.headquarter && item.headquarter.company === value)
//       .map((item, index) => {
//         publishOptions.push({
//           key: index,
//           text: item.company,
//           value: item.company
//         });
//         return null;
//       });
//     this.setState({
//       publishOptions
//     });
//   };

//   onSelectChange = (e, { name, value }) => {
//     this.setState({
//       [name]: value
//     });
//   };

//   render() {
//     const headOptions = [];
//     const {
//       publishOptions,
//       counterData,
//       yesterdayData,
//       todayData,
//       data
//     } = this.state;
//     this.state.headQuarterList &&
//       this.state.headQuarterList.map((item, index) => {
//         headOptions.push({
//           key: index,
//           text: item.company,
//           value: item.company
//         });
//         return null;
//       });

//     let combinedData = [];
//     if (yesterdayData && todayData && counterData && data) {
//       combinedData = this.combineData();
//     }

//     return (
//       <Container>
//         <div
//           style={{
//             borderBottom: "1.5px solid lightgray",
//             padding: "1rem 3rem",
//             fontSize: "17px",
//             marginBottom: "2rem",
//             fontWeight: "600",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between"
//           }}
//         >
//           <div>
//             <Icon name="call" style={{ marginRight: "1rem" }} />
//             <span>가맹점 통계</span>
//             <div
//               style={{
//                 margin: "1rem 1rem 0 0.5rem",
//                 fontSize: "12px",
//                 color: "#fa5252"
//               }}
//             >
//               ※ 통화실패, 통화시간 0초 건은 제외됩니다. 1시간 마다 업데이트
//               됩니다.
//             </div>
//           </div>
//         </div>

//         <div style={{ width: "100%" }}>
//           <div className="flex" style={{ justifyContent: "center" }}>
//             <Dropdown
//               onChange={this.onSelectChange}
//               options={[
//                 { key: 1, text: "협력사명", value: "publisher" },
//                 { key: 2, text: "가맹점명", value: "company" },
//                 { key: 3, text: "가맹점코드", value: "code" },
//                 { key: 4, text: "일반전화번호", value: "tel" },
//                 { key: 5, text: "050전화번호", value: "phone050" }
//               ]}
//               placeholder="검색조건"
//               name="condition"
//               style={{ margin: "0 0.5rem" }}
//             />
//             <Input style={{ margin: "0 1rem" }}>
//               <input onChange={this.onChange} name="keyword" id="search-con" />
//             </Input>
//           </div>
//           <div
//             className="flex"
//             style={{ justifyContent: "center", marginTop: "1rem" }}
//           >
//             <div>협력사 소속</div>
//             <Dropdown
//               onChange={this.onHeadquarterChange}
//               options={headOptions}
//               placeholder="본사"
//               selection
//               style={{ width: "50px", margin: "0 0.5rem" }}
//               name="selectedHeadquarter"
//             />

//             <Dropdown
//               onChange={this.onSelectChange}
//               options={publishOptions}
//               placeholder={publishOptions ? "총판" : "본사를 선택하세요"}
//               selection
//               name="selectedPublisher"
//               style={{ width: "50px", margin: "0 0.5rem" }}
//             />
//           </div>
//           <div
//             className="flex"
//             style={{
//               justifyContent: "center",
//               margin: "1rem 0",
//               borderBottom: "1px solid lightgrey",
//               paddingBottom: "1rem"
//             }}
//           >
//             <Button size="small" primary onClick={this.search}>
//               <Icon name="search" />
//               검색
//             </Button>
//           </div>
//         </div>
//         <div style={{ display: "flex", justifyContent: "flex-end" }}>
//           <CustomTable
//             title="총계"
//             data={[
//               {
//                 title: "전일",
//                 value: combinedData.reduce(
//                   (acc, item) => acc + item.yesterday,
//                   0
//                 )
//               },
//               {
//                 title: "당일",
//                 value: combinedData.reduce((acc, item) => acc + item.today, 0)
//               },
//               {
//                 title: "2개월전",
//                 value: combinedData.reduce((acc, item) => acc + item.two, 0)
//               },
//               {
//                 title: "1개월전",
//                 value: combinedData.reduce((acc, item) => acc + item.one, 0)
//               },
//               {
//                 title: "당월",
//                 value: combinedData.reduce((acc, item) => acc + item.current, 0)
//               },
//               {
//                 title: "누적",
//                 value: combinedData.reduce((acc, item) => acc + item.all, 0)
//               }
//             ]}
//           />
//         </div>
//         {data && counterData && yesterdayData && todayData && (
//           <ReactTable
//             data={this.combineData()}
//             style={{
//               fontSize: "14px",
//               textAlign: "center",
//               marginTop: "1rem"
//             }}
//             previousText="◀"
//             nextText="▶"
//             loadingText="Loading..."
//             noDataText="데이터가 없습니다."
//             pageText="현재 페이지"
//             ofText="중"
//             rowsText="개씩"
//             className="-highlight"
//             columns={[
//               {
//                 Header: "No.",
//                 accessor: "index",
//                 Cell: row => <span>{this.state.data.length - row.index}</span>,
//                 width: 50,
//                 headerClassName: "header"
//               },
//               {
//                 Header: "협력사명",
//                 accessor: "publisher.company",
//                 headerClassName: "header",
//                 minWidth: 130
//               },
//               {
//                 Header: "가맹점코드",
//                 accessor: "code",
//                 minWidth: 100,
//                 headerClassName: "header"
//               },
//               {
//                 Header: "가맹점명",
//                 accessor: "company",
//                 minWidth: 130,
//                 headerClassName: "header",
//                 Cell: row => (
//                   <Link to={`/main/store/${row.original.code}`}>
//                     {row.value}
//                   </Link>
//                 )
//               },
//               {
//                 Header: "일반전화번호",
//                 accessor: "tel",
//                 headerClassName: "header",
//                 minWidth: 120,
//                 Cell: row => <span>{formatPhone(row.value)}</span>
//               },
//               {
//                 Header: "전일call",
//                 accessor: "yesterday",
//                 headerClassName: "header"
//               },
//               {
//                 Header: "당일call",
//                 accessor: "today",
//                 headerClassName: "header"
//               },
//               {
//                 Header: "2개월전",
//                 accessor: "two",
//                 headerClassName: "header"
//               },
//               {
//                 Header: "1개월전",
//                 accessor: "one",
//                 headerClassName: "header"
//               },
//               {
//                 Header: "당월",
//                 accessor: "current",
//                 headerClassName: "header"
//               },
//               {
//                 Header: "누적call",
//                 accessor: "all",
//                 headerClassName: "header",
//                 Cell: row => (
//                   <Link to={`/main/order/${row.original.code}`}>
//                     {row.value}
//                   </Link>
//                 )
//               }
//             ]}
//           />
//         )}
//       </Container>
//     );
//   }
// }

// const mapStateToProps = state => ({
//   user: state.auth.user
// });

// export default compose(
//   connect(mapStateToProps),
//   withFirebase,
//   withRouter
// )(OrderStore_head);
