import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Icon, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';
import CountUp from 'react-countup';
import Table from '../../components/Table/Table';
import { Link } from 'react-router-dom';
import Clock from 'react-live-clock';
import Helmet from 'react-helmet';
import LineChart from '../../components/Chart/LineChart';
import { sortBy } from 'lodash';
import subMonths from 'date-fns/subMonths';

const Container = styled.div`
  display: flex;
  width: 90%;
  max-width: 1000px;
  margin-top: 30px;
  flex-direction: column;
  .flex {
    display: flex;
    margin-bottom: 20px;
    width: 100%;
  }
  .section {
    background: white;
    border-radius: 2px;
    box-shadow: 3px 1px 8px 2px rgba(0, 0, 0, 0.1);
    height: 290px;
    padding: 1rem 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    user-select: none;
  }
  .title {
    width: 100%;
    padding: 0.5rem 1rem 0 0.5rem;
    font-size: 18px;
    font-weight: bold;
  }
`;

const Home_daeri = props => {
  const { db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);
  const [data, setData] = useState(null);
  const { history } = props;

  useEffect(() => {
    const loadStatisticData = () => {
      const today = new Date();
      return db
        .collection('statistic')
        .where('publisher.id', '==', user.id)
        .where('date', '>=', new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1))
        .orderBy('date', 'desc')
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push(doc.data()));
          return result;
        });
    };

    const loadUserData = () => {
      return db
        .collection('users')
        .where('id', '==', user.id)
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push(doc.data()));
          return result.map(item => item.id);
        });
    };

    const loadCountData = () => {
      return db
        .collection('counters')
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push(doc.data()));
          return result;
        });
    };

    const loadStoreData = () => {
      return db
        .collection('stores')
        .where('publisher.id', '==', user.id)
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push(doc.data()));
          return result;
        });
    };

    const loadNoticeData = () => {
      return db
        .collection('notice')
        .get()
        .then(snapshots => {
          let originals = [];
          snapshots.forEach(doc => originals.push({ ...doc.data(), id: doc.id }));
          let result = [];
          result.push(...originals.filter(item => item.user === '관리자'));
          result.push(...originals.filter(item => item.userId === user.headquarter.id));

          return sortBy(result, ['date']).reverse();
        });
    };

    const loadAskData = () => {
      return db
        .collection('ask')
        .where('userId', '==', user.id)
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push({ ...doc.data(), id: doc.id }));

          return sortBy(result, ['date']).reverse();
        });
    };

    const countData = countData => {
      const date = new Date();
      let result = [];
      let labels = [];
      for (let i = 0; i <= 5; i++) {
        let counter = 0;
        let counter_app = 0;
        let findDate;
        let thisMonth;

        thisMonth = subMonths(date, i).getMonth() + 1;
        findDate = `${subMonths(date, i).getFullYear()}-${thisMonth}`;

        countData.forEach(item => item[findDate] && (counter += item[findDate]));
        countData.forEach(
          item => item[findDate + '-APP'] && (counter_app += item[findDate + '-APP'])
        );
        result.push({ counter, counter_app });
        labels.push(thisMonth + '월');
      }
      return { data: result.reverse(), labels: labels.reverse() };
    };

    const loadData = async () => {
      const result = await Promise.all([
        loadStoreData(),
        loadStatisticData(),
        loadCountData(),
        loadNoticeData(),
        loadAskData(),
        loadUserData()
      ]);
      const storeData = result[0];
      const statisticData = result[1];
      const countersData = result[2];
      const noticeData = result[3];
      const askData = result[4];
      const publishIds = result[5];

      let storeResult = [];
      publishIds.map(id =>
        storeData
          .filter(store => store.publisher.id === id)
          .filter(
            store =>
              store.changeLog && store.changeLog[store.changeLog.length - 1].status === '사용중'
          )
          .map(item => storeResult.push(item))
      );

      let countResult = [];
      publishIds.map(id =>
        countersData.filter(count => count.id === id).map(item => countResult.push(item))
      );

      let statisticResult = [];
      publishIds.map(id =>
        statisticData
          .filter(statistic => statistic.publisher.id === id)
          .map(item => statisticResult.push(item))
      );

      const countings = countData(countResult);
      setData({
        today: statisticResult.filter(item => item.date.toDate().getDate() === new Date().getDate())
          .length,
        yesterday: statisticResult.filter(
          item => item.date.toDate().getDate() !== new Date().getDate()
        ).length,
        storeData: storeResult,
        storeCount: storeResult.filter(
          store =>
            store &&
            store.changeLog &&
            store.changeLog[store.changeLog.length - 1].status === '사용중'
        ).length,
        appCount: storeResult.filter(store => store.phone050_app).length,
        noticeData,
        askData,
        chartData: {
          data: {
            book: countings.data.map(count => count.counter),
            app: countings.data.map(count => count.counter_app)
          },
          labels: countings.labels
        }
      });
    };
    if (user.level !== '대리점') {
      history.push('/main/home');
    } else {
      loadData();
    }
  }, []);

  return (
    <Container>
      <Helmet>
        <title>K배달 관리자시스템</title>
      </Helmet>
      {data ? (
        <>
          <div className="flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ color: '#0c8599', fontWeight: 'bold', fontSize: '16px' }}>
              K배달 관리자시스템
            </div>
            <div style={{ color: 'gray', fontWeight: 'bold', fontSize: '14px' }}>
              {new Date().toLocaleDateString()} <Clock ticking={true} format="HH:mm:ss" />
            </div>
          </div>
          <div className="flex">
            <div className="section" style={{ height: '100px', width: '23.5%', marginRight: '2%' }}>
              <div>
                <div style={{ fontSize: '26px', fontWeight: 'bold' }}>
                  <CountUp duration={2} start={0} end={data.today} />
                </div>
                <div
                  style={{
                    color: 'gray',
                    marginTop: '6px',
                    fontSize: '14px'
                  }}
                >
                  당일 콜 수
                </div>
              </div>
              <MiniCalendar month={new Date().getMonth() + 1} date={new Date().getDate()} />
            </div>
            <div className="section" style={{ height: '100px', width: '23.5%', marginRight: '2%' }}>
              <div>
                <div style={{ fontSize: '26px', fontWeight: 'bold' }}>
                  <CountUp duration={2} start={0} end={data.yesterday} />
                </div>
                <div
                  style={{
                    color: 'gray',
                    marginTop: '6px',
                    fontSize: '14px'
                  }}
                >
                  전일 콜 수
                </div>
              </div>
              <MiniCalendar month={new Date().getMonth() + 1} date={new Date().getDate() - 1} />
            </div>
            <div className="section" style={{ height: '100px', width: '23.5%', marginRight: '2%' }}>
              <div>
                <div style={{ fontSize: '26px', fontWeight: 'bold' }}>
                  <CountUp duration={2} start={0} end={data.storeCount} />
                </div>
                <div
                  style={{
                    color: 'gray',
                    marginTop: '6px',
                    fontSize: '14px'
                  }}
                >
                  책자 가맹점 수
                </div>
              </div>
              <Icon
                name="book"
                style={{
                  fontSize: '35px',
                  transform: 'rotate(-8deg)',
                  marginTop: '10px',
                  color: '#0c8599'
                }}
              />
            </div>
            <div className="section" style={{ height: '100px', width: '23.5%' }}>
              <div>
                <div style={{ fontSize: '26px', fontWeight: 'bold' }}>
                  <CountUp duration={2} start={0} end={data.appCount} />
                </div>
                <div
                  style={{
                    color: 'gray',
                    marginTop: '6px',
                    fontSize: '14px'
                  }}
                >
                  앱 가맹점 수
                </div>
              </div>
              <Icon
                name="mobile alternate"
                style={{
                  fontSize: '35px',
                  transform: 'rotate(-8deg)',
                  marginTop: '10px',
                  color: '#e64980'
                }}
              />
            </div>
          </div>
          <div className="flex">
            <div
              className="section"
              style={{
                width: '59%',
                height: '260px',
                marginRight: '2%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div className="title">
                <Icon name="call" />
                월별 콜 수
              </div>
              <LineChart data={data.chartData} />
            </div>
            <div
              className="section"
              style={{
                width: '39%',
                height: '260px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start'
              }}
            >
              <div className="title">
                <Icon name="question circle" />
                사용 방법
              </div>
            </div>
          </div>
          <div className="flex">
            <div
              className="section"
              style={{
                width: '49%',
                marginRight: '2%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start'
              }}
            >
              <div
                className="title"
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  width: '90%',
                  marginBottom: '2rem'
                }}
              >
                <span>
                  <Icon name="bell" />
                  공지 사항
                </span>
                <Link to="/main/notice" style={{ fontSize: '12px' }}>
                  더보기
                  <Icon name="angle right" />
                </Link>
              </div>
              <div style={{ width: '90%', margin: '1rem 5%' }}>
                <Table
                  index
                  notSearch
                  notPagination
                  data={data.noticeData}
                  perPage={5}
                  headers={[
                    {
                      title: '제목',
                      value: 'title',

                      render: item => <Link to={`/main/notice/${item.id}`}>{item.title}</Link>
                    },
                    { title: '작성자', value: 'user', width: 80 },
                    {
                      title: '작성일',
                      value: 'date',
                      render: item => item.date.toDate().toLocaleDateString()
                    }
                  ]}
                />
              </div>
            </div>
            <div
              className="section"
              style={{
                width: '49%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start'
              }}
            >
              <div
                className="title"
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  width: '90%',
                  marginBottom: '2rem'
                }}
              >
                <span>
                  <Icon name="comments" />내 문의 사항
                </span>
                <Link to="/main/ask" style={{ fontSize: '12px' }}>
                  더보기
                  <Icon name="angle right" />
                </Link>
              </div>
              <div style={{ width: '90%', margin: '1rem 5%' }}>
                <Table
                  index
                  notSearch
                  notPagination
                  data={data.askData}
                  perPage={5}
                  headers={[
                    {
                      title: '제목',
                      value: 'title',
                      render: item => <Link to={`/main/ask/${item.id}`}>{item.title}</Link>
                    },
                    { title: '작성자', value: 'user' },
                    {
                      title: '작성일',
                      value: 'date',
                      render: item => item.date.toDate().toLocaleDateString()
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader active />
      )}
    </Container>
  );
};

export default Home_daeri;

const MiniCalendar = ({ month, date }) => {
  return (
    <div
      style={{
        width: '35px',
        height: '40px',
        borderRadius: '4px',
        textAlign: 'center',
        boxShadow: '3px 1px 4px 2px rgba(0, 0, 0, 0.2)',
        transform: 'rotate(-8deg)'
      }}
    >
      <div style={{ color: 'white', fontSize: '8px', background: '#fa5252' }}>{month}월</div>
      <div style={{ fontSize: '16px', marginTop: '2px' }}>
        <b>{date}</b>
      </div>
    </div>
  );
};
