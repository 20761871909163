import React, { useEffect, useState, useContext } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import Table from "../../components/Table/Table";
import { Icon, Loader, Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FirebaseContext } from "../../firebase";
import { formatPhone } from "../../common/format";
import { jsonToSheet } from "../Admin/Admin";

const Container = styled.div`
  width: 95%;
  padding: 2rem 2.5%;
`;

const PublishList = props => {
  const [data, setData] = useState(null);
  const { db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);
  const { history } = props;

  useEffect(() => {
    const { level, id } = user;

    const loadUserData = () => {
      return db
        .collection("users")
        .orderBy("createdAt", "desc")
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push(doc.data()));
          return result;
        });
    };

    const loadStoreData = () => {
      return db
        .collection("stores")
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push({ ...doc.data(), pw: null }));
          return result;
        });
    };

    const loadData = async () => {
      const userData = await loadUserData();
      const storeData = await loadStoreData();

      let result = userData.map(item => {
        const activeStoreCount = storeData
          .filter(store => store.publisher.id === item.id)
          .filter(
            store =>
              store &&
              store.changeLog &&
              store.changeLog[store.changeLog.length - 1].status === "사용중"
          ).length;

        const appCount = storeData
          .filter(store => store.publisher.id === item.id)
          .filter(store => store.phone050_app).length;

        const allStoreCount = storeData.filter(
          store => store.publisher.id === item.id
        ).length;
        return { ...item, activeStoreCount, allStoreCount, appCount };
      });

      setData(result);
    };

    if (level === "총판") {
      history.push(`/main/publish_list_1`);
    } else if (level === "대리점") {
      // 대리점은 본인만 볼 수 있음
      history.push(`/main/publish/${id}`);
    } else if (level === "본사") {
      // 본사는 본사 아래만
      history.push(`/main/publish_list_2`);
    } else {
      // 관리자
      loadData();
    }
  }, []);

  const exportExcel = () => {
    const arr = [];
    data.forEach(item =>
      arr.push({
        분류: item.level,
        회사명: item.company,
        ID: item.id,
        대표자: item.ceo,
        전화번호: item.phone,
        주소: item.address,
        사업자번호: item.businessNumber,
        활성가맹점수: item.activeStoreCount,
        총가맹점수: item.allStoreCount
      })
    );
    jsonToSheet(arr, "K배달_협력사목록");
  };

  return (
    <Container>
      <Helmet>
        <title>협력사 - K배달 관리자시스템</title>
      </Helmet>
      <div
        style={{
          display: "flex",
          marginBottom: "1rem",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#0c8599",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Icon name="table" />
          <span>협력사</span>
          {data && (
            <span
              style={{ fontSize: "12px", color: "gray", marginLeft: "1rem" }}
            >
              총 {data.length} 개
            </span>
          )}
        </div>
        <div style={{ paddingRight: 0, marginRight: 0 }}>
          <Button
            size="small"
            style={{ fontWeight: "600", background: "white", color: "gray" }}
            onClick={exportExcel}
          >
            <Icon name="file excel" /> 엑셀 저장
          </Button>
          <Link to="/main/addpublish">
            <Button
              size="small"
              style={{
                fontWeight: "600",
                marginRight: 0,
                background: "white",
                color: "gray"
              }}
            >
              <Icon name="add" /> 협력사 등록
            </Button>
          </Link>
        </div>
      </div>

      {data ? (
        <Table
          data={data}
          index
          perPage={20}
          headers={[
            {
              title: "분류",
              value: "level",
              width: 60
            },
            {
              title: "회사명",
              value: "company",
              render: item => (
                <Link to={`/main/publish/${item.id}`}>{item.company}</Link>
              )
            },
            { title: "지역", value: "region", width: 120 },
            { title: "ID", value: "id", width: 80 },
            { title: "대표자", value: "ceo", width: 80 },
            {
              title: "전화번호",
              phone: "company",
              render: item => formatPhone(item.phone),
              width: 120
            },
            { title: "주소", value: "address" },
            { title: "사업자번호", value: "businessNumber", width: 120 },
            {
              title: "책자 가맹점수",
              notSearch: true,
              width: 100,
              value: "activeStoreCount",
              align: "center",
              render: item =>
                item.level === "대리점" &&
                `${item.activeStoreCount}/${item.allStoreCount}`
            },
            {
              title: "앱 가맹점수",
              notSearch: true,
              align: "center",
              width: 100,
              value: "activeStoreCount",
              render: item => item.level === "대리점" && `${item.appCount}`
            },
            {
              title: "상태",
              value: "status",
              render: item => (item.status ? "영업중" : "영업중지"),
              notSearch: true,
              width: 60
            }
          ]}
        />
      ) : (
        <Loader active />
      )}
    </Container>
  );
};

export default PublishList;
