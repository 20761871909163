import React, { Component } from 'react';
import styled from 'styled-components';
import { Input, Button, Icon, Loader, Table, Dropdown, Pagination } from 'semantic-ui-react';
import { withFirebase } from '../../firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { formatPhone } from '../../common/format';

const Container = styled.div`
  width: 98%;
  background: white;
  margin-bottom: 2rem;
  padding: 3rem 2rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #dfdfdf;
  .flex {
    display: flex;
    align-items: center;
  }
`;
const Row = styled(Table.Row)`
  &:hover {
    background: #e7f5ff;
  }
`;

class OrderPublishOne extends Component {
  state = {
    keyword: '',
    activePage: 1
  };

  componentDidMount() {
    if (this.props.user.level !== '대리점') {
      this.props.history.push('/main/orderpub');
    } else {
      this.loadData();
    }
  }

  search = () => {
    const { keyword, allData, selectedPublisher } = this.state;
    let newData;
    if (selectedPublisher) {
      newData = allData
        .filter(item => item.publisher && item.publisher.company === selectedPublisher)
        .filter(item => item.company && item.company.includes(keyword) === true);
    } else {
      newData = allData.filter(item => item.company && item.company.includes(keyword) === true);
    }

    this.setState({
      data: newData,
      activePage: 1
    });
    this.paginationFilter(newData);
  };

  resetData = () => {
    this.setState({
      data: this.state.allData,
      activePage: 1
    });
    this.paginationFilter(this.state.allData);
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  loadData = () => {
    let data = [];
    const { user } = this.props;
    this.props.firebase.db
      .collection('users')
      .doc(user.id)
      .get()
      .then(doc => {
        data.push(doc.data());
        this.setState({
          data
        });
        this.paginationFilter(data);
      });

    let counterData = [];
    this.props.firebase.db
      .collection('counters')
      .get()
      .then(snapshots => {
        snapshots.forEach(doc => counterData.push(doc.data()));
        this.setState({
          counterData
        });
      });
    const date = new Date();
    date.setTime(date.getTime() - 1 * 24 * 60 * 60 * 1000);

    let statisticData = [];
    this.props.firebase.db
      .collection('statistic')
      .where('date', '>=', date)
      .get()
      .then(snapshots => {
        snapshots.forEach(doc => statisticData.push(doc.data()));
        this.setState({
          todayData: statisticData.filter(
            item => item.date.toDate().getDate() === new Date().getDate()
          ),
          yesterdayData: statisticData.filter(
            item => item.date.toDate().getDate() !== new Date().getDate()
          )
        });
      });
  };

  countData = (data, id, type) => {
    const countData = data.filter(item => item.id === id);
    const date = new Date();

    if (type === 'all') {
      let counter = 0;
      let counter_app = 0;
      countData.forEach(item => (counter += item.allCount || 0));
      countData.forEach(item => (counter_app += item.allAppCount || 0));
      return { counter, counter_app };
    } else if (type === 'current') {
      let counter = 0;
      let counter_app = 0;
      let findDate = `${date.getFullYear()}-${date.getMonth() + 1}`;

      countData.forEach(item => item[findDate] && (counter += item[findDate]));
      countData.forEach(
        item => item[findDate + '-APP'] && (counter_app += item[findDate + '-APP'])
      );

      return { counter, counter_app };
    } else if (type === 'one') {
      let counter = 0;
      let counter_app = 0;
      let findDate;
      if (date.getMonth() === 0) {
        // 1월 일 경우 년도를 전년도로 설정
        findDate = `${date.getFullYear() - 1}-12`;
      } else {
        findDate = `${date.getFullYear()}-${date.getMonth()}`;
      }

      countData.forEach(item => item[findDate] && (counter += item[findDate]));
      countData.forEach(
        item => item[findDate + '-APP'] && (counter_app += item[findDate + '-APP'])
      );
      return { counter, counter_app };
    } else if (type === 'two') {
      let counter = 0;
      let counter_app = 0;
      let findDate;
      if (date.getMonth() === 0) {
        // 1월 일 경우 년도를 전년도로 설정
        findDate = `${date.getFullYear() - 1}-11`;
      } else {
        findDate = `${date.getFullYear()}-${date.getMonth() - 1}`;
      }

      countData.forEach(item => item[findDate] && (counter += item[findDate]));
      countData.forEach(
        item => item[findDate + '-APP'] && (counter_app += item[findDate + '-APP'])
      );
      return { counter, counter_app };
    }
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({
      activePage
    });
  };

  paginationFilter = data => {
    let result = [];
    const dataPerPage = 20;
    const last = Math.ceil(data.length / dataPerPage);
    for (let i = 0; i < last; i++) {
      if (i === 0) {
        result.push(data.slice(i + i * dataPerPage, dataPerPage + i * dataPerPage));
      } else {
        result.push(data.slice(i * dataPerPage, dataPerPage + i * dataPerPage));
      }
    }
    this.setState({
      paginationData: result
    });
  };

  onHeadquarterChange = (e, { value }) => {
    let publishOptions = [];
    this.state.publisherList
      .filter(item => item.headquarter && item.headquarter.company === value)
      .map((item, index) =>
        publishOptions.push({
          key: index,
          text: item.company,
          value: item.company
        })
      );
    this.setState({
      publishOptions
    });
  };

  onSelectChange = (e, { name, value }) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const headOptions = [];
    const { publishOptions } = this.state;
    this.state.headQuarterList &&
      this.state.headQuarterList.map((item, index) => {
        headOptions.push({
          key: index,
          text: item.company,
          value: item.company
        });
        return null;
      });

    return (
      <Container>
        <div
          style={{
            borderBottom: '1.5px solid lightgray',
            padding: '1rem 3rem',
            fontSize: '17px',
            marginBottom: '2rem',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <Icon name="call" style={{ marginRight: '1rem' }} />
            <span>협력사 통계</span>
            <div
              style={{
                margin: '1rem 1rem 0 0.5rem',
                fontSize: '14px',
                color: '#fa5252'
              }}
            >
              ※ 통화실패, 통화시간 0초 건은 제외됩니다. 1시간 마다 업데이트 됩니다.
            </div>
          </div>
        </div>
        {this.props.user.level === '본사' && (
          <div style={{ width: '100%' }}>
            <div className="flex" style={{ justifyContent: 'center' }}>
              <div>협력사명</div>
              <Input style={{ margin: '0 1rem' }}>
                <input onChange={this.onChange} name="keyword" id="search-con" />
              </Input>
              <div>협력사 소속</div>
              <Dropdown
                onChange={this.onHeadquarterChange}
                options={headOptions}
                placeholder="본사"
                selection
                style={{ width: '50px', margin: '0 0.5rem' }}
                name="selectedHeadquarter"
              />

              <Dropdown
                onChange={this.onSelectChange}
                options={publishOptions}
                placeholder={publishOptions ? '총판' : '본사를 선택하세요'}
                selection
                name="selectedPublisher"
                style={{ width: '50px', margin: '0 0.5rem' }}
              />
            </div>
            <div
              className="flex"
              style={{
                justifyContent: 'center',
                margin: '1rem 0',
                borderBottom: '1px solid lightgrey',
                paddingBottom: '1rem'
              }}
            >
              <Button size="small" primary onClick={this.search}>
                <Icon name="search" />
                검색
              </Button>
              <Button size="small" onClick={this.resetData}>
                <Icon name="redo" />
                초기화
              </Button>
            </div>
          </div>
        )}
        <Table celled size="small" style={{ textAlign: 'center' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>협력사명</Table.HeaderCell>
              <Table.HeaderCell>대표자</Table.HeaderCell>
              <Table.HeaderCell>전화번호</Table.HeaderCell>
              <Table.HeaderCell>전일call</Table.HeaderCell>
              <Table.HeaderCell>당일call</Table.HeaderCell>
              <Table.HeaderCell>2개월전call</Table.HeaderCell>
              <Table.HeaderCell>1개월전call</Table.HeaderCell>
              <Table.HeaderCell>당월call</Table.HeaderCell>
              <Table.HeaderCell>누적call</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.paginationData ? (
              <TableItem
                data={this.state.paginationData[this.state.activePage - 1]}
                activePage={this.state.activePage}
                counterData={this.state.counterData}
                countFn={this.countData}
                yesterdayData={this.state.yesterdayData}
                todayData={this.state.todayData}
                totalPages={this.state.data && Math.ceil(this.state.data.length / 20)}
                length={this.state.data && this.state.data.length}
              />
            ) : (
              <Table.Row>
                <Loader active />
              </Table.Row>
            )}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="10" style={{ textAlign: 'center' }}>
                {this.state.data && (
                  <Pagination
                    activePage={this.state.activePage}
                    onPageChange={this.handlePaginationChange}
                    totalPages={Math.ceil(this.state.data.length / 20)}
                  />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(connect(mapStateToProps), withFirebase, withRouter)(OrderPublishOne);

const TableItem = ({
  data,
  activePage,
  counterData,
  countFn,
  yesterdayData,
  todayData,
  totalPages,
  length
}) => {
  return (
    <>
      {todayData &&
        yesterdayData &&
        counterData &&
        data &&
        data.map((item, index) => (
          <Row style={{ fontSize: '12px' }} key={index}>
            <Table.Cell>
              {/* 첫페이지 */}
              {activePage === 1 &&
                activePage !== totalPages &&
                totalPages * 20 - (20 - (length % 20)) - index}
              {/* 중간 페이지 */}
              {activePage !== 1 &&
                activePage !== totalPages &&
                (totalPages - activePage + 1) * 20 - (20 - (length % 20)) - index}
              {/* 마지막페이지 */}
              {activePage === totalPages && data.length - index}
            </Table.Cell>

            <Table.Cell>
              <Link to={`/main/orderstore/${item.id}`}>{item.company}</Link>
            </Table.Cell>
            <Table.Cell>{item.ceo}</Table.Cell>
            <Table.Cell>{formatPhone(item.phone)}</Table.Cell>

            <Table.Cell>
              {yesterdayData.filter(doc => item.id === doc.publisher.id).length || 0}/
              {yesterdayData
                .filter(doc => item.id === doc.publisher.id)
                .filter(doc => doc.callType === 'app').length || 0}
            </Table.Cell>
            <Table.Cell>
              {todayData.filter(doc => item.id === doc.publisher.id).length || 0}/
              {todayData
                .filter(doc => item.id === doc.publisher.id)
                .filter(call => call.callType === 'app').length || 0}
            </Table.Cell>
            <Table.Cell>
              {countFn(counterData, item.id, 'two').counter}/
              {countFn(counterData, item.id, 'two').counter_app}
            </Table.Cell>
            <Table.Cell>
              {countFn(counterData, item.id, 'one').counter}/
              {countFn(counterData, item.id, 'one').counter_app}
            </Table.Cell>
            <Table.Cell>
              {countFn(counterData, item.id, 'current').counter}/
              {countFn(counterData, item.id, 'current').counter_app}
            </Table.Cell>
            <Table.Cell>
              {countFn(counterData, item.id, 'all').counter}/
              {countFn(counterData, item.id, 'all').counter_app}
            </Table.Cell>
          </Row>
        ))}
    </>
  );
};
