import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Input, Button, Icon, Modal, Image, Loader } from "semantic-ui-react";
import axios from "axios";
import { checkBn } from "../../common/checkRule";
import { deleteEmpty, formatPhone } from "../../common/format";
import { FirebaseContext } from "../../firebase";
import { useSelector } from "react-redux";

const Container = styled.div`
  width: 95%;
  padding: 2rem 0;
  .flex {
    display: flex;
  }
`;

const StoreAdd = ({ history }) => {
  const [data, setData] = useState({ files: [] });
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { db, storage } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);

  const createRandomCode = () => {
    return axios
      .get("https://us-central1-phone-fa49a.cloudfunctions.net/api/random")
      .then(result => {
        return result.data.code;
      });
  };

  useEffect(() => {
    const getCode = async () => {
      const code = await createRandomCode();
      setData({ ...data, code });
    };
    getCode();
  }, []);

  const onPhoneInputChange = e => {
    // 공백 지우고  '-' 추가하여 등록되게 끔
    const value = formatPhone(deleteEmpty(e.target.value));
    setData({ ...data, [e.target.name]: value || "" });
  };

  const onBnInputChange = e => {
    const result = checkBn(e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
    setError({
      ...error,
      bn: result ? true : "사업자번호가 형식에 맞지 않습니다."
    });
  };

  const onSubmit = async () => {
    if (!checkBn(data.businessNumber))
      return alert("사업자번호를 형식에 맞게 써주세요.");
    if (!data.tel) {
      alert("일반전화번호를 입력하세요. (필수)");
      return null;
    }
    if (!data.company) {
      alert("가맹점명을 입력하세요. (필수)");
      return null;
    }

    setIsLoading(true);

    const distributor = user.publisher;
    const publisher = {
      company: user.company,
      id: user.id
    };

    const getFileUrls = data.files.map(
      file =>
        new Promise(resolve => {
          storage
            .ref()
            .child("users/" + file.file.name)
            .put(file.file)
            .then(result => {
              result.ref.getDownloadURL().then(url => {
                resolve({ name: file.name, url });
              });
            })
            .catch(err => console.log(err));
        })
    );

    const result = await Promise.all(getFileUrls);
    const phoneData = await connect050();

    let newStore = {
      ...data,
      distributor,
      publisher,
      phone050: phoneData.phone050,
      changeLog: [phoneData.changeLog],
      createdAt: new Date()
    };

    result.forEach(item => Object.assign(newStore, { [item.name]: item.url }));
    delete newStore.files;
    console.log(newStore);

    db.collection("stores")
      .doc(newStore.code)
      .set(newStore)
      .then(() => {
        history.push("/main/liststore");
      })
      .catch(err => console.log(err));
  };

  const fileUpload = e => {
    const file = e.target.files[0];
    if (!file) return;

    // 파일 용량체크
    if (file.size > 5 * 1024 * 1024) {
      e.target.value = null;
      return alert("첨부 이미지의 최대 사이즈는 5MB 입니다.");
    }

    setData({
      ...data,
      files: data.files.concat({
        name: e.target.name,
        file
      })
    });
  };

  // 050 번호 연동
  const connect050 = () => {
    const newStore = data;
    const req = {
      rn: data.tel.replace(/-/gi, ""),
      linkYn: "y",
      shopName: data.company,
      callMent: "",
      colorRing: ""
    };
    console.log(req);

    return axios
      .get("https://us-central1-phone-fa49a.cloudfunctions.net/api/connect")
      .then(result => {
        // 남은 050번호 없을때
        if (result.data.error) {
          return alert(result.data.error);
        }
        const data = { ...result.data };
        const vn = result.data.phone050.replace(/-/gi, "");

        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=utf-8";
        return axios
          .get(
            `https://m.callmix.co.kr/cmxapi/vnMapping?id=${data.id}&pw=${data.pw}&rn=${req.rn}&linkYn=${req.linkYn}&vn=${vn}&shopName=${req.shopName}&colorRing=&callMent=&recordYn=y`
          )
          .then(result => {
            if (result.data.resCd === "SUCCESS") {
              return db
                .collection("phones")
                .doc(data.dbId)
                .update({
                  isAvailable: false,
                  storeName: newStore.company
                })
                .then(() => {
                  const today = new Date();
                  today.setTime(today.getTime() + 3600000 * 9);

                  const changeLog = {
                    storeName: newStore.company,
                    tel: newStore.tel,
                    phone050: result.data.resObj.vn,
                    status: "사용중",
                    createdAt: today.toISOString()
                  };
                  return { phone050: result.data.resObj.vn, changeLog };
                })
                .catch(err => console.log(err));
            } else {
              alert("알수 없는 에러가 발생했습니다. 관리자에게 문의해주세요.");
            }
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  };

  const onInputChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <Container>
      <div
        style={{
          padding: "1rem 3rem",
          fontSize: "17px",
          marginBottom: "2rem",
          fontWeight: "bold",
          color: "#0c8599"
        }}
      >
        <Icon name="add user" style={{ marginRight: "1rem" }} />
        가맹점 등록
      </div>
      <div className="flex">
        <InputSet
          name="협력사명"
          id="publisher"
          onChange={onInputChange}
          disabled={true}
          value={user.company}
        />
        <InputSet
          name="가맹점코드"
          id="code"
          onChange={onInputChange}
          disabled={true}
          value={data && data.code}
        />
        <div style={{ display: "flex", alignItems: "center" }} />
      </div>
      <div className="flex">
        <InputSet name="영업 담당자" id="salesman" onChange={onInputChange} />
      </div>

      <div className="flex">
        <InputSet name="가맹점명" id="company" onChange={onInputChange} />
        <InputSet
          name="일반전화번호"
          placeholder="예시: 02-000-0000"
          id="tel"
          onChange={onPhoneInputChange}
        />
      </div>
      <div className="flex">
        <InputSet name="대표자명" id="ceo" onChange={onInputChange} />
      </div>
      <div className="flex" style={{ alignItems: "center" }}>
        <InputSet
          name="사업자번호"
          id="businessNumber"
          onChange={onBnInputChange}
          placeholder="예시: 123-45-67890"
        />
        <div
          style={{
            marginLeft: "0.5rem",
            color: "#fa5252"
          }}
        >
          {error && error.bn}
        </div>
      </div>

      <div className="flex">
        <InputSet
          name="핸드폰번호"
          placeholder="예시: 010-0000-0000"
          id="phone"
          onChange={onPhoneInputChange}
        />
      </div>
      <div className="flex">
        <InputSetLarge name="주소" id="address" onChange={onInputChange} />
      </div>
      <div className="flex">
        <InputSetLarge name="이메일주소" id="email" onChange={onInputChange} />
      </div>
      <FileSet
        data={data && data.photo}
        onChange={fileUpload}
        name={"photo"}
        title={"메뉴판"}
      />
      <FileSet
        data={data && data.applicationForm}
        onChange={fileUpload}
        name={"applicationForm"}
        title={"신청서"}
      />
      <FileSet
        data={data && data.businessRegistForm}
        onChange={fileUpload}
        name={"businessRegistForm"}
        title={"사업자등록증"}
      />
      <FileSet
        data={data && data.copiedBankBook}
        onChange={fileUpload}
        name={"copiedBankBook"}
        title={"통장사본"}
      />

      <div
        className="flex"
        style={{ justifyContent: "center", marginTop: "1rem" }}
      >
        <Button
          primary
          style={{ minWidth: "120px", width: "40%", background: "#0c8599" }}
          onClick={() => {
            onSubmit();
          }}
          disabled={isLoading}
        >
          {isLoading ? <Loader active /> : "등록"}
        </Button>
      </div>
    </Container>
  );
};

export default StoreAdd;

const FileSet = ({ data, onChange, name, title }) => {
  return (
    <div className="flex" style={{ marginTop: "1rem", alignItems: "center" }}>
      <div style={{ marginLeft: "4.5%" }}>{title}</div>
      <div style={{ marginLeft: "1rem" }}>
        <input
          type="file"
          name={name}
          onChange={onChange}
          accept="image/gif,image/jpeg,image/png,application/pdf"
        />
      </div>
      <div>
        {data && (
          <Modal trigger={<Button size="small">상세보기</Button>}>
            <Image centered size="big" src={data} />
          </Modal>
        )}
      </div>
    </div>
  );
};

const InputSet = ({
  name,
  onChange,
  id,
  placeholder,
  disabled,
  value,
  width
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "50%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      <div>{name}</div>
      {!disabled ? (
        <Input
          placeholder={placeholder || name}
          style={{ width: "75%", marginLeft: "1rem" }}
        >
          <input name={id} onChange={onChange} />
        </Input>
      ) : (
        <Input
          placeholder={placeholder || name}
          style={{ width: width || "75%", marginLeft: "1rem" }}
          defaultValue={value}
        >
          <input name={id} onChange={onChange} disabled />
        </Input>
      )}
    </div>
  );
};

const InputSetLarge = ({ name, onChange, id }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      {" "}
      <div>{name}</div>
      <Input placeholder={name} style={{ width: "88%", marginLeft: "1rem" }}>
        <input name={id} onChange={onChange} />
      </Input>
    </div>
  );
};
