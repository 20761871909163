import React, { useEffect, useState, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Table from '../../components/Table/Table';
import { Icon, Loader, Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';
import { formatPhone, formatMiddleStar } from '../../common/format';
import { jsonToSheet } from '../Admin/Admin';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const Container = styled.div`
  width: 96%;
  padding: 1.5rem 2%;
  .flex {
    display: flex;
    align-items: center;
  }
`;

const OrderMonth = props => {
  const { db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);
  const date = new Date();
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
  );
  const [endDate, setEndDate] = useState(date);
  const { history } = props;
  const [isReveal, setReveal] = useState(false);

  useEffect(() => {
    const limitDay = endDate - startDate;
    if (limitDay < 0) {
      return alert('마지막 기간이 시작기간 보다 빠릅니다.');
    }
    if (Math.floor(limitDay / 86400000) > 30) {
      return alert('최대 검색 가능 일수는 30일 입니다.');
    }

    setData(null);
    // 자정까지
    const noonEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);

    const loadData = () => {
      db.collection('statistic')
        .where('date', '>=', startDate)
        .where('date', '<=', noonEndDate)
        .orderBy('date', 'desc')
        .limit(10000)
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc =>
            result.push({
              ...doc.data(),
              id: doc.id,
              publisherName: doc.data().publisher.company
            })
          );
          setData(result);
        });
    };

    const { level } = user;
    if (level === '대리점') {
      history.push(`/main/ordermonth_1`);
    } else if (level === '본사') {
      history.push(`/main/ordermonth_2`);
    } else if (level === '총판') {
      history.push(`/main/ordermonth_3`);
    } else {
      // 관리자 레벨
      loadData();
    }
  }, [startDate, endDate]);

  const getRecord = item => {
    const { vn, seq, date } = item;
    let month = date.toDate().getMonth() + 1;
    const year = date.toDate().getFullYear();

    if (month < 10) {
      month = '0' + month;
    }
    axios
      .get(
        `https://m.callmix.co.kr/cmxapi/recFileUrl?id=csj7979&pw=csj2718&vn=${vn}&callLogSeq=${seq}&yyyyMm=${year}${month}`
      )
      .then(result => {
        if (result.data.resObj) {
          let ok = window.confirm(
            '녹음파일 청취는 반드시 고객의 동의가 필요합니다. 동의를 받으셨습니까?'
          );
          if (ok) {
            window.open(result.data.resObj.recFileUrl, 'a', 'width=400, height=300');
          } else {
            return null;
          }
        } else {
          alert('녹취 파일이 존재하지 않습니다.');
        }
      });
  };

  const couponChange = (id, current) => {
    db.collection('statistic')
      .doc(id)
      .update({
        coupon: !current
      })
      .then(alert('처리되었습니다.'));
  };

  const exportExcel = () => {
    const arr = [];
    data.forEach(item =>
      arr.push({
        협력사명: item.publisher.company,
        가맹점코드: item.code,
        가맹점명: item.shopName,
        착신번호: item.rn,
        발신번호: formatMiddleStar(item.an, isReveal),
        통화시간: item.callDuration,
        통화시작시간: item.callStartTime,
        콜상태: item.callStat
      })
    );
    jsonToSheet(arr, 'K배달_월별주문조회');
  };

  return (
    <Container>
      <Helmet>
        <title>주문조회 - K배달 관리자시스템</title>
      </Helmet>
      <div
        style={{
          fontSize: '17px',
          marginBottom: '0.5rem',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: '1rem'
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: '600',
            color: '#0c8599',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div>
            <Icon name="call" style={{ marginRight: '0.5rem' }} onClick={_ => setReveal(true)} />
            <span>주문 조회</span>
          </div>

          <div
            style={{
              marginTop: '1rem',
              marginLeft: '2rem',
              fontSize: '12px',
              color: '#fa5252'
            }}
          >
            1. 통화실패, 통화시간 0초 건은 제외됩니다. (주문내역은 1시간 마다 업데이트 됩니다.){' '}
            <br />
            2. 당일 기록을 우선 출력하며 최대 검색 기간은 30일 까지 조회 가능합니다. (최대 10000건)
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <Button
            size="small"
            style={{
              fontWeight: '600',
              background: 'white',
              color: 'gray'
            }}
            onClick={exportExcel}
          >
            <Icon name="file excel" /> 엑셀 저장
          </Button>
        </div>
      </div>
      <div
        style={{
          color: 'gray',
          display: 'flex',
          background: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '4px',
          justifyContent: 'center',
          marginBottom: '0.5rem'
        }}
      >
        <span style={{ marginRight: '1rem' }}>검색일</span>
        <DatePicker
          dateFormat="yyyy. MM. dd"
          selected={startDate}
          locale={ko}
          onChange={value => {
            console.log(value);
            setStartDate(value);
          }}
          customInput={<CustomDateButton />}
        />
        <div style={{ fontSize: '18px', margin: '0 0.5rem' }}>~</div>
        <DatePicker
          dateFormat="yyyy. MM. dd"
          selected={endDate}
          locale={ko}
          onChange={value => {
            setEndDate(value);
          }}
          customInput={<CustomDateButton />}
        />
      </div>

      {data ? (
        <Table
          data={data}
          index
          perPage={20}
          headers={[
            {
              title: '통화일자',
              value: 'date',
              notSearch: true,
              width: 100,
              render: item => item.date.toDate().toLocaleDateString()
            },
            { title: '협력사명', value: 'publisherName' },
            { title: '가맹점코드', value: 'code', noRender: true },
            {
              title: '가맹점명',
              value: 'shopName',
              width: 140,
              render: item => (
                <>
                  <div>{item.shopName}</div>
                  <div style={{ color: 'gray', fontSize: '12px' }}>[{item.code}]</div>
                </>
              )
            },
            {
              title: '착신번호',
              value: 'rn',
              align: 'right',
              render: item => <span>{formatPhone(item.rn)}</span>
            },
            {
              title: '발신번호',
              value: 'an',
              align: 'right',
              render: item => <span>{formatMiddleStar(item.an, isReveal)}</span>
            },
            {
              title: '경로',
              value: 'callType',
              render: item =>
                item.callType === 'app' ? (
                  <span
                    style={{
                      background: '#e64980',
                      color: 'white',
                      fontWeight: 'bold',
                      padding: '2px 6px',
                      borderRadius: '8px'
                    }}
                  >
                    앱
                  </span>
                ) : (
                  <span
                    style={{
                      background: '#0c8599',
                      color: 'white',
                      fontWeight: 'bold',
                      padding: '2px 6px',
                      borderRadius: '8px'
                    }}
                  >
                    책자
                  </span>
                ),
              align: 'center'
            },
            {
              title: '통화시간(초)',
              value: 'callDuration',
              align: 'right',
              notSearch: true
            },
            {
              title: '통화시작시간',
              value: 'callStartTime',

              align: 'right',
              render: item => item.callStartTime.split(' ')[1]
            },
            {
              title: '파일',
              value: 'seq',
              align: 'center',
              notSearch: true,
              width: 65,
              render: item => (
                <Button
                  size="small"
                  style={{
                    padding: '0.2rem 0.6rem',
                    background: '#0c8599',
                    color: 'white'
                  }}
                  onClick={() => {
                    getRecord(item);
                  }}
                >
                  듣기
                </Button>
              )
            },
            {
              title: '지급여부',
              value: 'toggle',
              align: 'center',
              notSearch: true,
              width: 80,
              render: item => (
                <CustomButton
                  size="tiny"
                  onClick={() => {
                    couponChange(item.id, item.coupon);
                  }}
                  value={item.coupon}
                />
              )
            }
          ]}
        />
      ) : (
        <Loader active />
      )}
    </Container>
  );
};

export default OrderMonth;

const CustomDateButton = ({ value, onClick }) => {
  return (
    <Button
      size="small"
      style={{
        color: 'white',
        background: '#0c8599',
        padding: '0.2rem 0.5rem'
      }}
      onClick={() => {
        onClick();
      }}
    >
      {value}
    </Button>
  );
};

const CustomButton = ({ value, onClick }) => {
  const [flag, setFlag] = useState(value ? '지급' : '미지급');

  return (
    <Button
      size="small"
      style={{ padding: '0.2rem 0.5rem' }}
      color={flag === '지급' ? 'orange' : 'grey'}
      onClick={() => {
        onClick();
        setFlag(flag === '지급' ? '미지급' : '지급');
      }}
    >
      {flag}
    </Button>
  );
};
