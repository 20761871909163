import React, { Component } from "react";
import styled from "styled-components";
import { Button, Icon } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";

const Container = styled.div`
  width: 90%;
  margin-bottom: 2rem;
  padding: 3rem 2rem;
  max-width: 1000px;
  .flex {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .head {
    background: #0c8599;
    padding: 1rem 1rem;
    font-weight: bold;
    color: white;
  }
  .body {
    padding: 1rem 1rem;
  }
`;

class NoticeDetail extends Component {
  state = {
    data: null
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { id } = this.props.match.params;
    this.props.firebase.db
      .collection("notice")
      .doc(id)
      .get()
      .then(doc => {
        this.setState({
          data: { ...doc.data(), id: doc.id }
        });
      });
  };

  render() {
    const { data } = this.state;
    const { user } = this.props;

    return (
      <Container>
        {data && (
          <>
            <div
              style={{
                padding: "1rem 3rem",
                marginBottom: "2rem",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "#0c8599",
                fontSize: "16px"
              }}
            >
              <div>
                <Icon name="th list" style={{ marginRight: "1rem" }} />
                <span>공지사항</span>
              </div>
            </div>
            <div>
              <div
                className="flex"
                style={{
                  marginBottom: "1rem",
                  width: "100%",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  background: "white"
                }}
              >
                <span className="head" style={{ marginRight: "1rem" }}>
                  제목
                </span>
                <span className="body" style={{ width: "50%" }}>
                  {data.title}
                </span>
                <span className="head" style={{ marginRight: "1rem" }}>
                  작성자
                </span>
                <span className="body" style={{ marginRight: "1rem" }}>
                  {data.user}
                </span>
                <span className="head" style={{ marginRight: "1rem" }}>
                  작성일
                </span>
                <span className="body">
                  {data.date && data.date.toDate().toLocaleDateString()}
                </span>
              </div>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "100%",
                  padding: "1rem 1rem",
                  minHeight: "15rem",
                  background: "white"
                }}
              >
                {this.state.data.content.split("<br/>").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </div>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "100%",
                  marginBottom: "2rem",
                  marginTop: "1rem",
                  background: "white"
                }}
                className="flex"
              >
                <div className="head">첨부파일</div>
                <div className="body">
                  {data.fileURL ? <a href={data.fileURL}>다운로드</a> : "없음"}
                </div>
              </div>
              <div
                className="flex"
                style={{ marginBottom: "1rem", justifyContent: "center" }}
              >
                <Link to={`/main/notice`}>
                  <Button size="small">
                    <Icon name="th list" />
                    목록으로
                  </Button>
                </Link>
                {data.userId === user.id && (
                  <Link to={`/main/editnotice/${data.id}`}>
                    <Button size="small">수정하기</Button>
                  </Link>
                )}
              </div>
            </div>{" "}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(NoticeDetail);
