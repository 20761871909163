import React, { Component } from 'react';
import styled from 'styled-components';
import { Input, Button, Icon, Loader, Modal, Image } from 'semantic-ui-react';
import { withFirebase } from '../../firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { formatPhone, deleteEmpty } from '../../common/format';
import { checkBn } from '../../common/checkRule';

const callLinkID = process.env.REACT_APP_CALL_LINK_ID;
const callLinkPW = process.env.REACT_APP_CALL_LINK_PW;

const Container = styled.div`
  width: 90%;
  padding: 3rem 2rem;
  .flex {
    display: flex;
  }
`;

class StoreEdit extends Component {
  state = {
    isLoading: false,
    phoneLoading: false,
    files: []
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id === ':id') {
      this.props.history.push(`/main/liststore`);
    } else {
      this.loadData();
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.user) {
      return { user: nextProps.user };
    }
    return null;
  }

  onInputChange = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  onPhoneInputChange = e => {
    // 공백 지우고  '-' 추가하여 등록되게 끔
    const value = formatPhone(deleteEmpty(e.target.value));
    this.setState({
      data: { ...this.state.data, [e.target.name]: value || '' }
    });
  };

  onBnInputChange = e => {
    const result = checkBn(e.target.value);
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      error: { bn: result ? true : '사업자번호가 형식에 맞지 않습니다.' }
    });
  };

  loadData = () => {
    const { id } = this.props.match.params;
    this.props.firebase.db
      .collection('stores')
      .doc(id)
      .get()
      .then(doc =>
        this.setState({
          data: doc.data()
        })
      );
  };

  onSubmit = async (redirect, newChangeLog, newChangeLog_app) => {
    const { data } = this.state;

    if (!checkBn(data.businessNumber)) return alert('사업자번호를 형식에 맞게 써주세요.');

    this.setState({
      isLoading: true
    });

    const promises = this.state.files.map(
      file =>
        new Promise(resolve => {
          this.props.firebase.storage
            .ref()
            .child('users/' + file.file.name + '_' + new Date().getMilliseconds())
            .put(file.file)
            .then(result => {
              result.ref.getDownloadURL().then(url => {
                resolve({ name: file.name, url });
              });
            })
            .catch(err => console.log(err));
        })
    );
    const result = await Promise.all(promises);
    let newData = {
      ...data
    };
    result.forEach(item => Object.assign(newData, { [item.name]: item.url }));

    const existedLog = data.changeLog || [];
    const existedLog_app = data.changeLog_app || [];

    if (newChangeLog) newData.changeLog = [...existedLog, newChangeLog];

    if (newChangeLog_app) newData.changeLog_app = [...existedLog_app, newChangeLog_app];

    const { db } = this.props.firebase;

    db.collection('stores')
      .doc(data.code)
      .update({ ...newData, editedAt: new Date() })
      .then(() => {
        this.setState({
          isLoading: false
        });
        if (redirect) {
          this.props.history.push(`/main/store/${data.code}`);
        }
      })
      .catch(err => console.log(err));
  };

  // 사진 추가용
  fileUpload = e => {
    const file = e.target.files[0];
    if (!file) return;

    // 파일 용량체크
    if (file.size > 5 * 1024 * 1024) {
      e.target.value = null;
      return alert('첨부 이미지의 최대 사이즈는 5MB 입니다.');
    }

    this.setState({
      files: this.state.files.concat({
        name: e.target.name,
        file
      })
    });
  };

  // 050 번호 연동
  connect050 = () => {
    // 번호가 존재하면 해지 동작
    if (this.state.data.phone050) {
      return this.disconnect050(this.state.data.phone050);
    }
    this.setState({
      phoneLoading: true
    });
    const req = {
      rn: this.state.data.tel.replace(/-/gi, ''),
      linkYn: 'y',
      shopName: this.state.data.company,
      callMent: '',
      colorRing: ''
    };

    axios.defaults.baseURL = 'https://us-central1-phone-fa49a.cloudfunctions.net/api';
    axios
      .get('/connect')
      .then(result => {
        // 남은 050번호 없을때
        if (result.data.error) {
          alert(result.data.error);
          this.setState({
            phoneLoading: false
          });
          return null;
        }
        // console.log(result);
        const data = { ...result.data };

        const vn = result.data.phone050.replace(/-/gi, '');

        axios.defaults.baseURL = 'https://m.callmix.co.kr/cmxapi';
        axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
        axios
          .get(
            `/vnMapping?id=${data.id}&pw=${data.pw}&rn=${req.rn}&linkYn=${req.linkYn}&vn=${vn}&shopName=${req.shopName}&colorRing=&callMent=&recordYn=y`
          )
          .then(result => {
            if (result.data.resCd === 'SUCCESS') {
              this.setState({
                data: { ...this.state.data, phone050: result.data.resObj.vn }
              });
              this.props.firebase.db
                .collection('phones')
                .doc(data.dbId)
                .update({
                  isAvailable: false,
                  storeName: this.state.data.company
                })
                .then(() => {
                  const today = new Date();
                  today.setTime(today.getTime() + 3600000 * 9);

                  const changeLog = {
                    storeName: this.state.data.company,
                    tel: this.state.data.tel,
                    phone050: result.data.resObj.vn,
                    status: '사용중',
                    createdAt: today
                  };
                  this.onSubmit(false, changeLog);

                  alert('번호가 연동 되었습니다. (자동저장됩니다.)');
                  this.setState({
                    phoneLoading: false
                  });
                  this.props.history.push(`/main/store/${this.state.data.code}`);
                })
                .catch(err => console.log(err));
            } else {
              alert('알수 없는 에러가 발생했습니다. 관리자에게 문의해주세요.');
            }
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  };

  // 앱용 050 번호 연동
  connect050_app = () => {
    // 번호가 존재하면 해지 동작
    if (this.state.data.phone050_app) {
      return this.disconnect050_app(this.state.data.phone050_app);
    }
    this.setState({
      phoneLoading: true
    });
    const req = {
      rn: this.state.data.tel.replace(/-/gi, ''),
      linkYn: 'y',
      shopName: this.state.data.company,
      callMent: '',
      colorRing: ''
    };

    axios.defaults.baseURL = 'https://us-central1-phone-fa49a.cloudfunctions.net/api';
    axios
      .get('/connect')
      .then(result => {
        // 남은 050번호 없을때
        if (result.data.error) {
          alert(result.data.error);
          this.setState({
            phoneLoading: false
          });
          return null;
        }
        // console.log(result);
        const data = { ...result.data };

        const vn = result.data.phone050.replace(/-/gi, '');

        axios.defaults.baseURL = 'https://m.callmix.co.kr/cmxapi';
        axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
        axios
          .get(
            `/vnMapping?id=${data.id}&pw=${data.pw}&rn=${req.rn}&linkYn=${req.linkYn}&vn=${vn}&shopName=${req.shopName}(App)&colorRing=&callMent=&recordYn=y`
          )
          .then(result => {
            if (result.data.resCd === 'SUCCESS') {
              this.setState({
                data: {
                  ...this.state.data,
                  phone050_app: result.data.resObj.vn
                }
              });
              this.props.firebase.db
                .collection('phones')
                .doc(data.dbId)
                .update({
                  isAvailable: false,
                  storeName: this.state.data.company
                })
                .then(() => {
                  const today = new Date();

                  const changeLog_app = {
                    storeName: this.state.data.company,
                    tel: this.state.data.tel,
                    phone050_app: result.data.resObj.vn,
                    detail: '번호발급',
                    status:
                      (this.state.data.changeLog_app &&
                        this.state.data.changeLog_app[this.state.data.changeLog_app.length - 1]
                          .status) ||
                      '',
                    createdAt: today
                  };
                  this.onSubmit(false, null, changeLog_app);

                  alert('앱용 050번호가 연동 되었습니다. (자동 저장 됩니다.)');
                  this.setState({
                    phoneLoading: false
                  });
                  this.props.history.push(`/main/store/${this.state.data.code}`);
                })
                .catch(err => console.log(err));
            } else {
              alert('알수 없는 에러가 발생했습니다. 관리자에게 문의해주세요.');
            }
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  };

  // 이미 저장된 050 번호에서 일반전화 번호 변경시
  connectDirect = type => {
    this.setState({
      phoneLoading: true
    });
    if (!this.state.data.phone050) {
      alert('발급된 050번호가 없습니다.');
      return null;
    }
    const req = {
      rn: this.state.data.tel.replace(/-/gi, ''),
      linkYn: 'y',
      shopName: this.state.data.company,
      callMent: '',
      colorRing: '',
      vn: this.state.data.phone050
    };

    axios.defaults.baseURL = 'https://m.callmix.co.kr/cmxapi';
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
    axios
      .get(
        `/vnMapping?id=${callLinkID}&pw=${callLinkPW}&rn=${req.rn}&linkYn=${req.linkYn}&vn=${req.vn}&shopName=${req.shopName}&colorRing=&callMent=`
      )
      .then(result => {
        if (result.data.resCd === 'SUCCESS') {
          this.setState({
            data: { ...this.state.data, phone050: result.data.resObj.vn }
          });
          const today = new Date();
          today.setTime(today.getTime() + 3600000 * 9);

          const changeLog = {
            storeName: this.state.data.company,
            tel: this.state.data.tel,
            phone050: result.data.resObj.vn,
            status: type,
            createdAt: today
          };
          this.onSubmit(false, changeLog);
          alert('번호가 연동 되었습니다. (자동저장됩니다.)');
          this.setState({
            phoneLoading: false
          });
          this.props.history.push(`/main/store/${this.state.data.code}`);
        } else {
          alert('알수 없는 에러가 발생했습니다. 관리자에게 문의해주세요.');
        }
      })
      .catch(err => console.log(err));
  };

  // 앱용 이미 저장된 050 번호에서 일반전화 번호 변경시
  connectDirect_app = () => {
    this.setState({
      phoneLoading: true
    });
    if (!this.state.data.phone050_app) return alert('발급된 앱용 050번호가 없습니다.');

    const req = {
      rn: this.state.data.tel.replace(/-/gi, ''),
      linkYn: 'y',
      shopName: this.state.data.company,
      callMent: '',
      colorRing: '',
      vn: this.state.data.phone050_app
    };

    axios.defaults.baseURL = 'https://m.callmix.co.kr/cmxapi';
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
    axios
      .get(
        `/vnMapping?id=${callLinkID}&pw=${callLinkPW}&rn=${req.rn}&linkYn=${req.linkYn}&vn=${req.vn}&shopName=${req.shopName}(App)&colorRing=&callMent=`
      )
      .then(result => {
        if (result.data.resCd === 'SUCCESS') {
          this.setState({
            data: {
              ...this.state.data,
              phone050_app: result.data.resObj.vn
            }
          });
          const changeLog_app = {
            storeName: this.state.data.company,
            tel: this.state.data.tel,
            phone050_app: result.data.resObj.vn,
            status:
              (this.state.data.changeLog_app &&
                this.state.data.changeLog_app[this.state.data.changeLog_app.length - 1].status) ||
              '',
            createdAt: new Date()
          };
          this.onSubmit(false, null, changeLog_app);
          alert('번호가 연동 되었습니다. (자동저장됩니다.)');
          this.setState({
            phoneLoading: false
          });
          this.props.history.push(`/main/store/${this.state.data.code}`);
        } else {
          console.log(result);
          alert('알수 없는 에러가 발생했습니다. 관리자에게 문의해주세요.');
        }
      })
      .catch(err => console.log(err));
  };

  // 050번호 해지
  disconnect050 = phone050 => {
    const oldData = this.state.data;
    this.setState({
      data: null
    });
    setTimeout(() => {
      this.setState({
        data: { ...oldData, phone050: '' }
      });
    }, 150);

    const phones = this.props.firebase.db.collection('phones');

    phones
      .where('phone050', '==', formatPhone(phone050))
      .get()
      .then(snapshots => {
        const id = snapshots.docs[0].id;
        phones
          .doc(id)
          .update({
            isAvailable: false,
            storeName: '',
            disconnectedAt: new Date()
          })
          .then(() => {
            const today = new Date();
            today.setTime(today.getTime() + 3600000 * 9);

            const changeLog = {
              storeName: this.state.data.company,
              tel: this.state.data.tel,
              phone050,
              status: '해지',
              createdAt: today
            };
            this.afterStop(phone050, changeLog);
          })
          .catch(err => console.log(err));
      });
  };

  // 앱용 050번호 해지
  disconnect050_app = phone050_app => {
    const oldData = this.state.data;
    this.setState({
      data: null
    });
    setTimeout(() => {
      this.setState({
        data: { ...oldData, phone050_app: '' }
      });
    }, 150);

    const phones = this.props.firebase.db.collection('phones');

    phones
      .where('phone050', '==', formatPhone(phone050_app))
      .get()
      .then(snapshots => {
        const id = snapshots.docs[0].id;
        phones
          .doc(id)
          .update({
            isAvailable: false,
            storeName: '',
            disconnectedAt: new Date()
          })
          .then(() => {
            const today = new Date();

            const changeLog_app = {
              storeName: this.state.data.company,
              tel: this.state.data.tel,
              phone050_app,
              detail: '번호해지',
              status:
                (this.state.data.changeLog_app &&
                  this.state.data.changeLog_app[this.state.data.changeLog_app.length - 1].status) ||
                '',
              createdAt: today
            };
            this.afterStop_app(phone050_app, changeLog_app);
          })
          .catch(err => console.log(err));
      });
  };

  // 050번호 일시정지
  stop050 = () => {
    if (
      this.state.data.changeLog &&
      this.state.data.changeLog[this.state.data.changeLog.length - 1].status === '일시정지'
    ) {
      this.connectDirect('사용중');
      return null;
    }
    axios.defaults.baseURL = 'https://us-central1-phone-fa49a.cloudfunctions.net/api';
    axios
      .get('/connect_direct')
      .then(result => {
        const data = { ...result.data };
        const vn = this.state.data.phone050;

        axios.defaults.baseURL = 'https://m.callmix.co.kr/cmxapi';
        axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
        axios
          .get(`vnPause?id=${data.id}&pw=${data.pw}&vn=${vn}`)
          .then(result => {
            console.log(result);
            if (result.data.resCd === 'SUCCESS') {
              this.setState({
                data: { ...this.state.data, phone050: result.data.resObj.vn }
              });
              const today = new Date();
              today.setTime(today.getTime() + 3600000 * 9);

              const changeLog = {
                storeName: this.state.data.company,
                tel: this.state.data.tel,
                phone050: result.data.resObj.vn,
                status: '일시정지',
                createdAt: today
              };
              this.onSubmit(false, changeLog);
              alert('050번호가 일시정지 되었습니다. (자동저장됩니다.)');
              this.setState({
                phoneLoading: false
              });
              this.props.history.push(`/main/store/${this.state.data.code}`);
            } else {
              alert('알수 없는 에러가 발생했습니다. 관리자에게 문의해주세요.');
            }
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  };

  // 앱용 050번호 일시정지
  stop050_app = () => {
    const { changeLog_app } = this.state.data;
    const vn = this.state.data.phone050_app;

    axios.defaults.baseURL = 'https://m.callmix.co.kr/cmxapi';
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
    axios
      .get(`vnPause?id=${callLinkID}&pw=${callLinkPW}&vn=${vn}`)
      .then(result => {
        if (result.data.resCd === 'SUCCESS') {
          this.setState({
            data: {
              ...this.state.data,
              phone050_app: result.data.resObj.vn
            }
          });
          const newChangeLog_app = {
            storeName: this.state.data.company,
            tel: this.state.data.tel,
            phone050_app: result.data.resObj.vn,
            detail: '번호 일시정지',
            status: (changeLog_app && changeLog_app[changeLog_app.length - 1].status) || '',
            createdAt: new Date()
          };
          this.onSubmit(false, null, newChangeLog_app);
          alert('앱용 050번호가 일시정지 되었습니다. (자동저장됩니다.)');
          this.setState({
            phoneLoading: false
          });
          this.props.history.push(`/main/store/${this.state.data.code}`);
        } else {
          alert('알수 없는 에러가 발생했습니다. 관리자에게 문의해주세요.');
        }
      })
      .catch(err => console.log(err));
  };

  // 해지후 일시 정지용
  afterStop = (vn, changeLog) => {
    axios.defaults.baseURL = 'https://m.callmix.co.kr/cmxapi';
    axios
      .get(`vnPause?id=${callLinkID}&pw=${callLinkPW}&vn=${vn}`)
      .then(() => {
        this.setState({
          phoneLoading: false
        });
        this.onSubmit(true, changeLog);
        alert('번호가 해지되었습니다.');
      })
      .catch(err => console.log(err));
  };

  // 앱용 해지후 일시 정지용
  afterStop_app = (vn, changeLog_app) => {
    axios.defaults.baseURL = 'https://m.callmix.co.kr/cmxapi';
    axios
      .get(`vnPause?id=${callLinkID}&pw=${callLinkPW}&vn=${vn}`)
      .then(() => {
        this.setState({
          phoneLoading: false
        });
        this.onSubmit(true, null, changeLog_app);
        alert('앱용 번호가 해지되었습니다.');
      })
      .catch(err => console.log(err));
  };

  render() {
    const { data, fileLoading } = this.state;

    return (
      <Container>
        <div
          style={{
            fontSize: '18px',
            fontWeight: '600',
            color: '#0c8599',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem',
            paddingBottom: '0.5rem',
            borderBottom: '2px solid #ccc'
          }}
        >
          <Icon name="edit" style={{ marginLeft: '1rem', marginRight: '0.5rem' }} />
          <span>가맹점 수정</span>
        </div>

        {data ? (
          <>
            <div className="flex">
              <InputSet
                name="협력사명"
                id="publisher"
                onChange={this.onInputChange}
                value={data.publisher && data.publisher.company}
                disabled={true}
              />
              <InputSet
                name="가맹점코드"
                id="code"
                onChange={this.onInputChange}
                value={data.code}
                disabled={true}
              />
            </div>
            <div className="flex">
              <InputSet
                name="영업 담당자"
                id="salesman"
                value={data.salesman}
                onChange={this.onInputChange}
              />
            </div>
            <div className="flex">
              <InputSet
                name="050전화번호"
                id="phone050"
                onChange={this.onInputChange}
                value={formatPhone(data.phone050)}
                disabled={true}
              />

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  size="small"
                  style={{ background: '#0c8599', color: 'white' }}
                  onClick={this.connect050}
                >
                  {this.state.phoneLoading ? <Loader active inline size="mini" /> : '번호등록/해지'}
                </Button>
                <Button
                  size="small"
                  style={{ background: '#fa5252', color: 'white' }}
                  onClick={this.stop050}
                >
                  일시정지/재개
                </Button>
              </div>
            </div>
            <div className="flex">
              <InputSet
                name="상태 (책자)"
                id="status"
                value={data.changeLog && data.changeLog[data.changeLog.length - 1].status}
                disabled={true}
              />
            </div>
            <div className="flex">
              <InputSet
                name="콕콕114 전화번호"
                id="phone050_app"
                onChange={this.onInputChange}
                value={formatPhone(data.phone050_app)}
                disabled={true}
              />

              {this.props.user.level === '관리자' && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    size="small"
                    style={{ background: '#0c8599', color: 'white' }}
                    onClick={this.connect050_app}
                  >
                    {this.state.phoneLoading ? (
                      <Loader active inline size="mini" />
                    ) : (
                      '번호등록/해지'
                    )}
                  </Button>
                  <Button
                    size="small"
                    style={{ background: '#fa5252', color: 'white' }}
                    onClick={this.stop050_app}
                  >
                    일시정지
                  </Button>
                  <Button size="small" onClick={this.connectDirect_app}>
                    재개
                  </Button>
                </div>
              )}
            </div>

            <div className="flex">
              <InputSet
                name="일반전화번호"
                id="tel"
                onChange={this.onPhoneInputChange}
                value={data.tel}
                disabled={this.props.user.level !== '관리자' && true}
              />

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button size="small" onClick={this.connectDirect.bind(this, '사용중')}>
                  {this.state.phoneLoading ? <Loader active inline size="mini" /> : '번호매칭'}
                </Button>
                {this.props.user.level === '관리자' && (
                  <Button size="small" onClick={this.connectDirect_app}>
                    {this.state.phoneLoading ? (
                      <Loader active inline size="mini" />
                    ) : (
                      '콕콕114 번호매칭'
                    )}
                  </Button>
                )}
              </div>
            </div>

            <div
              style={{
                marginLeft: '8.5%',
                color: '#fa5252',
                padding: '0.5rem 0'
              }}
            >
              ※ 일반전화번호, 업체명 변경시 반드시 번호 매칭을 눌러 050번호와 연동해주세요.
            </div>
            <p />
            <div className="flex">
              <InputSet
                name="가맹점명"
                id="company"
                onChange={this.onInputChange}
                value={data.company}
              />
            </div>
            <div className="flex" style={{ alignItems: 'center' }}>
              <InputSet
                name="사업자번호"
                id="businessNumber"
                onChange={this.onBnInputChange}
                placeholder="예시: 123-45-67890"
                value={data.businessNumber}
              />
              <div
                style={{
                  marginLeft: '0.5rem',
                  color: '#fa5252'
                }}
              >
                {this.state.error && this.state.error.bn}
              </div>
            </div>

            <div className="flex">
              <InputSetLarge
                name="주소"
                id="address"
                onChange={this.onInputChange}
                value={data.address}
              />
            </div>
            <div className="flex">
              <InputSetLarge
                name="이메일주소"
                id="email"
                onChange={this.onInputChange}
                value={data.email}
              />
            </div>
            <div className="flex">
              <InputSet name="대표자명" id="ceo" onChange={this.onInputChange} value={data.ceo} />
              <InputSet
                name="핸드폰번호"
                id="phone"
                onChange={this.onPhoneInputChange}
                value={data.phone}
              />
            </div>
            <div className="flex">
              <InputSet
                name="콕콕114 상태"
                id="status"
                value={
                  data.changeLog_app && data.changeLog_app[data.changeLog_app.length - 1].status
                }
                disabled={true}
              />
            </div>
            <FileSet data={data.photo} onChange={this.fileUpload} name={'photo'} title={'메뉴판'} />
            <FileSet
              data={data.applicationForm}
              onChange={this.fileUpload}
              name={'applicationForm'}
              title={'신청서'}
            />
            <FileSet
              data={data.businessRegistForm}
              onChange={this.fileUpload}
              name={'businessRegistForm'}
              title={'사업자등록증'}
            />
            <FileSet
              data={data.copiedBankBook}
              onChange={this.fileUpload}
              name={'copiedBankBook'}
              title={'통장사본'}
            />
            <div className="flex" style={{ justifyContent: 'center', marginTop: '1rem' }}>
              <Button
                primary
                style={{
                  minWidth: '120px',
                  width: '40%',
                  background: '#0c8599'
                }}
                onClick={() => {
                  this.onSubmit(true);
                }}
                disabled={fileLoading === 'LOADING'}
              >
                {this.state.isLoading ? (
                  <Loader active inline size="mini" />
                ) : (
                  <>
                    <Icon name="save" />
                    저장
                  </>
                )}
              </Button>
            </div>
          </>
        ) : (
          <div>
            <Loader active />
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(connect(mapStateToProps), withFirebase, withRouter)(StoreEdit);

const FileSet = ({ data, onChange, name, title }) => {
  return (
    <div className="flex" style={{ marginTop: '1rem', alignItems: 'center' }}>
      <div style={{ marginLeft: '4.5%' }}>{title}</div>
      <div style={{ marginLeft: '1rem' }}>
        <input
          type="file"
          name={name}
          onChange={onChange}
          accept="image/gif,image/jpeg,image/png,application/pdf"
        />
      </div>
      <div>
        {data && (
          <Modal trigger={<Button size="small">상세보기</Button>}>
            <Image centered size="big" src={data} />
          </Modal>
        )}
      </div>
    </div>
  );
};

const InputSet = ({ name, onChange, id, placeholder, value, disabled }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '50%',
        padding: '0.5rem 1rem',
        justifyContent: 'flex-end'
      }}
    >
      {' '}
      <div style={{ whiteSpace: 'pre-wrap' }}>{name}</div>
      <Input placeholder={placeholder || name} style={{ width: '75%', marginLeft: '1rem' }}>
        <input name={id} onChange={onChange} defaultValue={value} disabled={disabled} />
      </Input>
    </div>
  );
};

const InputSetLarge = ({ name, onChange, id, value }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '0.5rem 1rem',
        justifyContent: 'flex-end'
      }}
    >
      {' '}
      <div style={{ whiteSpace: 'pre-wrap' }}>{name}</div>
      <Input placeholder={name} style={{ width: '88%', marginLeft: '1rem' }}>
        <input name={id} onChange={onChange} defaultValue={value} />
      </Input>
    </div>
  );
};
