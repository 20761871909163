import React, { Component } from "react";
import styled from "styled-components";
import { Input, Button, Loader, TextArea, Form } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  width: 98%;
  background: white;
  margin-bottom: 2rem;
  padding: 3rem 2rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #dfdfdf;
  .flex {
    display: flex;
    align-items: center;
  }
`;

class EditAsk extends Component {
  state = {
    fileLoading: "WAIT",
    data: null,
    title: null,
    fileURL: null,
    content: null,
    status: null
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id === ":id") {
      this.props.history.push(`/main/ask`);
    } else {
      this.loadData();
    }
  }

  loadData = () => {
    const { id } = this.props.match.params;
    this.props.firebase.db
      .collection("ask")
      .doc(id)
      .get()
      .then(doc => {
        this.setState({
          data: doc.data(),
          status: doc.data().status
        });
      });
  };

  onSubmit = () => {
    const { title, fileURL, content, status } = this.state;

    const newData = {
      title,
      fileURL,
      content,
      status
    };
    if (!title) {
      delete newData.title;
    }
    if (!fileURL) {
      delete newData.fileURL;
    }
    if (!content) {
      delete newData.content;
    }

    const { id } = this.props.match.params;

    this.props.firebase.db
      .collection("ask")
      .doc(id)
      .update(newData)
      .then(() => {
        this.props.history.push(`/main/ask`);
      });
  };

  onChange = (e, { value, name }) => {
    this.setState({
      [name]: value
    });
  };

  onContentChange = (e, { value }) => {
    this.setState({
      content: value.replace(/\n/g, "<br/>")
    });
  };

  fileUpload = e => {
    const storageRef = this.props.firebase.storage.ref();
    let filelist = e.target.files;
    let file = filelist[0];

    // 파일 용량체크
    if (file.size > 10 * 1024 * 1024) {
      alert("첨부 파일의 최대 사이즈는 10MB 입니다.");
      return null;
    }

    let uploadTask = storageRef.child("extra/" + file.name).put(file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        this.setState({
          fileLoading: "LOADING"
        });
      },
      err => {
        this.setState({
          fileLoading: "FAIL"
        });
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(url => {
          this.setState({
            fileURL: url,
            fileLoading: "DONE"
          });
        });
      }
    );
  };
  render() {
    const { user } = this.props;
    const { fileLoading, data } = this.state;
    return (
      <Container>
        <div
          style={{
            borderBottom: "1.5px solid lightgray",
            padding: "1rem 3rem",
            fontSize: "17px",
            marginBottom: "2rem",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div>
            <span>문의사항 수정</span>
          </div>
        </div>
        {data && (
          <div>
            <div className="flex" style={{ marginBottom: "1rem" }}>
              <span style={{ marginRight: "1rem" }}>제목</span>
              <Input
                onChange={this.onChange}
                defaultValue={data.title}
                name="title"
                style={{ minWidth: "75%", marginRight: "1rem" }}
              />
              <span style={{ marginRight: "1rem" }}>작성자</span>
              <span>{user.level}</span>
            </div>

            <Form>
              <div>내용</div>
              <TextArea
                onChange={this.onContentChange}
                name="content"
                defaultValue={data.content.replace(/<br\/>/g, `\r\n`)}
                style={{ margin: "1rem 0", minHeight: "10rem" }}
              />
            </Form>
            <div
              className="flex"
              style={{ marginLeft: "1rem", marginBottom: "2rem" }}
            >
              <div style={{ marginLeft: "1rem" }}>첨부파일</div>
              <div style={{ marginLeft: "1rem" }}>
                <input type="file" name="file" onChange={this.fileUpload} />
                <div>
                  {fileLoading === "LOADING" && (
                    <Loader active inline size="mini" />
                  )}
                  {fileLoading === "DONE" && <>업로드 완료!</>}
                  {fileLoading === "FAIL" && (
                    <>업로드 실패 다시 시도해주세요.</>
                  )}
                </div>
              </div>
            </div>
            <div className="flex" style={{ marginBottom: "1rem" }}>
              <Button
                size="small"
                primary
                onClick={() => {
                  this.onSubmit();
                }}
              >
                수정하기
              </Button>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(EditAsk);
