import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../firebase';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie
} from 'recharts';
import { Loader } from 'semantic-ui-react';

const Container = styled.div`
  display: flex;
  width: 90%;
  margin-top: 30px;
  flex-direction: column;
  .flex {
    display: flex;
    margin-bottom: 20px;
    width: 100%;
  }
  .section {
    background: white;
    border-radius: 2px;
    box-shadow: 3px 1px 8px 2px rgba(0, 0, 0, 0.1);
    padding: 1rem 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    user-select: none;
    flex-direction: column;
  }
  .title {
    width: 100%;
    padding: 0.5rem 1rem 0 0.5rem;
    font-size: 18px;
    font-weight: bold;
  }
`;

const areas = ['서울시', '전라남도', '경기도', '충청북도', '경상남도', '전라북도'];
const cities = [
  { owner: '서울', lists: ['서울시'] },
  { owner: '인천', lists: ['인천시'] },
  { owner: '충청북도', lists: ['청주시', '천안시'] },
  { owner: '경기도', lists: ['광주시', '용인시', '평택시'] },
  { owner: '경상남도', lists: ['창원시', '부산시'] }
];

const findArea = address => {
  const splited = address.split(' ');
  if (splited[0].substr(splited[0].length - 1) === '도') {
    return areas[areas.findIndex(area => area === splited[0])];
    // '경기' 일 경우 경기도로 검색해서 찾음
  } else if (areas.includes(splited[0] + '도')) {
    return splited[0] + '도';
  } else if (splited[0].substr(splited[0].length - 1) === '시') {
    const filterArr = cities.filter(item => item.lists.includes(splited[0]));
    return filterArr.length > 0 ? filterArr[0].owner : '미분류';
  } else if (splited[0] === '경남') {
    return '경상남도';
  } else if (splited[0] === '충북') {
    return '충청북도';
  } else {
    return '미분류';
  }
};

const combineData = data => {
  let newData = [];
  data.forEach(item =>
    newData.push({
      ...item,
      area: item.address ? findArea(item.address) : '주소없음'
    })
  );
  return newData;
};

const StatisticCharts = props => {
  const { db } = props.firebase;
  const [data, setData] = useState(null);
  // const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [countData, setCount] = useState(null);

  useEffect(() => {
    db.collection('stores')
      .get()
      .then(snapshots => {
        let stores = [];
        snapshots.forEach(doc => stores.push(doc.data()));
        setData(combineData(stores));
      })
      .catch(err => console.log(err));

    db.collection('counters')
      .get()
      .then(snapshots => {
        let counters = [];
        snapshots.forEach(doc => counters.push(doc.data()));
        setCount(counters);
      })
      .catch(err => console.log(err));
  }, [db]);

  // const countByArea = area => {
  //   let sum = 0;
  //   data
  //     .filter(item => item.area === area)
  //     .forEach(item =>
  //       countData
  //         .filter(count => count.code === item.code)
  //         .forEach(count => (sum += count.allCount))
  //     );
  //   return sum;
  // };

  const countMonthByArea = (area, month, lastyear) => {
    let sum = 0;
    const year = new Date().getFullYear();
    data
      .filter(item => item.area === area)
      .forEach(item =>
        countData
          .filter(count => count.code === item.code)
          .forEach(
            count =>
              count[`${lastyear ? year - 1 : year}-${month}`] &&
              (sum += count[`${lastyear ? year - 1 : year}-${month}`])
          )
      );
    return sum;
  };

  const combineChartData = () => {
    let newData = [];
    const thisMonth = new Date().getMonth() + 1;
    console.log(thisMonth);
    for (let i = thisMonth - 5; i <= thisMonth; i++) {
      let month = i;
      let lastyear = false;
      if (i <= 0) {
        month = 12 + i;
        lastyear = true;
      }
      newData.push({
        name: month + '월',
        서울: countMonthByArea('서울', month, lastyear),
        인천: countMonthByArea('인천', month, lastyear),
        경기도: countMonthByArea('경기도', month, lastyear),
        충청북도: countMonthByArea('충청북도', month, lastyear),
        경상남도: countMonthByArea('경상남도', month, lastyear),
        주소없음: countMonthByArea('주소없음', month, lastyear)
      });
    }
    console.log(newData);
    return newData;
  };

  // const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <Container>
      <h3 style={{ color: '#0c8599' }}>K배달 지역별 통계</h3>
      {data && countData ? (
        <div className="flex">
          <div className="section" style={{ marginRight: '2%' }}>
            <div className="title">월별 주문 수</div>
            <BarChart
              width={500}
              height={300}
              data={combineChartData()}
              margin={{
                top: 20
              }}
            >
              <CartesianGrid strokeDasharray="6 6" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />

              <Bar dataKey="경기도" stackId="a" fill="#0088FE" />
              <Bar dataKey="충청북도" stackId="a" fill="#00C49F" />
              <Bar dataKey="경상남도" stackId="a" fill="#FFBB28" />
              <Bar dataKey="인천" stackId="a" fill="#b197fc" />
              <Bar dataKey="주소없음" stackId="a" fill="#FF8042" />
            </BarChart>
          </div>
          <div className="section" style={{ width: '49%' }}>
            <div className="title">가맹점 수</div>
            {data && (
              <PieChart width={500} height={300}>
                <Pie
                  dataKey="value"
                  isAnimationActive={true}
                  data={[
                    {
                      name: '경기도',
                      value: data.filter(item => item.area === '경기도').length,
                      fill: '#0088FE'
                    },
                    {
                      name: '충청북도',
                      value: data.filter(item => item.area === '충청북도').length,
                      fill: '#00C49F'
                    },
                    {
                      name: '경상남도',
                      value: data.filter(item => item.area === '경상남도').length,
                      fill: '#FFBB28'
                    },
                    {
                      name: '인천',
                      value: data.filter(item => item.area === '인천').length,
                      fill: '#b197fc'
                    },
                    {
                      name: '주소없음',
                      value: data.filter(item => item.area === '주소없음').length,
                      fill: '#FF8042'
                    },
                    {
                      name: '미분류',
                      value: data.filter(item => item.area === '미분류').length
                    }
                  ]}
                  outerRadius={80}
                  label
                ></Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            )}
          </div>
        </div>
      ) : (
        <Loader active />
      )}
    </Container>

    // <>
    // <hr />
    // <h4>지역별 누적주문수</h4>
    // <div>
    //   <div>경기도: {countByArea("경기도")}건</div>
    //   <div>경상남도: {countByArea("경상남도")}건</div>
    //   <div>주소없음: {countByArea("주소없음")}건</div>
    // </div>
    // <hr />
    // <h4>지역별 월별주문수</h4>
    // <>
    //   <select>
    //     <option>2019</option>
    //   </select>
    //   년
    //   <select
    //     defaultValue={month}
    //     onChange={e => {
    //       setMonth(e.target.value);
    //     }}
    //   >
    //     <option>1</option>
    //     <option>2</option>
    //     <option>3</option>
    //     <option>4</option>
    //     <option>5</option>
    //     <option>6</option>
    //     <option>7</option>
    //     <option>8</option>
    //     <option>9</option>
    //     <option>10</option>
    //     <option>11</option>
    //     <option>12</option>
    //   </select>{" "}
    //   월
    //   <br />
    //   <br />
    //   <div>
    //     <div>경기도: {countMonthByArea("경기도", month)}건</div>
    //     <div>경상남도: {countMonthByArea("경상남도", month)}건</div>
    //     <div>주소없음: {countMonthByArea("주소없음", month)}건</div>
    //   </div>
    // </>
    // <hr />
    // <h4>예시) 전월대비 콜 증가율 (매달 1일 부터 오늘까지)</h4>
    // {
    //   <>
    //     <div>
    //       경기도:{" "}
    //       {(
    //         (parseInt(
    //           countMonthByArea("경기도", new Date().getMonth() + 1)
    //         ) /
    //           parseInt(
    //             countMonthByArea("경기도", new Date().getMonth())
    //           )) *
    //         100
    //       ).toFixed(2)}
    //       %
    //     </div>
    //     <div>
    //       주소없음:{" "}
    //       {(
    //         (parseInt(
    //           countMonthByArea("주소없음", new Date().getMonth() + 1)
    //         ) /
    //           parseInt(
    //             countMonthByArea("주소없음", new Date().getMonth())
    //           )) *
    //         100
    //       ).toFixed(2)}
    //       %
    //     </div>
    //   </>
    // }
  );
};

export default withFirebase(StatisticCharts);
