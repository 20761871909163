import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Table, Icon, Dropdown } from "semantic-ui-react";

const Container = styled.div`
  width: 98%;
  background: white;
  margin-bottom: 2rem;
  min-height: 35rem;
  padding: 3rem 2rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #dfdfdf;
  .flex {
    display: flex;
    align-items: center;
  }
`;
const Row = styled(Table.Row)`
  &:hover {
    background: #e7f5ff;
  }
`;

const OrderStoreId = props => {
  const { id } = props.match.params;
  const { db } = props.firebase;

  const [year, setYear] = useState(new Date().getFullYear());
  const [counter, setCounter] = useState(null);
  const [name, setName] = useState("");

  useEffect(() => {
    if (id === ":id") {
      props.history.push("/main/orderpub");
    }
    db.collection("users")
      .doc(id)
      .get()
      .then(doc =>
        setName({ name: doc.data().company, level: doc.data().level })
      )
      .catch(err => console.error(err));

    db.collection("counters")
      .where("id", "==", id)
      .get()
      .then(snapshots => {
        let data = [];
        snapshots.forEach(doc => data.push(doc.data()));
        setCounter(data);
      });
  }, [db, id, year]);

  const counterData = keyword => {
    let result = 0;
    if (counter) {
      counter.forEach(count => count[keyword] && (result += count[keyword]));
    }
    return result;
  };

  let dropDownOptions = [];
  const thisYear = new Date().getFullYear();
  for (let i = 0; i <= thisYear - 2019; i++) {
    dropDownOptions.push({ key: i, text: 2019 + i, value: 2019 + i });
  }

  return (
    <Container>
      <div
        style={{
          borderBottom: "1.5px solid lightgray",
          padding: "1rem 3rem",
          fontSize: "17px",
          marginBottom: "2rem",
          fontWeight: "600",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Icon name="call" style={{ marginRight: "1rem" }} />
        <span>협력사 통계 상세</span>
      </div>
      <div>
        <b>분류</b> {name.level}
      </div>
      <div>
        <b>협력사명</b> {name.name}({id})
      </div>
      <div style={{ width: "100%" }}>
        <div className="flex" style={{ justifyContent: "center" }} />
        <div
          className="flex"
          style={{ justifyContent: "center", marginTop: "1rem" }}
        >
          <div>선택 연도 </div>
          <Dropdown
            onChange={(e, { value }) => {
              setYear(value);
            }}
            defaultValue={year}
            options={dropDownOptions}
            placeholder="연도를 선택하세요"
            selection
            style={{ width: "50px", margin: "0 0.5rem" }}
            name="year"
          />
        </div>
      </div>
      <Table celled style={{ textAlign: "center" }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>1</Table.HeaderCell>
            <Table.HeaderCell>2</Table.HeaderCell>
            <Table.HeaderCell>3</Table.HeaderCell>
            <Table.HeaderCell>4</Table.HeaderCell>
            <Table.HeaderCell>5</Table.HeaderCell>
            <Table.HeaderCell>6</Table.HeaderCell>
            <Table.HeaderCell>7</Table.HeaderCell>
            <Table.HeaderCell>8</Table.HeaderCell>
            <Table.HeaderCell>9</Table.HeaderCell>
            <Table.HeaderCell>10</Table.HeaderCell>
            <Table.HeaderCell>11</Table.HeaderCell>
            <Table.HeaderCell>12</Table.HeaderCell>
            <Table.HeaderCell>합계</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {counter && (
            <Row style={{ fontSize: "12px" }}>
              <Table.Cell>{counterData(`${year}-1`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-2`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-3`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-4`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-5`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-6`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-7`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-8`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-9`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-10`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-11`)}</Table.Cell>
              <Table.Cell>{counterData(`${year}-12`)}</Table.Cell>
              <Table.Cell>{counterData("allCount")}</Table.Cell>
            </Row>
          )}
        </Table.Body>
      </Table>
    </Container>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(OrderStoreId);
