import React, { Component } from "react";
import styled from "styled-components";
import { Input, Button, Icon, Select, Loader } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  width: 90%;
  background: white;
  margin-top: 2rem;
  padding: 3rem 2rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #dfdfdf;
  .flex {
    display: flex;
  }
`;

class PublishAdd extends Component {
  state = {
    isLoading: false,
    level: "본사"
  };
  componentDidMount() {
    this.setState({
      user: this.props.user
    });
    const { level } = this.props.user;
    if (level === "본사") {
      this.setState({
        level: "총판"
      });
    } else if (level === "총판") {
      this.setState({
        level: "대리점"
      });
    }
  }

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSelectChange = e => {
    this.setState({
      level: e.target.innerText
    });
  };

  onSubmit = () => {
    const {
      businessNumber,
      id,
      pw,
      level,
      company,
      ceo,
      phone,
      address,
      email,
      account,
      bank,
      region
    } = this.state;
    if (!id) {
      alert("아이디를 입력해주세요.");
      return null;
    } else if (!pw) {
      alert("비밀번호를 입력해주세요.");
      return null;
    } else if (businessNumber !== "" || !businessNumber) {
      const result = businessNumber.split("-");
      if (
        result.length !== 3 ||
        result[0].length !== 3 ||
        result[1].length !== 2 ||
        result[2].length !== 5
      ) {
        alert("사업자번호를 형식에 맞게 써주세요.");
        return null;
      } else {
        this.setState({
          isLoading: true
        });
        let headquarter = "";
        let publisher = "";

        if (level === "대리점") {
          publisher = {
            company: this.state.user.company,
            id: this.state.user.id
          };
          headquarter = this.state.user.headquarter;
        } else if (level === "총판") {
          headquarter = {
            company: this.state.user.company,
            id: this.state.user.id
          };
        }

        const newData = {
          businessNumber,
          id,
          pw,
          level,
          company,
          ceo,
          phone,
          address,
          email,
          account,
          bank,
          headquarter,
          publisher,
          status: true,
          region
        };
        axios.defaults.baseURL =
          "https://us-central1-phone-fa49a.cloudfunctions.net/api";
        axios.post("/join", newData).then(result => {
          // console.log(result);
          this.setState({
            isLoading: false
          });
          if (result.data.success) {
            this.props.history.push("/main/listpublish");
          } else {
            alert(result.data.error);
          }
        });
      }
    }
  };

  render() {
    const { user } = this.state;
    return (
      <Container>
        <div
          style={{
            borderBottom: "1.5px solid lightgray",
            padding: "1rem 3rem",
            fontSize: "17px",
            marginBottom: "2rem",
            fontWeight: "600"
          }}
        >
          <Icon name="add user" style={{ marginRight: "1rem" }} />
          협력사 등록
        </div>
        <div className="flex">
          <InputSet name="아이디" id="id" onChange={this.onInputChange} />
          <InputSet name="비밀번호" id="pw" onChange={this.onInputChange} />
        </div>
        <div className="flex">
          {user && user.level === "관리자" && (
            <AdminSelectSet
              name="분류"
              id="level"
              onChange={this.onSelectChange}
            />
          )}
          {user && user.level === "본사" && (
            <SelectSet name="분류" id="level" onChange={this.onSelectChange} />
          )}
          {user && user.level !== "본사" && user.level !== "관리자" && (
            <SelectSet
              name="분류"
              id="level"
              onChange={this.onSelectChange}
              disabled={true}
            />
          )}
          <InputSet name="지역" id="region" onChange={this.onInputChange} />
        </div>
        <div className="flex">
          <InputSet name="회사명" id="company" onChange={this.onInputChange} />
          <InputSet name="대표자명" id="ceo" onChange={this.onInputChange} />
        </div>
        <div className="flex">
          <InputSet name="전화번호" id="phone" onChange={this.onInputChange} />
          <InputSet
            name="사업자번호"
            id="businessNumber"
            onChange={this.onInputChange}
            placeholder="예시: 123-45-67890"
          />
        </div>
        <div className="flex">
          <InputSetLarge
            name="주소"
            id="address"
            onChange={this.onInputChange}
          />
        </div>
        <div className="flex">
          <InputSetLarge
            name="이메일주소"
            id="email"
            onChange={this.onInputChange}
          />
        </div>
        <div className="flex">
          <InputSet name="은행명" id="bank" onChange={this.onInputChange} />
          <InputSet
            name="계좌번호"
            id="account"
            onChange={this.onInputChange}
          />
        </div>
        <div
          className="flex"
          style={{ justifyContent: "center", marginTop: "1rem" }}
        >
          <Button
            primary
            style={{ minWidth: "120px", width: "40%" }}
            onClick={() => {
              this.onSubmit();
            }}
          >
            {this.state.isLoading ? (
              <Loader active inline size="mini" />
            ) : (
              "등록"
            )}
          </Button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(PublishAdd);

const InputSet = ({ name, onChange, id, placeholder }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "50%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      {" "}
      <div>{name}</div>
      <Input
        placeholder={placeholder || name}
        style={{ width: "75%", marginLeft: "1rem" }}
      >
        <input name={id} onChange={onChange} />
      </Input>
    </div>
  );
};

const InputSetLarge = ({ name, onChange, id }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      {" "}
      <div>{name}</div>
      <Input placeholder={name} style={{ width: "88%", marginLeft: "1rem" }}>
        <input name={id} onChange={onChange} />
      </Input>
    </div>
  );
};

const SelectSet = ({ name, onChange, id, disabled }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "50%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      <div>{name}</div>
      {disabled ? (
        <Select
          onChange={onChange}
          name={id}
          style={{ width: "75%", marginLeft: "1rem" }}
          defaultValue="대리점"
          disabled
          options={[{ key: "2", text: "대리점", value: "대리점" }]}
        />
      ) : (
        <Select
          onChange={onChange}
          name={id}
          style={{ width: "75%", marginLeft: "1rem" }}
          defaultValue="총판"
          options={[{ key: "1", text: "총판", value: "총판" }]}
        />
      )}
    </div>
  );
};

const AdminSelectSet = ({ name, onChange, id }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "50%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      <div>{name}</div>
      <Select
        onChange={onChange}
        name={id}
        style={{ width: "75%", marginLeft: "1rem" }}
        defaultValue="본사"
        options={[
          { key: "1", text: "본사", value: "본사" },
          { key: "2", text: "총판", value: "총판" },
          { key: "3", text: "대리점", value: "대리점" }
        ]}
      />
    </div>
  );
};
