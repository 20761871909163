import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Icon, Loader } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatPhone } from "../../common/format";
import Table from "../../components/Table/Table";
import DatePicker from "react-datepicker";
import { FirebaseContext } from "../../firebase";
import Helmet from "react-helmet";
import { ko } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";

const Container = styled.div`
  width: 98%;
  margin-bottom: 2rem;
  padding: 3rem 2rem;
  .flex {
    display: flex;
    align-items: center;
  }
  .header {
    font-weight: bold;
  }
`;

const OrderPublish = () => {
  const { db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);
  const date = new Date();
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
  );
  const [endDate, setEndDate] = useState(date);

  const loadUserData = () => {
    return db
      .collection("users")
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc => result.push(doc.data()));
        return result;
      });
  };

  const loadStatisticData = () => {
    const noonEndDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate() + 1
    );
    return db
      .collection("statistic")
      .where("date", ">=", startDate)
      .where("date", "<=", noonEndDate)
      .orderBy("date", "desc")
      .limit(3000)
      .get()
      .then(snapshots => {
        let result = [];
        snapshots.forEach(doc =>
          result.push({
            ...doc.data(),
            id: doc.id,
            publisherName: doc.data().publisher.company
          })
        );
        return result;
      });
  };

  const loadData = async () => {
    const userData = await loadUserData();
    const statisticData = await loadStatisticData();
    const result = userData.map(user => {
      let data = statisticData.filter(
        statistic => statistic.publisher.id === user.id
      );
      return { ...user, statistic: data.length };
    });
    setData(result);
  };

  useEffect(() => {
    if (user.level !== "관리자") return alert("관리자만 접속 가능합니다.");
    const limitDay = endDate - startDate;
    if (limitDay < 0) {
      return alert("마지막 기간이 시작기간 보다 빠릅니다.");
    }
    if (Math.floor(limitDay / 86400000) > 30) {
      return alert("최대 검색 가능 일수는 30일 입니다.");
    }
    setData(null);
    loadData();
  }, [startDate, endDate]);

  return (
    <Container>
      <Helmet>
        <title>기간별 통계 - 어드민 - K배달 관리자시스템</title>
      </Helmet>
      <div
        style={{
          padding: "1rem 3rem",
          fontSize: "17px",
          marginBottom: "2rem",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "#0c8599"
        }}
      >
        <div>
          <Icon name="call" style={{ marginRight: "1rem" }} />
          <span>기간별 통계</span>
        </div>
      </div>
      <div
        style={{
          padding: "1rem 1rem",
          color: "red",
          fontSize: "12px"
        }}
      >
        * 3000건(콜수) 이상은 집계되지 않습니다. 월별 조회는 주문관리의 협력사
        통계에서 확인바랍니다.
      </div>
      <div
        style={{
          color: "gray",
          display: "flex",
          background: "white",
          padding: "0.5rem 1rem",
          borderRadius: "4px",
          justifyContent: "center",
          marginBottom: "0.5rem"
        }}
      >
        <span style={{ marginRight: "1rem" }}>검색일</span>
        <DatePicker
          dateFormat="yyyy. MM. dd"
          selected={startDate}
          locale={ko}
          onChange={value => {
            console.log(value);
            setStartDate(value);
          }}
          customInput={<CustomDateButton />}
        />
        <div style={{ fontSize: "18px", margin: "0 0.5rem" }}>~</div>
        <DatePicker
          dateFormat="yyyy. MM. dd"
          selected={endDate}
          locale={ko}
          onChange={value => {
            setEndDate(value);
          }}
          customInput={<CustomDateButton />}
        />
      </div>
      {data ? (
        <Table
          data={data}
          perPage={20}
          index
          headers={[
            {
              title: "구분",
              value: "level"
            },
            {
              title: "본사",
              value: "headquarter",
              render: item => item.headquarter && item.headquarter.company
            },
            {
              title: "총판",
              value: "publisher",
              render: item => item.publisher && item.publisher.company
            },
            {
              title: "협력사명",
              value: "company",
              render: item => (
                <Link to={`/main/orderstore/${item.id}`}>{item.company}</Link>
              )
            },
            {
              title: "대표자",
              value: "ceo",
              width: 100
            },
            {
              title: "전화번호",
              value: "phone",
              render: row => <span>{formatPhone(row.phone)}</span>
            },
            {
              title: "해당기간 콜수",
              value: "statistic"
            }
          ]}
        />
      ) : (
        <Loader active />
      )}
    </Container>
  );
};

export default OrderPublish;

const CustomDateButton = ({ value, onClick }) => {
  return (
    <Button
      size="small"
      style={{
        color: "white",
        background: "#0c8599",
        padding: "0.2rem 0.5rem"
      }}
      onClick={() => {
        onClick();
      }}
    >
      {value}
    </Button>
  );
};
