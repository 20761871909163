import React, { Component } from "react";
import styled from "styled-components";
import { Button, Icon } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";

const Container = styled.div`
  width: 90%;
  margin-bottom: 2rem;
  padding: 3rem 2rem;
  max-width: 1000px;
  .flex {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .head {
    background: #0c8599;
    padding: 1rem 1rem;
    font-weight: bold;
    color: white;
  }
  .body {
    padding: 1rem 1rem;
  }
`;

const Comment = styled.div`
  border-top: 0.5px solid #efefef;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  &:hover {
    background: #efefef;
  }
`;

class AskDetail extends Component {
  state = {
    data: null
  };

  componentDidMount() {
    if (this.props.match.params.id === ":id") {
      this.props.history.push("/main/ask");
    } else {
      this.loadData();
    }
  }

  loadData = () => {
    const { user } = this.props;
    const { id } = this.props.match.params;
    this.props.firebase.db
      .collection("ask")
      .doc(id)
      .get()
      .then(doc => {
        let data = { ...doc.data(), id: doc.id };
        if (user.level === "대리점" || user.level === "총판") {
          if (data.userId !== user.id) {
            alert("작성자만 조회 가능합니다.");
            return this.props.history.push("/main/ask");
          }
        }
        this.setState({
          data: data
        });
      });
  };

  addComment = current => {
    const { id } = this.props.match.params;
    const { user } = this.props;
    this.props.firebase.db
      .collection("ask")
      .doc(id)
      .update({
        comments: current.concat({
          user: {
            id: user.id,
            level: user.level,
            company: user.company,
            date: new Date()
          },
          comment: this.state.comment
        })
      })
      .then(() => {
        const ref = document.getElementById("textarea_comment");
        ref.value = "";
        this.setState({
          data: {
            ...this.state.data,
            comments: current.concat({
              user: {
                id: user.id,
                level: user.level,
                company: user.company,
                date: new Date()
              },
              comment: this.state.comment
            })
          }
        });
      });
  };

  deleteComment = (current, select) => {
    const filtered = current.filter((item, index) => index !== select);

    const { id } = this.props.match.params;

    this.props.firebase.db
      .collection("ask")
      .doc(id)
      .update({
        comments: filtered
      })
      .then(() => {
        this.setState({
          data: {
            ...this.state.data,
            comments: filtered
          }
        });
      });
  };

  render() {
    const { data } = this.state;
    const { user } = this.props;

    return (
      <Container>
        {data && (
          <>
            <div
              style={{
                padding: "1rem 3rem",
                marginBottom: "2rem",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "#0c8599",
                fontSize: "16px"
              }}
            >
              <div>
                <Icon name="question circle" style={{ marginRight: "1rem" }} />
                <span>문의사항</span>
              </div>
            </div>
            <div>
              <div
                className="flex"
                style={{
                  marginBottom: "1rem",
                  width: "100%",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  background: "white"
                }}
              >
                <span className="head" style={{ marginRight: "1rem" }}>
                  제목
                </span>
                <span className="body" style={{ width: "50%" }}>
                  {data.title}
                </span>
                <span className="head" style={{ marginRight: "1rem" }}>
                  작성자
                </span>
                <span className="body" style={{ marginRight: "1rem" }}>
                  {data.user}
                </span>
                <span className="head" style={{ marginRight: "1rem" }}>
                  작성일
                </span>
                <span className="body">
                  {data.date && data.date.toDate().toLocaleDateString()}
                </span>
              </div>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "100%",
                  padding: "1rem 1rem",
                  minHeight: "15rem",
                  background: "white"
                }}
              >
                {this.state.data.content.split("<br/>").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </div>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "100%",
                  marginBottom: "2rem",
                  marginTop: "1rem",
                  background: "white"
                }}
                className="flex"
              >
                <div className="head">첨부파일</div>
                <div className="body">
                  {data.fileURL ? <a href={data.fileURL}>다운로드</a> : "없음"}
                </div>
              </div>
              <div
                className="flex"
                style={{ marginBottom: "1rem", justifyContent: "center" }}
              >
                <Link to={`/main/ask`}>
                  <Button size="small">
                    <Icon name="th list" />
                    목록으로
                  </Button>
                </Link>
                {data.userId === user.id && (
                  <Link to={`/main/editask/${data.id}`}>
                    <Button size="small">수정하기</Button>
                  </Link>
                )}
              </div>
            </div>
            <div className="flex">
              <div style={{ margin: "2rem 2rem" }}>
                댓글 [{(data.comments && data.comments.length) || 0}개]{" "}
              </div>
            </div>
            <div className="flex" style={{ marginBottom: "1rem" }}>
              <div
                style={{
                  width: "20%",
                  textAlign: "right",
                  marginRight: "1.5rem"
                }}
              >
                <span
                  style={{
                    background: "gray",
                    padding: "0.3rem 0.7rem",
                    borderRadius: "3px",
                    fontWeight: 600,
                    color: "white"
                  }}
                >
                  {this.props.user.level} | {this.props.user.company}
                </span>
              </div>
              <textarea
                onChange={e => {
                  this.setState({
                    comment: e.target.value.replace(/\n/g, "<br/>")
                  });
                }}
                id="textarea_comment"
                placeholder="댓글을 입력하세요..."
                style={{
                  outline: "none",
                  padding: "1rem 1rem",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "65%",
                  height: "5rem",
                  resize: "none"
                }}
              />
              <div
                style={{
                  textAlign: "center",
                  width: "10%"
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    borderRadius: "5px",
                    background: "#1c7ed6",
                    color: "white",
                    fontWeight: 600,
                    padding: "1.5rem 1.5rem",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    this.addComment(data.comments ? data.comments : []);
                  }}
                >
                  작성
                </span>
              </div>
            </div>
            {data.comments &&
              data.comments.map((item, index) => (
                <Comment key={`comment-${index}`}>
                  <div
                    style={{
                      width: "20%",
                      textAlign: "right",
                      marginRight: "1.5rem"
                    }}
                  >
                    <span
                      style={{
                        background:
                          item.user.level === "관리자" ||
                          item.user.level === "본사"
                            ? "#fa5252"
                            : "gray",
                        padding: "0.3rem 0.7rem",
                        borderRadius: "3px",
                        fontWeight: 600,
                        color: "white"
                      }}
                    >
                      {item.user.level} | {item.user.company}
                    </span>
                    {item.user.date && item.user.date.nanoseconds ? (
                      <div
                        style={{
                          color: "gray",
                          fontSize: "11px",
                          marginTop: "0.3rem"
                        }}
                      >
                        {item.user.date.toDate().toLocaleDateString()} &nbsp;
                        {item.user.date.toDate().toLocaleTimeString()}
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "gray",
                          fontSize: "11px",
                          marginTop: "0.3rem"
                        }}
                      >
                        {item.user.date.toLocaleDateString()} &nbsp;
                        {item.user.date.toLocaleTimeString()}
                      </div>
                    )}
                  </div>
                  <div style={{ width: "70%" }}>
                    {item.comment.split("<br/>").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </div>
                  <div>
                    {this.props.user.id === item.user.id && (
                      <Icon
                        name="close"
                        style={{ color: "lightgray", cursor: "pointer" }}
                        onClick={() => {
                          this.deleteComment(
                            data.comments ? data.comments : [],
                            index
                          );
                        }}
                      />
                    )}
                  </div>
                </Comment>
              ))}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(AskDetail);
