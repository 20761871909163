import React, { Component } from "react";
import styled from "styled-components";
import { withFirebase } from "../../firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "../../components/Table/Table";

const Section = styled.div`
  margin-top: 2rem;
  padding: 1rem 0.5rem;
  .item {
    margin-top: 1rem;
  }
  width: 90%;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

class VnAdmin extends Component {
  state = {
    disconnectedData: null
  };
  db = this.props.firebase.db;

  componentDidMount() {
    this.db
      .collection("phones")
      .where("disconnectedAt", ">=", new Date(2019, 5))
      .get()
      .then(snapshots => {
        let data = [];
        snapshots.forEach(doc => data.push({ ...doc.data(), id: doc.id }));
        this.setState({
          disconnectedData: data.reverse()
        });
        // console.log(data);
      });
  }

  render() {
    return (
      <Container>
        <Section>
          <h4>050번호 해지 목록</h4>
          <div>해지 이력이 있는 번호만 불러옵니다.</div>
          <div>현재사용중인 050번호는 사용가능 전환이 불가합니다.</div>
          <div>
            {this.state.disconnectedData && (
              <Table
                data={this.state.disconnectedData}
                perPage={20}
                index
                headers={[
                  {
                    title: "생성일",
                    value: "createdAt",
                    minWidth: 100
                  },
                  {
                    title: "050번호",
                    value: "phone050",
                    minWidth: 100
                  },
                  {
                    title: "해제일",
                    value: "disconnectedAt",
                    minWidth: 120,
                    render: item => (
                      <span>
                        {item.disconnectedAt.toDate().toLocaleDateString()}
                      </span>
                    )
                  },
                  {
                    title: "사용가능여부",
                    value: "isAvailable",
                    render: item => (
                      <span
                        style={{ color: item.isAvailable ? "green" : "red" }}
                      >
                        {item.isAvailable ? "사용가능" : "사용불가"}
                      </span>
                    )
                  },
                  {
                    title: "사용전환하기",
                    value: "id",
                    render: item => (
                      <button
                        onClick={() => {
                          const { isAvailable, storeName, id } = item;
                          if (storeName) {
                            alert(
                              `이미 사용중인 번호입니다. (가맹점명: ${storeName})`
                            );
                            return null;
                          } else {
                            this.db
                              .collection("phones")
                              .doc(id)
                              .update({
                                isAvailable: !isAvailable
                              })
                              .then(() => {
                                isAvailable
                                  ? alert("사용불가 처리되었습니다.")
                                  : alert("사용가능 처리되었습니다.");
                              });
                          }
                        }}
                      >
                        전환
                      </button>
                    )
                  }
                ]}
              />
            )}
          </div>
        </Section>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(VnAdmin);
