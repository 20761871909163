import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../../firebase';
import csv2json from 'csvjson-csv2json';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import xlsx from 'xlsx';
//import axios from "axios";

const Section = styled.div`
  padding: 1rem 0.5rem;
  border-top: 1px solid #ccc;
  .item {
    margin-top: 1rem;
  }
  width: 90%;
`;
const Container = styled.div`
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

class Admin extends Component {
  state = { result: '', year: new Date().getFullYear() };

  fileUpload = e => {
    const file = e.target.files[0];

    const readFileContent = file => {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = e => resolve(e.target.result);
        reader.onerror = err => reject(err);
        reader.readAsText(file);
      });
    };
    let data = [];
    readFileContent(file).then(csv => {
      csv2json(csv).map(item =>
        data.push({
          phone050: item['050번호'],
          storeName: item['사용처'],
          createdAt: item['발급일자'],
          isAvailable: item['사용처'] ? false : true
        })
      );
      const db = this.props.firebase.db;
      const batch = db.batch();
      data.map(item => {
        const ref = db.collection('phones').doc();
        batch.set(ref, item);
        return null;
      });
      batch
        .commit()
        .then(() =>
          this.setState({
            result: '업로드에 성공하였습니다.'
          })
        )
        .catch(err => {
          console.log(err);
          this.setState({
            result: '실패하였습니다.',
            log: err
          });
        });
    });
  };

  testFn = () => {
    let callList = [];
    this.props.firebase.db
      .collection('statistic')
      .get()
      .then(snapshots => {
        snapshots.forEach(doc => {
          callList.push(doc.data());
        });
        console.log(callList);
        this.setState({
          callList
        });
      });
  };

  render() {
    return (
      <Container>
        {this.props.user.level !== '관리자' ? (
          <div>관리자만 접속할수 있습니다.</div>
        ) : (
          <>
            <Section style={{ border: 'none', color: '#0c8599' }}>
              <h3>K배달 관리자시스템 어드민</h3>
            </Section>

            <Section style={{ display: 'flex' }}>
              <div style={{ textAlign: 'center', marginRight: '2rem' }}>
                <h4>통계</h4>
                <Link to="/main/admin_static_reg">지역별 통계</Link>
                <br />
                <Link to="/main/admin_static_pub">기간별 통계</Link>
              </div>
              <div style={{ textAlign: 'center', marginRight: '2rem' }}>
                <h4>050번호 관리</h4>
                <Link to="/main/admin_vn">해지번호 목록</Link>
              </div>
            </Section>

            <Section style={{ marginTop: '5rem' }}>
              <div style={{ color: 'red', fontSize: '12px' }}>
                * 여기 아래부턴 테스트용 입니다. 사용하지 마세요. 필요시 사용방법을 문의해주세요.
              </div>
              <h4>통계, 카운팅 매칭 테스트</h4>
              <div>
                <select
                  defaultValue={this.state.year}
                  onChange={e => {
                    this.setState({
                      year: e.target.value
                    });
                  }}
                >
                  <option value={2019}>2019</option>
                  <option value={2020}>2020</option>
                </select>
                <input
                  placeholder="해당 월 만 입력"
                  onChange={e => {
                    this.setState({
                      month: e.target.value
                    });
                  }}
                />
                <button
                  onClick={() => {
                    const date = new Date(this.state.year, this.state.month - 1);
                    const next = new Date(this.state.year, this.state.month);
                    console.log(date);
                    this.props.firebase.db
                      .collection('statistic')
                      .where('date', '>=', date)
                      .where('date', '<=', next)
                      .get()
                      .then(snapshots => {
                        let statisticData = [];
                        snapshots.forEach(doc => statisticData.push(doc.data()));
                        console.log(statisticData);
                        this.setState({
                          statistic: statisticData
                        });
                      });

                    this.props.firebase.db
                      .collection('counters')
                      .get()
                      .then(snapshots => {
                        let counterData = [];
                        snapshots.forEach(doc =>
                          counterData.push({ ...doc.data(), docId: doc.id })
                        );
                        console.log(counterData);
                        this.setState({
                          counter: counterData
                        });
                      });
                  }}
                >
                  불러오기
                </button>
                <div>결과: </div>
                <div style={{ background: 'white' }}>
                  {this.state.counter &&
                    this.state.month &&
                    this.state.counter.map(count => {
                      const data = count[`${this.state.year}-${this.state.month}`];
                      const length = this.state.statistic.filter(item => item.code === count.code)
                        .length;

                      if (data) {
                        return (
                          <>
                            <div>
                              {count.code}: {data}건 <span>| 통계: {length}건</span>
                            </div>
                            {data === length ? (
                              <span>일치</span>
                            ) : (
                              <span>
                                <span style={{ color: 'red' }}>불일치</span>
                                <button
                                  onClick={() => {
                                    this.props.firebase.db
                                      .collection('counters')
                                      .doc(count.docId)
                                      .update({
                                        [`${this.state.year}-${this.state.month}`]: length,
                                        allCount: count.allCount + (length - data)
                                      })
                                      .then(() => {
                                        console.log('success');
                                      });
                                  }}
                                >
                                  매칭
                                </button>
                              </span>
                            )}
                          </>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              </div>
            </Section>

            <Section>
              <h4>050 번호 목록 올리기</h4>
              <div>
                콜믹스 관리자 페이지 - 050번호관리 - CSV다운 하여 .csv 파일로 업로드 해주세요.
                한번에 500개 까지 가능합니다. 파일을 500개씩 나눠서 편집한다음 업로드 하세요.
              </div>
              <div className="item">
                <input type="file" name="file" onChange={this.fileUpload} />
              </div>
              <div className="item" style={{ color: 'green' }}>
                {this.state.result && this.state.result}
              </div>
              {this.state.log && <div> 로그: {this.state.log}</div>}
            </Section>
            {/* <Section>
              <button
                onClick={() => {
                  axios
                    .get(
                      "http://localhost:5000/phone-fa49a/us-central1/api/statistic/today"
                    )
                    .then()
                    .catch(err => console.log(err));
                }}
              >
                당일 통계 불러오기
              </button>
            </Section> */}
            {/* <Section>
              <input
                placeholder="yyyyMMdd"
                onChange={e => {
                  this.setState({
                    date: e.target.value
                  });
                }}
              />
              <button
                onClick={() => {
                  axios
                    .post(
                      "http://localhost:5000/phone-fa49a/us-central1/api/statistic/specific",
                      { date: this.state.date }
                    )
                    .then()
                    .catch(err => console.log(err));
                }}
              >
                통계 불러오기 테스트
              </button>
            </Section>
            */}
            {/* <Section>
              <h3>카운터 전환용</h3>
              <button
                onClick={() => {
                  this.props.firebase.db
                    .collection("counters")
                    .get()
                    .then(snapshots => {
                      let data = [];
                      snapshots.forEach(doc => data.push(doc.data()));
                      console.log(data);
                      jsonToSheet(data, "counter");
                    });
                }}
              >
                파일내려받기
              </button>
              <h5>업로드</h5>
              <input
                type="file"
                name="file"
                onChange={e => {
                  const file = e.target.files[0];

                  const readFileContent = file => {
                    const reader = new FileReader();
                    return new Promise((resolve, reject) => {
                      reader.onload = e => resolve(e.target.result);
                      reader.onerror = err => reject(err);
                      reader.readAsText(file);
                    });
                  };
                  let data = [];
                  readFileContent(file).then(csv => {
                    csv2json(csv).map(item =>
                      data.push({
                        "2019-5": parseInt(item[`2019-5`] ? item[`2019-5`] : 0),
                        "2019-6": parseInt(item[`2019-6`] ? item[`2019-6`] : 0),
                        "2019-7": parseInt(item[`2019-7`] ? item[`2019-7`] : 0),
                        "2019-8": parseInt(item[`2019-8`] ? item[`2019-8`] : 0),
                        "2019-9": parseInt(item[`2019-9`] ? item[`2019-9`] : 0),
                        "2019-10": parseInt(
                          item[`2019-10`] ? item[`2019-10`] : 0
                        ),
                        allCount: parseInt(item[`allCount`]),
                        code: item["code"],
                        id: item["id"]
                      })
                    );
                    const db = this.props.firebase.db;
                    const batch = db.batch();
                    data.map(item => {
                      const ref = db.collection("counters").doc(item.code);
                      batch.set(ref, item);
                      return null;
                    });
                    batch
                      .commit()
                      .then(() =>
                        this.setState({
                          result: "업로드에 성공하였습니다."
                        })
                      )
                      .catch(err => {
                        console.log(err);
                        this.setState({
                          result: "실패하였습니다.",
                          log: err
                        });
                      });
                  });
                }}
              />
            </Section> */}
            {/* <Section>
              <button
                onClick={() => {
                  this.props.firebase.db
                    .collection("counters")
                    .doc("QPLBW")
                    .get()
                    .then(doc => {
                      console.log(doc);
                    });
                }}
              >
                파일내려받기
              </button>
            </Section> */}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(connect(mapStateToProps), withFirebase, withRouter)(Admin);

export const jsonToSheet = (arr, filename) => {
  const fileName = `${filename}.xlsx`;

  const ws = xlsx.utils.json_to_sheet(arr);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'test');

  xlsx.writeFile(wb, fileName);
};
