import React, { useEffect, useState, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Table from '../../components/Table/Table';
import { Icon, Loader, Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../firebase';
import { formatPhone, formatMiddleStar } from '../../common/format';
import { jsonToSheet } from '../Admin/Admin';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import _ from 'lodash';

const Container = styled.div`
  width: 96%;
  padding: 1.5rem 2%;
  .flex {
    display: flex;
    align-items: center;
  }
`;

// 총판용
const OrderMonth_3 = props => {
  const { db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);
  const date = new Date();
  const [data, setData] = useState(null);
  const [ownIds, setOwnIds] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
  );
  const [endDate, setEndDate] = useState(date);
  const { history } = props;
  const [isReveal, setReveal] = useState(false);

  useEffect(() => {
    const limitDay = endDate - startDate;
    if (limitDay < 0) {
      return alert('마지막 기간이 시작기간 보다 빠릅니다.');
    }
    if (Math.floor(limitDay / 86400000) > 30) {
      return alert('최대 검색 가능 일수는 30일 입니다.');
    }

    setData(null);
    // 자정까지
    const noonEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);

    const loadStatistic = () => {
      return db
        .collection('statistic')
        .where('date', '>=', startDate)
        .where('date', '<=', noonEndDate)
        .orderBy('date', 'desc')
        .limit(10000)
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc =>
            result.push({
              ...doc.data(),
              id: doc.id,
              publisherName: doc.data().publisher.company
            })
          );
          return result;
        });
    };

    const getIds = () => {
      return db
        .collection('users')
        .get()
        .then(snapshots => {
          let userData = [];
          snapshots.forEach(doc => userData.push({ ...doc.data(), pw: '' }));
          const ids = userData
            .filter(item => item.level === '대리점')
            .filter(item => item.publisher.id === user.id)
            .map(item => item.id);
          setOwnIds(ids);
          return ids;
        });
    };

    const loadData = async () => {
      const statisticData = await loadStatistic();
      const ids = ownIds || (await getIds());
      let resultArray = [];

      statisticData.forEach(dataItem =>
        ids.forEach(id => {
          if (dataItem.publisher.id === id) resultArray.push(dataItem);
        })
      );
      setData(_.sortBy(resultArray, 'date').reverse());
    };

    const { level } = user;
    if (level === '대리점') {
      history.push(`/main/ordermonth_1`);
    } else if (level === '본사') {
      history.push(`/main/ordermonth_2`);
    } else {
      loadData();
    }
  }, [startDate, endDate]);

  const getRecord = item => {
    const { vn, seq, date } = item;
    let month = date.toDate().getMonth() + 1;
    const year = date.toDate().getFullYear();

    if (month < 10) {
      month = '0' + month;
    }
    axios
      .get(
        `https://m.callmix.co.kr/cmxapi/recFileUrl?id=csj7979&pw=csj2718&vn=${vn}&callLogSeq=${seq}&yyyyMm=${year}${month}`
      )
      .then(result => {
        if (result.data.resObj) {
          let ok = window.confirm(
            '녹음파일 청취는 반드시 고객의 동의가 필요합니다. 동의를 받으셨습니까?'
          );
          if (ok) {
            window.open(result.data.resObj.recFileUrl, 'a', 'width=400, height=300');
          } else {
            return null;
          }
        } else {
          alert('녹취 파일이 존재하지 않습니다.');
        }
      });
  };

  const couponChange = (id, current) => {
    db.collection('statistic')
      .doc(id)
      .update({
        coupon: !current
      })
      .then(alert('처리되었습니다.'));
  };

  const exportExcel = () => {
    const arr = [];
    data.forEach(item =>
      arr.push({
        협력사명: item.publisher.company,
        가맹점코드: item.code,
        가맹점명: item.shopName,
        착신번호: item.rn,
        발신번호: formatMiddleStar(item.an, isReveal),
        통화시간: item.callDuration,
        통화시작시간: item.callStartTime,
        콜상태: item.callStat
      })
    );
    jsonToSheet(arr, 'K배달_월별주문조회');
  };

  return (
    <Container>
      <Helmet>
        <title>주문조회 - K배달 관리자시스템</title>
      </Helmet>
      <div
        style={{
          fontSize: '17px',
          marginBottom: '0.5rem',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: '1rem'
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: '600',
            color: '#0c8599',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div>
            <Icon name="call" style={{ marginRight: '0.5rem' }} onClick={_ => setReveal(true)} />
            <span>주문 조회</span>
          </div>

          <div
            style={{
              marginTop: '1rem',
              marginLeft: '2rem',
              fontSize: '12px',
              color: '#fa5252'
            }}
          >
            1. 통화실패, 통화시간 0초 건은 제외됩니다. (주문내역은 1시간 마다 업데이트 됩니다.){' '}
            <br />
            2. 당일 기록을 우선 출력하며 최대 검색 기간은 30일 까지 조회 가능합니다. (최대 10000건)
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <Button
            size="small"
            style={{
              fontWeight: '600',
              background: 'white',
              color: 'gray'
            }}
            onClick={exportExcel}
          >
            <Icon name="file excel" /> 엑셀 저장
          </Button>
        </div>
      </div>
      <div
        style={{
          color: 'gray',
          display: 'flex',
          background: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '4px',
          justifyContent: 'center',
          marginBottom: '0.5rem'
        }}
      >
        <span style={{ marginRight: '1rem' }}>검색일</span>
        <DatePicker
          dateFormat="yyyy. MM. dd"
          selected={startDate}
          locale={ko}
          onChange={value => {
            console.log(value);
            setStartDate(value);
          }}
          customInput={<CustomDateButton />}
        />
        <div style={{ fontSize: '18px', margin: '0 0.5rem' }}>~</div>
        <DatePicker
          dateFormat="yyyy. MM. dd"
          selected={endDate}
          locale={ko}
          onChange={value => {
            setEndDate(value);
          }}
          customInput={<CustomDateButton />}
        />
      </div>

      {data ? (
        <Table
          data={data}
          index
          perPage={20}
          headers={[
            {
              title: '통화일자',
              value: 'date',
              notSearch: true,
              width: 100,
              render: item => item.date.toDate().toLocaleDateString()
            },
            { title: '협력사명', value: 'publisherName' },
            { title: '가맹점코드', value: 'code', noRender: true },
            {
              title: '가맹점명',
              value: 'shopName',
              width: 140,
              render: item => (
                <>
                  <div>{item.shopName}</div>
                  <div style={{ color: 'gray', fontSize: '12px' }}>[{item.code}]</div>
                </>
              )
            },
            {
              title: '착신번호',
              value: 'rn',
              align: 'right',
              render: item => <span>{formatPhone(item.rn)}</span>
            },
            {
              title: '발신번호',
              value: 'an',
              align: 'right',
              render: item => <span>{formatMiddleStar(item.an, isReveal)}</span>
            },
            {
              title: '경로',
              value: 'callType',
              render: item =>
                item.callType === 'app' ? (
                  <span
                    style={{
                      background: '#e64980',
                      color: 'white',
                      fontWeight: 'bold',
                      padding: '2px 6px',
                      borderRadius: '8px'
                    }}
                  >
                    앱
                  </span>
                ) : (
                  <span
                    style={{
                      background: '#0c8599',
                      color: 'white',
                      fontWeight: 'bold',
                      padding: '2px 6px',
                      borderRadius: '8px'
                    }}
                  >
                    책자
                  </span>
                ),
              align: 'center'
            },
            {
              title: '통화시간(초)',
              value: 'callDuration',
              align: 'right',
              notSearch: true
            },
            {
              title: '통화시작시간',
              value: 'callStartTime',

              align: 'right',
              render: item => item.callStartTime.split(' ')[1]
            },
            {
              title: '파일',
              value: 'seq',
              align: 'center',
              notSearch: true,
              width: 65,
              render: item => (
                <Button
                  size="small"
                  style={{
                    padding: '0.2rem 0.6rem',
                    background: '#0c8599',
                    color: 'white'
                  }}
                  onClick={() => {
                    getRecord(item);
                  }}
                >
                  듣기
                </Button>
              )
            },
            {
              title: '지급여부',
              value: 'toggle',
              align: 'center',
              notSearch: true,
              width: 80,
              render: item => (
                <CustomButton
                  size="tiny"
                  onClick={() => {
                    couponChange(item.id, item.coupon);
                  }}
                  value={item.coupon}
                />
              )
            }
          ]}
        />
      ) : (
        <Loader active />
      )}
    </Container>
  );
};

export default OrderMonth_3;

const CustomDateButton = ({ value, onClick }) => {
  return (
    <Button
      size="small"
      style={{
        color: 'white',
        background: '#0c8599',
        padding: '0.2rem 0.5rem'
      }}
      onClick={() => {
        onClick();
      }}
    >
      {value}
    </Button>
  );
};

const CustomButton = ({ value, onClick }) => {
  const [flag, setFlag] = useState(value ? '지급' : '미지급');

  return (
    <Button
      size="small"
      style={{ padding: '0.2rem 0.5rem' }}
      color={flag === '지급' ? 'orange' : ''}
      onClick={() => {
        onClick();
        setFlag(flag === '지급' ? '미지급' : '지급');
      }}
    >
      {flag}
    </Button>
  );
};

// import React, { Component, useState } from "react";
// import styled from "styled-components";
// import { Input, Button, Icon, Loader, Dropdown } from "semantic-ui-react";
// import { withFirebase } from "../../firebase";
// import { connect } from "react-redux";
// import { compose } from "redux";
// import { withRouter } from "react-router-dom";
// import { formatPhone, formatMiddleStar } from "../../common/format";
// import DatePicker from "react-datepicker";
// import axios from "axios";
// import ReactTable from "react-table";
// import { jsonToSheet } from "../Admin/Admin";

// import "react-datepicker/dist/react-datepicker.css";

// const Container = styled.div`
//   width: 98%;
//   background: white;
//   margin-bottom: 2rem;
//   padding: 3rem 2rem;
//   border-radius: 4px;
//   box-shadow: 1px 1px 1px #dfdfdf;
//   .flex {
//     display: flex;
//     align-items: center;
//   }
// `;

// class OrderMonth_3 extends Component {
//   date = new Date();
//   startDate = new Date(
//     this.date.getFullYear(),
//     this.date.getMonth(),
//     this.date.getDate() - 3
//   );
//   state = {
//     keyword: "",
//     startDate: this.startDate,
//     endDate: this.date,
//     lastStartDate: this.startDate,
//     lastEndDate: this.date
//   };

//   componentDidMount() {
//     const { level } = this.props.user;
//     if (level === "대리점") {
//       this.props.history.push(`/main/ordermonth_1`);
//     } else if (level === "본사") {
//       this.props.history.push(`/main/ordermonth_2`);
//     } else if (level === "관리자") {
//       this.props.history.push(`/main/ordermonth`);
//     } else {
//       // 총판레벨
//       this.loadData();
//     }

//     document.getElementById("search-con").addEventListener("keydown", e => {
//       if (e.keyCode === 13) {
//         this.search();
//       }
//     });
//   }

//   search = () => {
//     const {
//       keyword,
//       allData,
//       condition,
//       lastEndDate,
//       lastStartDate,
//       startDate,
//       endDate
//     } = this.state;

//     const limitDay = endDate - startDate;
//     if (limitDay < 0) {
//       alert("마지막 기간이 시작기간 보다 빠릅니다.");
//       return null;
//     }
//     if (Math.floor(limitDay / 86400000) > 30) {
//       alert("최대 검색 가능 일수는 30일 입니다.");
//       return null;
//     } else {
//       this.setState({
//         data: null
//       });
//     }

//     const searchDetail = allData => {
//       let newData = allData;
//       // 검색조건 있을 때
//       if (condition) {
//         if (condition === "publisher") {
//           newData = allData.filter(
//             item =>
//               item.publisher &&
//               item.publisher.company.includes(keyword) === true
//           );
//         } else {
//           newData = allData.filter(
//             item =>
//               item[condition] && item[condition].includes(keyword) === true
//           );
//         }
//         if (this.state.selectedDaeri) {
//           newData = newData.filter(
//             item =>
//               item.publisher && item.publisher.id === this.state.selectedDaeri
//           );
//         }
//       } else {
//         if (this.state.selectedDaeri) {
//           newData = allData.filter(
//             item =>
//               item.publisher && item.publisher.id === this.state.selectedDaeri
//           );
//         }
//       }

//       this.setState({
//         data: newData,
//         allData: allData
//       });
//     };

//     if (lastStartDate !== startDate) {
//       this.setState({
//         lastStartDate: startDate
//       });
//     }

//     if (lastEndDate !== endDate) {
//       this.setState({
//         lastEndDate: endDate
//       });
//     }

//     if (lastStartDate !== startDate || lastEndDate !== endDate) {
//       this.loadStatistic().then(result => {
//         searchDetail(result);
//       });
//     } else {
//       searchDetail(allData);
//     }
//   };

//   // 기간변경후 검색시 다시 로드 하는 용도
//   loadStatistic = () => {
//     let data = [];

//     const endDate = new Date(
//       this.state.endDate.getFullYear(),
//       this.state.endDate.getMonth(),
//       this.state.endDate.getDate() + 1
//     );

//     return this.props.firebase.db
//       .collection("statistic")
//       .where("date", ">=", this.state.startDate)
//       .where("date", "<=", endDate)
//       .orderBy("date", "desc")
//       .get()
//       .then(snapshots => {
//         snapshots.forEach(doc =>
//           data.push({
//             ...doc.data(),
//             id: doc.id
//           })
//         );

//         this.setState({
//           allData: data,
//           data
//         });
//         return data;
//       });
//   };

//   resetData = () => {
//     this.setState({
//       data: this.state.allData
//     });
//   };

//   onChange = e => {
//     this.setState({
//       [e.target.name]: e.target.value
//     });
//   };

//   loadData = () => {
//     let userData = [];
//     this.props.firebase.db
//       .collection("users")
//       .get()
//       .then(snapshots => {
//         snapshots.forEach(doc => userData.push({ ...doc.data(), pw: "" }));

//         const daeriOptions = [];
//         const daeriList = userData
//           .filter(item => item.level === "대리점")
//           .filter(item => item.publisher.id === this.props.user.id);

//         daeriList.map((item, index) => {
//           daeriOptions.push({
//             key: index + 1,
//             text: `${item.company} (${item.id})`,
//             value: item.id
//           });
//           return null;
//         });
//         this.setState({
//           daeriOptions,
//           daeriList
//         });
//       });

//     const endDate = new Date(
//       this.state.endDate.getFullYear(),
//       this.state.endDate.getMonth(),
//       this.state.endDate.getDate() + 1
//     );

//     this.props.firebase.db
//       .collection("statistic")
//       .where("date", ">=", this.state.startDate)
//       .where("date", "<=", endDate)
//       .orderBy("date", "desc")
//       .get()
//       .then(snapshots => {
//         let data = [];
//         snapshots.forEach(doc =>
//           data.push({
//             ...doc.data(),
//             id: doc.id
//           })
//         );

//         let allData = [];

//         data &&
//           data.forEach(dataItem =>
//             this.state.daeriList.forEach(daeri => {
//               if (dataItem.publisher.id === daeri.id) allData.push(dataItem);
//             })
//           );
//         this.setState({
//           allData: allData,
//           data: allData
//         });
//       });
//   };

//   onPublisherChange = (e, { value }) => {
//     let daeriOptions = [];
//     this.state.daeriList
//       .filter(item => item.publisher && item.publisher.company === value)
//       .map((item, index) => {
//         daeriOptions.push({
//           key: index,
//           text: item.company,
//           value: item.company
//         });
//         return null;
//       });
//     this.setState({
//       daeriOptions
//     });
//   };

//   onSelectChange = (e, { name, value }) => {
//     this.setState({
//       [name]: value
//     });
//   };

//   dateChange = value => {
//     this.setState({
//       lastStartDate: this.state.startDate,
//       startDate: value
//     });
//   };

//   endDateChange = value => {
//     this.setState({
//       lastEndDate: this.state.endDate,
//       endDate: value
//     });
//   };

//   onCouponChange = (id, current) => {
//     this.props.firebase.db
//       .collection("statistic")
//       .doc(id)
//       .update({
//         coupon: !current
//       })
//       .then(alert("처리되었습니다."));
//   };

//   render() {
//     const { daeriOptions } = this.state;

//     return (
//       <Container>
//         <div
//           style={{
//             borderBottom: "1.5px solid lightgray",
//             padding: "1rem 3rem",
//             fontSize: "17px",
//             marginBottom: "2rem",
//             fontWeight: "600",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between"
//           }}
//         >
//           <div>
//             <Icon name="call" style={{ marginRight: "1rem" }} />
//             <span>월별주문조회</span>
//             <div
//               style={{
//                 margin: "1rem 1rem 0 0.5rem",
//                 fontSize: "14px",
//                 color: "#fa5252"
//               }}
//             >
//               1. 통화실패, 통화시간 0초 건은 제외됩니다. (기록은 1시간 마다
//               업데이트 됩니다.) <br />
//               2. 최근 3일의 데이터를 우선 출력하고 최대 검색 기간은 30일 까지
//               가능합니다.
//             </div>
//           </div>
//         </div>
//         <div style={{ width: "100%" }}>
//           <div
//             className="flex"
//             style={{
//               justifyContent: "center",
//               marginTop: "1rem",
//               paddingBottom: "1rem",
//               borderBottom: "1px solid lightgrey",
//               marginBottom: "1rem"
//             }}
//           >
//             <div style={{ marginRight: "1rem" }}>통화일자</div>
//             <DatePicker
//               dateFormat="yyyy. MM. dd"
//               selected={this.state.startDate}
//               onChange={this.dateChange}
//               customInput={<CustomDateButton />}
//             />
//             <div style={{ fontSize: "18px", margin: "0 1rem" }}>~</div>
//             <DatePicker
//               dateFormat="yyyy. MM. dd"
//               selected={this.state.endDate}
//               onChange={this.endDateChange}
//               customInput={<CustomDateButton />}
//             />
//           </div>
//           <div className="flex" style={{ justifyContent: "center" }}>
//             <Dropdown
//               onChange={this.onSelectChange}
//               options={[
//                 { key: 1, text: "가맹점명", value: "shopName" },
//                 { key: 2, text: "사업자번호", value: "businessNumber" },
//                 { key: 3, text: "착신번호", value: "rn" },
//                 { key: 4, text: "발신번호", value: "an" },
//                 { key: 5, text: "050전화번호", value: "vn" },
//                 { key: 6, text: "가맹점코드", value: "code" }
//               ]}
//               placeholder="검색조건"
//               name="condition"
//               style={{ margin: "0 0.5rem" }}
//             />
//             <Input style={{ margin: "0 1rem" }}>
//               <input onChange={this.onChange} name="keyword" id="search-con" />
//             </Input>
//           </div>

//           <div
//             className="flex"
//             style={{ justifyContent: "center", marginTop: "1rem" }}
//           >
//             <Dropdown
//               onChange={this.onSelectChange}
//               options={daeriOptions}
//               placeholder={daeriOptions ? "대리점" : "총판을 선택하세요"}
//               selection
//               name="selectedDaeri"
//               style={{ width: "50px", margin: "0 0.5rem" }}
//             />
//           </div>
//           <div
//             className="flex"
//             style={{
//               justifyContent: "center",
//               margin: "1rem 0",
//               borderBottom: "1px solid lightgrey",
//               paddingBottom: "1rem"
//             }}
//           >
//             <Button size="small" primary onClick={this.search}>
//               <Icon name="search" />
//               검색
//             </Button>
//             <Button size="small" onClick={this.resetData}>
//               <Icon name="redo" />
//               초기화
//             </Button>
//           </div>
//         </div>
//         <div className="flex" style={{ justifyContent: "flex-end" }}>
//           <Button
//             size="small"
//             style={{ fontWeight: "600", background: "#0e9d58", color: "white" }}
//             onClick={() => {
//               const arr = [];
//               this.state.data.forEach(item =>
//                 arr.push({
//                   협력사명: item.publisher.company,
//                   가맹점코드: item.code,
//                   가맹점명: item.shopName,
//                   착신번호: item.rn,
//                   발신번호: formatMiddleStar(item.an),
//                   통화시간: item.callDuration,
//                   통화시작시간: item.callStartTime,
//                   콜상태: item.callStat
//                 })
//               );
//               jsonToSheet(arr, "K배달_월별주문조회");
//             }}
//           >
//             <Icon name="file excel" /> 엑셀 저장
//           </Button>
//         </div>
//         {this.state.data ? (
//           <ReactTable
//             data={this.state.data}
//             style={{ fontSize: "12px", textAlign: "center", marginTop: "1rem" }}
//             previousText="◀"
//             nextText="▶"
//             loadingText="Loading..."
//             noDataText="데이터가 없습니다."
//             pageText="현재 페이지"
//             ofText="중"
//             rowsText="개씩"
//             className="-highlight"
//             columns={[
//               {
//                 Header: "No.",
//                 accessor: "index",
//                 Cell: row => <span>{this.state.data.length - row.index}</span>,
//                 width: 50
//               },
//               {
//                 Header: "통화일자",
//                 id: "date",
//                 width: 100,
//                 accessor: data => data.date.toDate().toLocaleDateString()
//               },
//               { Header: "협력사명", accessor: "publisher.company", width: 120 },
//               { Header: "가맹점코드", accessor: "code", width: 80 },
//               { Header: "가맹점명", accessor: "shopName", width: 140 },
//               {
//                 Header: "착신번호",
//                 accessor: "rn",
//                 Cell: row => <span>{formatPhone(row.value)}</span>
//               },
//               {
//                 Header: "발신번호",
//                 accessor: "an",
//                 Cell: row => <span>{formatMiddleStar(row.value)}</span>
//               },
//               { Header: "통화시간 (초)", accessor: "callDuration", width: 90 },
//               {
//                 Header: "통화시작시간",
//                 id: "callStartTime",
//                 width: 90,
//                 accessor: data => data.callStartTime.split(" ")[1]
//               },
//               {
//                 Header: "파일",
//                 accessor: "seq",
//                 width: 60,
//                 Cell: row => (
//                   <Button
//                     size="tiny"
//                     primary
//                     style={{ padding: "0.3rem 0.6rem" }}
//                     onClick={() => {
//                       const { vn, seq, date } = row.original;
//                       let month = date.toDate().getMonth() + 1;
//                       const year = date.toDate().getFullYear();

//                       if (month < 10) {
//                         month = "0" + month;
//                       }
//                       axios
//                         .get(
//                           `https://m.callmix.co.kr/cmxapi/recFileUrl?id=csj7979&pw=csj2718&vn=${vn}&callLogSeq=${seq}&yyyyMm=${year}${month}`
//                         )
//                         .then(result => {
//                           if (result.data.resObj) {
//                             let ok = window.confirm(
//                               "녹음파일 청취는 반드시 고객의 동의가 필요합니다. 동의를 받으셨습니까?"
//                             );
//                             if (ok) {
//                               window.open(
//                                 result.data.resObj.recFileUrl,
//                                 "a",
//                                 "width=400, height=300"
//                               );
//                             } else {
//                               return null;
//                             }
//                           } else {
//                             alert("녹취 파일이 존재하지 않습니다.");
//                           }
//                         });
//                     }}
//                   >
//                     듣기
//                   </Button>
//                 )
//               },
//               {
//                 Header: "지급여부",
//                 accessor: "toggle",
//                 width: 60,
//                 Cell: row => (
//                   <CustomButton
//                     onClick={() => {
//                       this.onCouponChange(row.original.id, row.original.coupon);
//                     }}
//                     value={row.original.coupon}
//                   />
//                 )
//               }
//             ]}
//           />
//         ) : (
//           <div>
//             <Loader active />
//           </div>
//         )}
//       </Container>
//     );
//   }
// }

// const mapStateToProps = state => ({
//   user: state.auth.user
// });

// export default compose(
//   connect(mapStateToProps),
//   withFirebase,
//   withRouter
// )(OrderMonth_3);

// const CustomButton = ({ value, onClick }) => {
//   const [flag, setFlag] = useState(value ? "지급" : "미지급");

//   return (
//     <Button
//       size="small"
//       style={{ padding: "0.3rem 0.5rem" }}
//       color={flag === "지급" && "orange"}
//       onClick={() => {
//         onClick();
//         setFlag(flag === "지급" ? "미지급" : "지급");
//       }}
//     >
//       {flag}
//     </Button>
//   );
// };

// const CustomDateButton = ({ value, onClick }) => {
//   return (
//     <Button
//       size="small"
//       basic
//       onClick={() => {
//         onClick();
//       }}
//     >
//       {value}
//     </Button>
//   );
// };
