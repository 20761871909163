import { createAction, handleActions } from "redux-actions";

const SET_AUTH = "auth/SET_AUTH";
const CLEAR_AUTH = "auth/CLEAR_AUTH";
const SET_PATH = "auth/SET_PATH";

export const setAuth = createAction(SET_AUTH);
export const clearAuth = createAction(CLEAR_AUTH);
export const setPath = createAction(SET_PATH);

const initialState = {
  isAuthenticated: false,
  user: {},
  path: null
};

const reducers = handleActions(
  {
    [SET_AUTH]: (state, action) => ({
      ...state,
      isAuthenticated: true,
      user: action.payload
    }),
    [CLEAR_AUTH]: state => ({
      ...state,
      isAuthenticated: false,
      user: null
    }),
    [SET_PATH]: (state, action) => ({
      ...state,
      path: action.payload
    })
  },
  initialState
);

export default reducers;
