import React, { useEffect } from "react";
import Chart from "chart.js";

const LineChart = ({ data }) => {
  const makeChart = () => {
    var ctx = document.getElementById("myChart");
    const gridLines = {
      display: false,
      drawBorder: false
    };
    new Chart(ctx, {
      type: "line",
      data: {
        labels: data.labels,
        datasets: [
          {
            label: "책자",
            fill: false,
            data: data.data.book,
            borderColor: "#0c8599",
            backgroundColor: "#0c8599"
          },
          {
            label: "앱",
            fill: false,
            data: data.data.app,
            borderColor: "#e64980",
            backgroundColor: "#e64980"
          }
        ]
      },
      options: {
        hover: {
          mode: "nearest",
          intersect: true
        },
        legend: {
          position: "left"
        },
        scales: {
          xAxes: [
            {
              gridLines
            }
          ],
          yAxes: [
            {
              gridLines,
              ticks: {
                stepSize: 3000
              }
            }
          ]
        },
        layout: {
          padding: {
            left: 5,
            right: 20,
            top: 10
          }
        }
      }
    });
  };
  useEffect(() => {
    makeChart();
  }, []);

  return (
    <canvas
      id="myChart"
      style={{
        width: "60%",
        maxWidth: "480px",
        maxHeight: "210px"
      }}
    ></canvas>
  );
};

export default LineChart;
