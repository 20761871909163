import React, { Component } from "react";
import styled from "styled-components";
import { Input, Button, Icon, Loader } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  width: 90%;
  background: white;
  margin-top: 2rem;
  padding: 3rem 2rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #dfdfdf;
  .flex {
    display: flex;
  }
`;

class PublishEdit extends Component {
  state = {
    isLoading: false,
    isPWreseted: false,
    status: false
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id === ":id") {
      this.props.history.push(`/main/listpublish`);
    } else {
      this.loadData();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user) {
      return { user: nextProps.user };
    }
    return null;
  }

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSelectChange = e => {
    this.setState({
      level: e.target.innerText
    });
  };

  loadData = () => {
    const { id } = this.props.match.params;
    this.props.firebase.db
      .collection("users")
      .doc(id)
      .get()
      .then(doc =>
        this.setState({
          ...doc.data(),
          pw: ""
        })
      );
  };

  resetPassword = () => {
    let result = window.confirm("정말 초기화 하시겠습니까?");
    if (result) {
      this.setState({
        isPWreseted: true
      });
    } else {
      return null;
    }

    alert("비밀번호가 1111로 초기화 되었습니다. 수정 후 등록을 눌러주세요.");
  };

  onSubmit = () => {
    const {
      businessNumber,
      id,
      level,
      company,
      ceo,
      phone,
      address,
      email,
      account,
      bank,
      status,
      region
    } = this.state;

    if (businessNumber !== "" || businessNumber) {
      const result = businessNumber.split("-");
      if (
        result.length !== 3 ||
        result[0].length !== 3 ||
        result[1].length !== 2 ||
        result[2].length !== 5
      ) {
        alert("사업자번호를 형식에 맞게 써주세요.");
        return null;
      } else {
        this.setState({
          isLoading: true
        });
        let newData = {
          businessNumber,
          id,
          level,
          company,
          ceo,
          phone,
          address,
          email,
          account,
          bank,
          status,
          region
        };
        if (this.state.isPWreseted) {
          newData.pw = "1111";
        }

        axios.defaults.baseURL =
          "https://us-central1-phone-fa49a.cloudfunctions.net/api";
        axios.post("/edit", newData).then(result => {
          // console.log(result);
          this.setState({
            isLoading: false
          });
          if (result.data.success) {
            this.props.history.push(`/main/publish/${id}`);
          } else {
            alert(result.data.error);
          }
        });
      }
    } else {
      return alert("사업자번호를 입력해주세요");
    }
  };

  render() {
    const data = this.state;
    return (
      <Container>
        <div
          style={{
            borderBottom: "1.5px solid lightgray",
            padding: "1rem 3rem",
            fontSize: "17px",
            marginBottom: "2rem",
            fontWeight: "600"
          }}
        >
          <Icon name="edit" style={{ marginRight: "1rem" }} />
          협력사 수정
        </div>
        {data ? (
          <>
            <div className="flex">
              <InputSet
                name="아이디"
                id="id"
                onChange={this.onInputChange}
                value={data.id}
                disabled={true}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                  padding: "0.5rem 1rem",
                  justifyContent: "flex-end"
                }}
              >
                {this.state.user && this.state.user.level === "관리자" && (
                  <>
                    <div>비밀번호</div>
                    <Button
                      size="small"
                      style={{ width: "75%", marginLeft: "1rem" }}
                      onClick={this.resetPassword}
                    >
                      비밀번호 초기화
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className="flex">
              <InputSet
                name="분류"
                id="level"
                onChange={this.onSelectChange}
                value={data.level}
                disabled={true}
              />
              <InputSet
                name="지역"
                id="region"
                onChange={this.onInputChange}
                value={data.region}
              />
            </div>
            <div className="flex">
              <InputSet
                name="회사명"
                id="company"
                onChange={this.onInputChange}
                value={data.company}
              />
              <InputSet
                name="대표자명"
                id="ceo"
                onChange={this.onInputChange}
                value={data.ceo}
              />
            </div>
            <div className="flex">
              <InputSet
                name="전화번호"
                id="phone"
                onChange={this.onInputChange}
                value={data.phone}
              />
              <InputSet
                name="사업자번호"
                id="businessNumber"
                onChange={this.onInputChange}
                placeholder="예시: 123-45-67890"
                value={data.businessNumber}
              />
            </div>
            <div className="flex">
              <InputSetLarge
                name="주소"
                id="address"
                onChange={this.onInputChange}
                value={data.address}
              />
            </div>
            <div className="flex">
              <InputSetLarge
                name="이메일주소"
                id="email"
                onChange={this.onInputChange}
                value={data.email}
              />
            </div>
            <div className="flex">
              <InputSet
                name="은행명"
                id="bank"
                onChange={this.onInputChange}
                value={data.bank}
              />
              <InputSet
                name="계좌번호"
                id="account"
                onChange={this.onInputChange}
                value={data.account}
              />
            </div>
            <div
              className="flex"
              style={{
                marginTop: "1rem"
              }}
            >
              <div
                style={{
                  marginLeft: "10%",
                  marginRight: "1rem"
                }}
              >
                상태
              </div>
              <div
                style={{
                  marginTop: "1rem",
                  marginRight: "1rem"
                }}
              >
                {this.state.status ? "영업중" : "영업중지"}
              </div>
              {this.props.user.level !== "대리점" && (
                <Button
                  size="tiny"
                  onClick={() => {
                    this.setState({
                      status: !this.state.status
                    });
                  }}
                >
                  상태변경
                </Button>
              )}
            </div>
            <div
              className="flex"
              style={{ justifyContent: "center", marginTop: "1rem" }}
            >
              <Button
                primary
                style={{ minWidth: "120px", width: "40%" }}
                onClick={() => {
                  this.onSubmit();
                }}
              >
                {this.state.isLoading ? (
                  <Loader active inline size="mini" />
                ) : (
                  "저장"
                )}
              </Button>
            </div>
          </>
        ) : (
          <div>
            <Loader active />
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(PublishEdit);

const InputSet = ({ name, onChange, id, placeholder, value, disabled }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "50%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      {" "}
      <div>{name}</div>
      <Input
        placeholder={placeholder || name}
        style={{ width: "75%", marginLeft: "1rem" }}
      >
        <input
          name={id}
          onChange={onChange}
          defaultValue={value}
          disabled={disabled}
        />
      </Input>
    </div>
  );
};

const InputSetLarge = ({ name, onChange, id, value }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      {" "}
      <div>{name}</div>
      <Input placeholder={name} style={{ width: "88%", marginLeft: "1rem" }}>
        <input name={id} onChange={onChange} defaultValue={value} />
      </Input>
    </div>
  );
};
