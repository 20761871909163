import React, { Component } from "react";
import styled from "styled-components";
import { withFirebase } from "../../firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Section = styled.div`
  margin-top: 2rem;
  padding: 1rem 0.5rem;
  border-top: 1px solid grey;
  .item {
    margin-top: 1rem;
  }
  width: 90%;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

class Admin extends Component {
  loadData = () => {
    const data = this.props.firebase.db
      .collection("stores")
      .doc("G7E9M")
      .get()
      .then(doc => doc.data());
    return data;
  };

  render() {
    return (
      <Container>
        <Section>
          <button
            onClick={() => {
              this.loadData().then(result => console.log(result));
            }}
          >
            로드
          </button>
        </Section>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(Admin);
