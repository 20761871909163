import React, { useState, useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Table from '../../components/Table/Table';
import { Icon, Loader, Button } from 'semantic-ui-react';
import { FirebaseContext } from '../../firebase';
import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { formatPhone, dateFormat } from '../../common/format';
import { jsonToSheet } from '../Admin/Admin';
import _ from 'lodash';

const Container = styled.div`
  width: 95%;
  padding: 2rem 2.5%;
`;

const StoreList = props => {
  const [data, setData] = useState(null);
  const { db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);

  useEffect(() => {
    const loadData = () => {
      let userData = [];
      db.collection('users')
        .get()
        .then(snapshots => {
          snapshots.forEach(doc => userData.push({ ...doc.data(), pw: '' }));

          const daeriList = userData.filter(item => item.level === '대리점');

          const daeriIds = daeriList
            .filter(item => item.headquarter.id === user.id)
            .map(item => item.id);

          db.collection('stores')
            .orderBy('createdAt', 'desc')
            .get()
            .then(snapshots => {
              let data = [];

              snapshots.forEach(doc =>
                data.push({
                  ...doc.data(),
                  publisherName: doc.data().publisher.company || doc.data().headquarter.company,
                  numStatus: doc.data().changeLog
                    ? doc.data().changeLog[doc.data().changeLog.length - 1].status
                    : '미사용',

                  appStatus:
                    (doc.data().changeLog_app &&
                      doc.data().changeLog_app[doc.data().changeLog_app.length - 1].status) ||
                    '미사용'
                })
              );
              let resultArray = [];
              daeriIds.map(id =>
                data.filter(store => store.publisher.id === id).map(item => resultArray.push(item))
              );

              setData(_.sortBy(resultArray, ['createdAt']).reverse());
            });
        });
    };
    if (user.level === '총판' || user.level === '대리점') {
      props.history.push('/main/store_list_1');
    } else {
      loadData();
    }
  }, []);

  const exportExcel = () => {
    const arr = [];
    data.forEach(item =>
      arr.push({
        협력사명: item.publisherName,
        가맹점코드: item.code,
        회사명: item.company,
        대표자: item.ceo,
        일반전화번호: item.tel,
        [`050전화번호`]: item.phone050,
        사업자번호: item.businessNumber,
        [`050가입일자`]: item.changeLog && item.changeLog[0].createdAt
      })
    );
    jsonToSheet(arr, 'K배달_가맹점목록');
  };

  return (
    <Container>
      <Helmet>
        <title>가맹점 - K배달 관리자시스템</title>
      </Helmet>
      <div
        style={{
          display: 'flex',
          marginBottom: '1rem',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: '600',
            color: '#0c8599',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Icon name="table" />
          <span>가맹점</span>
          {data && (
            <span style={{ fontSize: '12px', color: 'gray', marginLeft: '1rem' }}>
              총 {data.length} 개
            </span>
          )}
        </div>
        <div style={{ paddingRight: 0, marginRight: 0 }}>
          <Button
            size="small"
            style={{ fontWeight: '600', background: 'white', color: 'gray' }}
            onClick={exportExcel}
          >
            <Icon name="file excel" /> 엑셀 저장
          </Button>
          <Link to="/main/addstore">
            <Button
              size="small"
              style={{
                fontWeight: '600',
                marginRight: 0,
                background: 'white',
                color: 'gray'
              }}
            >
              <Icon name="add" /> 가맹점 등록
            </Button>
          </Link>
        </div>
      </div>

      {data ? (
        <Table
          data={data}
          index
          perPage={20}
          headers={[
            {
              title: '협력사명',
              value: 'publisherName',
              render: item => item.publisher.company || `본사/${item.headquarter.company}`
            },
            { title: '가맹점코드', value: 'code', width: 100, noRender: true },
            {
              title: '가맹점명 [코드]',
              width: 130,
              value: 'company',
              render: item => (
                <>
                  <Link to={`/main/store/${item.code}`} target="_blank">
                    <div>{item.company}</div>
                  </Link>
                  <div style={{ color: 'gray', fontSize: '12px' }}>[{item.code}]</div>
                </>
              )
            },
            { title: '대표자', value: 'ceo', width: 80 },
            { title: '일반전화번호', value: 'tel' },
            {
              title: '050전화번호',
              value: 'phone050',
              render: item => formatPhone(item.phone050)
            },
            {
              title: '050가입일자',
              notSearch: true,
              render: item =>
                item.changeLog && dateFormat(item.changeLog[0].createdAt).split(' ')[0]
            },
            {
              title: '상태',
              value: 'numStatus',
              render: item => (
                <Link
                  to={`/main/liststore/${item.code}`}
                  style={{
                    color:
                      item.changeLog &&
                      item.changeLog[item.changeLog.length - 1].status !== '사용중' &&
                      '#ff6b6b'
                  }}
                >
                  {item.numStatus}
                </Link>
              ),
              notSearch: true,
              width: 80
            },
            {
              title: '콕콕114 상태',
              value: 'appStatus',
              notSearch: true,
              render: item => {
                return <Link to={`/main/liststore_app/${item.code}`}>{item.appStatus}</Link>;
              }
            }
          ]}
        />
      ) : (
        <Loader active />
      )}
    </Container>
  );
};

export default withRouter(StoreList);
