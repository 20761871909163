import React, { useEffect, useState, useContext } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import Table from "../../components/Table/Table";
import { Icon, Loader, Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FirebaseContext } from "../../firebase";
import { formatPhone } from "../../common/format";
import { jsonToSheet } from "../Admin/Admin";

const Container = styled.div`
  width: 95%;
  padding: 2rem 2.5%;
`;

const PublishList_2 = props => {
  const [data, setData] = useState(null);
  const { db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);
  const { history } = props;

  useEffect(() => {
    const { level, id } = user;

    const loadUserData = () => {
      return db
        .collection("users")
        .where("headquarter.id", "==", id)
        .get()
        .then(snapshots => {
          let result = [];
          result.push(user);
          snapshots.forEach(doc => result.push(doc.data()));
          return result;
        });
    };

    const loadStoreData = () => {
      return db
        .collection("stores")
        .get()
        .then(snapshots => {
          let result = [];
          snapshots.forEach(doc => result.push({ ...doc.data(), pw: null }));
          return result;
        });
    };

    const loadData = async () => {
      const userData = await loadUserData();
      const storeData = await loadStoreData();

      let result = userData.map(item => {
        const activeStoreCount = storeData
          .filter(store => store.publisher.id === item.id)
          .filter(
            store =>
              store &&
              store.changeLog &&
              store.changeLog[store.changeLog.length - 1].status === "사용중"
          ).length;

        const allStoreCount = storeData.filter(
          store => store.publisher.id === item.id
        ).length;
        const appCount = storeData
          .filter(store => store.publisher.id === item.id)
          .filter(store => store.phone050_app).length;
        return { ...item, activeStoreCount, allStoreCount, appCount };
      });

      setData(result);
    };

    if (level === "총판") {
      history.push(`/main/publish_list_1`);
    } else if (level === "대리점") {
      // 대리점은 본인만 볼 수 있음
      history.push(`/main/publish/${id}`);
    } else {
      loadData();
    }
  }, []);

  const exportExcel = () => {
    const arr = [];
    data.forEach(item =>
      arr.push({
        분류: item.level,
        회사명: item.company,
        ID: item.id,
        대표자: item.ceo,
        전화번호: item.phone,
        주소: item.address,
        사업자번호: item.businessNumber,
        활성가맹점수: item.activeStoreCount,
        총가맹점수: item.allStoreCount
      })
    );
    jsonToSheet(arr, "K배달_협력사목록");
  };

  return (
    <Container>
      <Helmet>
        <title>협력사 - K배달 관리자시스템</title>
      </Helmet>
      <div
        style={{
          display: "flex",
          marginBottom: "1rem",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#0c8599",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Icon name="table" />
          <span>협력사</span>
          {data && (
            <span
              style={{ fontSize: "12px", color: "gray", marginLeft: "1rem" }}
            >
              총 {data.length} 개
            </span>
          )}
        </div>
        <div style={{ paddingRight: 0, marginRight: 0 }}>
          <Button
            size="small"
            style={{ fontWeight: "600", background: "white", color: "gray" }}
            onClick={exportExcel}
          >
            <Icon name="file excel" /> 엑셀 저장
          </Button>
          <Link to="/main/addpublish">
            <Button
              size="small"
              style={{
                fontWeight: "600",
                marginRight: 0,
                background: "white",
                color: "gray"
              }}
            >
              <Icon name="add" /> 협력사 등록
            </Button>
          </Link>
        </div>
      </div>

      {data ? (
        <Table
          data={data}
          index
          perPage={20}
          headers={[
            {
              title: "분류",
              value: "level",
              width: 60
            },
            {
              title: "회사명",
              value: "company",
              render: item => (
                <Link to={`/main/publish/${item.id}`}>{item.company}</Link>
              )
            },
            { title: "지역", value: "region", width: 120 },
            { title: "ID", value: "id", width: 80 },
            { title: "대표자", value: "ceo", width: 80 },
            {
              title: "전화번호",
              phone: "company",
              render: item => formatPhone(item.phone),
              width: 120
            },
            { title: "주소", value: "address" },
            { title: "사업자번호", value: "businessNumber", width: 120 },
            {
              title: "책자 가맹점수",
              notSearch: true,
              width: 100,
              value: "activeStoreCount",
              align: "center",
              render: item =>
                item.level === "대리점" &&
                `${item.activeStoreCount}/${item.allStoreCount}`
            },
            {
              title: "앱 가맹점수",
              notSearch: true,
              align: "center",
              width: 100,
              render: item => item.level === "대리점" && `${item.appCount}`
            },
            {
              title: "상태",
              value: "status",
              render: item => (item.status ? "영업중" : "영업중지"),
              notSearch: true,
              width: 60
            }
          ]}
        />
      ) : (
        <Loader active />
      )}
    </Container>
  );
};

export default PublishList_2;

// import React, { Component } from "react";
// import styled from "styled-components";
// import {
//   Input,
//   Button,
//   Icon,
//   Loader,
//   Table,
//   Pagination
// } from "semantic-ui-react";
// import { withFirebase } from "../../firebase";
// import { connect } from "react-redux";
// import { compose } from "redux";
// import { withRouter, Link } from "react-router-dom";
// import { jsonToSheet } from "../Admin/Admin";

// const Container = styled.div`
//   width: 98%;
//   background: white;
//   margin-bottom: 2rem;
//   padding: 3rem 2rem;
//   border-radius: 4px;
//   box-shadow: 1px 1px 1px #dfdfdf;
//   .flex {
//     display: flex;
//     align-items: center;
//   }
// `;
// const Row = styled(Table.Row)`
//   &:hover {
//     background: #e7f5ff;
//   }
// `;
// // 본사용 리스트 출력
// class PublishList_2 extends Component {
//   state = {
//     keyword: "",
//     activePage: 1
//   };

//   componentDidMount() {
//     this.loadData();
//     this.loadStoreData();

//     document.getElementById("search-con").addEventListener("keydown", e => {
//       if (e.keyCode === 13) {
//         this.search();
//       }
//     });
//   }

//   search = () => {
//     const { keyword, allData, selectedPublisher } = this.state;
//     let newData;
//     if (selectedPublisher) {
//       newData = allData
//         .filter(
//           item => item.publisher && item.publisher.id === selectedPublisher
//         )
//         .filter(
//           item => item.company && item.company.includes(keyword) === true
//         );
//     } else {
//       newData = allData.filter(
//         item => item.company && item.company.includes(keyword) === true
//       );
//     }

//     this.setState({
//       data: newData,
//       activePage: 1
//     });
//     this.paginationFilter(newData);
//   };

//   resetData = () => {
//     this.setState({
//       data: this.state.allData,
//       activePage: 1
//     });
//     this.paginationFilter(this.state.allData);
//   };

//   onChange = e => {
//     this.setState({
//       [e.target.name]: e.target.value
//     });
//   };

//   loadStoreData = () => {
//     this.props.firebase.db
//       .collection("stores")
//       .get()
//       .then(snapshots => {
//         let result = [];
//         snapshots.forEach(doc => result.push(doc.data()));
//         this.setState({
//           storeData: result
//         });
//       });
//   };

//   loadData = () => {
//     const { id } = this.props.user;
//     let data = [];
//     this.props.firebase.db
//       .collection("users")
//       .where("headquarter.id", "==", id)
//       .get()
//       .then(snapshots => {
//         data.push({ ...this.props.user });
//         snapshots.forEach(doc => data.push({ ...doc.data(), pw: "" }));

//         this.setState({
//           allData: data,
//           data
//         });
//         this.paginationFilter(data);
//       });
//   };

//   handlePaginationChange = (e, { activePage }) => {
//     this.setState({
//       activePage
//     });
//   };

//   paginationFilter = data => {
//     let result = [];
//     const dataPerPage = 20;
//     const last = Math.ceil(data.length / dataPerPage);
//     for (let i = 0; i < last; i++) {
//       if (i === 0) {
//         result.push(
//           data.slice(i + i * dataPerPage, dataPerPage + i * dataPerPage)
//         );
//       } else {
//         result.push(data.slice(i * dataPerPage, dataPerPage + i * dataPerPage));
//       }
//     }
//     this.setState({
//       paginationData: result
//     });
//   };

//   render() {
//     return (
//       <Container>
//         <div
//           style={{
//             borderBottom: "1.5px solid lightgray",
//             padding: "1rem 3rem",
//             fontSize: "17px",
//             marginBottom: "2rem",
//             fontWeight: "600",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between"
//           }}
//         >
//           <div>
//             <Icon name="th list" style={{ marginRight: "1rem" }} />
//             <span>협력사 목록</span>
//           </div>
//         </div>
//         <div style={{ width: "100%" }}>
//           <div className="flex" style={{ justifyContent: "center" }}>
//             <div>협력사명</div>
//             <Input style={{ margin: "0 1rem" }}>
//               <input onChange={this.onChange} name="keyword" id="search-con" />
//             </Input>
//           </div>
//           <div
//             className="flex"
//             style={{
//               justifyContent: "center",
//               margin: "1rem 0",
//               borderBottom: "1px solid lightgrey",
//               paddingBottom: "1rem"
//             }}
//           >
//             <Button size="small" primary onClick={this.search}>
//               <Icon name="search" />
//               검색
//             </Button>
//             <Button size="small" onClick={this.resetData}>
//               <Icon name="redo" />
//               초기화
//             </Button>
//           </div>
//         </div>
//         <div className="flex" style={{ justifyContent: "flex-end" }}>
//           <Button
//             size="small"
//             style={{ fontWeight: "600", background: "#0e9d58", color: "white" }}
//             onClick={() => {
//               const arr = [];
//               this.state.data.forEach((item, index) =>
//                 arr.push({
//                   no: index + 1,
//                   분류: item.level,
//                   회사명: item.company,
//                   id: item.id,
//                   대표자: item.ceo,
//                   전화번호: item.phone,
//                   주소: item.address,
//                   사업자번호: item.businessNumber,
//                   가맹점수: item.storeCount
//                 })
//               );
//               jsonToSheet(arr, "K배달_협력사목록");
//             }}
//           >
//             <Icon name="file excel" /> 엑셀 저장
//           </Button>
//           <Link to="/main/addpublish">
//             <Button size="small" style={{ fontWeight: "600" }}>
//               <Icon name="add" /> 대리점 등록
//             </Button>
//           </Link>
//         </div>
//         <Table celled size="small" style={{ textAlign: "center" }}>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell>No.</Table.HeaderCell>
//               <Table.HeaderCell>분류</Table.HeaderCell>
//               <Table.HeaderCell>회사명</Table.HeaderCell>
//               <Table.HeaderCell>지역</Table.HeaderCell>
//               <Table.HeaderCell>ID</Table.HeaderCell>
//               <Table.HeaderCell>대표자</Table.HeaderCell>
//               <Table.HeaderCell>전화번호</Table.HeaderCell>
//               <Table.HeaderCell>주소</Table.HeaderCell>
//               <Table.HeaderCell>사업자번호</Table.HeaderCell>
//               <Table.HeaderCell>050가맹점수</Table.HeaderCell>
//               <Table.HeaderCell>상태</Table.HeaderCell>
//             </Table.Row>
//           </Table.Header>

//           <Table.Body>
//             {this.state.paginationData ? (
//               <TableItem
//                 data={this.state.paginationData[this.state.activePage - 1]}
//                 activePage={this.state.activePage}
//                 storeData={this.state.storeData}
//               />
//             ) : (
//               <Table.Row>
//                 <Loader active />
//               </Table.Row>
//             )}
//           </Table.Body>

//           <Table.Footer>
//             <Table.Row>
//               <Table.HeaderCell colSpan="11" style={{ textAlign: "center" }}>
//                 {this.state.data && (
//                   <Pagination
//                     activePage={this.state.activePage}
//                     onPageChange={this.handlePaginationChange}
//                     totalPages={Math.ceil(this.state.data.length / 20)}
//                   />
//                 )}
//               </Table.HeaderCell>
//             </Table.Row>
//           </Table.Footer>
//         </Table>
//       </Container>
//     );
//   }
// }

// const mapStateToProps = state => ({
//   user: state.auth.user
// });

// export default compose(
//   connect(mapStateToProps),
//   withFirebase,
//   withRouter
// )(PublishList_2);

// const TableItem = ({ data, storeData }) => {
//   return (
//     <>
//       {data &&
//         data.map((item, index) => (
//           <Row style={{ fontSize: "12px" }} key={index}>
//             <Table.Cell>
//               {/* 1번부터... */}
//               {/* {activePage === 1 ? index + 1 : index + (activePage - 1) * 20 + 1} */}
//               {/* 끝번부터 */}
//               {data.length - index}
//             </Table.Cell>
//             <Table.Cell>{item.level}</Table.Cell>
//             <Table.Cell>
//               <Link to={`/main/publish/${item.id}`}>{item.company}</Link>
//             </Table.Cell>
//             <Table.Cell>{item.region}</Table.Cell>
//             <Table.Cell>{item.id}</Table.Cell>
//             <Table.Cell>{item.ceo}</Table.Cell>
//             <Table.Cell>{item.phone}</Table.Cell>
//             <Table.Cell>{item.address}</Table.Cell>
//             <Table.Cell>{item.businessNumber}</Table.Cell>
//             <Table.Cell>
//               {" "}
//               {storeData &&
//                 storeData
//                   .filter(store => store.publisher.id === item.id)
//                   .filter(
//                     store =>
//                       store &&
//                       store.changeLog &&
//                       store.changeLog[store.changeLog.length - 1].status ===
//                         "사용중"
//                   ).length}
//               /
//               {storeData &&
//                 storeData.filter(store => store.publisher.id === item.id)
//                   .length}
//             </Table.Cell>
//             <Table.Cell>{item.status ? "영업중" : "영업중지"}</Table.Cell>
//           </Row>
//         ))}
//     </>
//   );
// };
