import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './Pages/Login';
import Header from './components/layouts/Header';
import styled from 'styled-components';
import Home from './Pages/Home/Home';
import PrivateRouter from './components/PrivateRouter';
import Footer from './components/layouts/Footer';
import PublishAdd from './Pages/Publisher/PublishAdd';
import PublishList from './Pages/Publisher/PublishList';
import PublishDetail from './Pages/Publisher/PublishDetail';
import PublishEdit from './Pages/Publisher/PublishEdit';
import StoreEdit from './Pages/Store/StoreEdit';
import StoreAdd from './Pages/Store/StoreAdd';
import StoreDetail from './Pages/Store/StoreDetail';
import StoreList from './Pages/Store/StoreList';
import Admin from './Pages/Admin/Admin';
import StoreStatusList from './Pages/Store/StoreStatusList';
import OrderMonth from './Pages/Order/OrderMonth';
import OrderPublish from './Pages/Order/OrderPublish';
import OrderStore from './Pages/Order/OrderStore';
import OrderOneStore from './Pages/Order/OrderOneStore';
import Notice from './Pages/Notice/Notice';
import AddNotice from './Pages/Notice/AddNotice';
import NoticeDetail from './Pages/Notice/NoticeDetail';
import EditNotice from './Pages/Notice/EditNotice';
import ChangePw from './Pages/Publisher/ChangePw';
import PublishList_1 from './Pages/Publisher/PublishList_1';
import StoreList_1 from './Pages/Store/StoreList_1';
import Ask from './Pages/Ask/Ask';
import AddAsk from './Pages/Ask/AddAsk';
import AskDetail from './Pages/Ask/AskDetail';
import EditAsk from './Pages/Ask/EditAsk';
import PublishList_2 from './Pages/Publisher/PublishList_2';
import OrderMonth_1 from './Pages/Order/OrderMonth_1';
import OrderMonth_2 from './Pages/Order/OrderMonth_2';
import OrderMonth_3 from './Pages/Order/OrderMonth_3';
import OrderPublishOne from './Pages/Order/OrderPublishOne';
import Home_head from './Pages/Home/Home_head';
import Home_dist from './Pages/Home/Home_dist';
import Home_daeri from './Pages/Home/Home_daeri';
import OrderStoreId from './Pages/Order/OrderStoreId';
import OrderStore_head from './Pages/Order/OrderStore_head';
import StatisticPublish from './Pages/Statistic/StatisticPublish';
import StatisticRegion from './Pages/Statistic/StatisticRegion';
import StoreList_Head from './Pages/Store/StoreList_Head';
import VnListAdmin from './Pages/Admin/VnListAdmin';
import Test from './Pages/Admin/Test';
import StoreStatusList_app from './Pages/Store/StoreStatusList_app';

const Contents = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class App extends Component {
  render() {
    return (
      <>
        <Route exact path="/main/:path" component={Header} />
        <Route exact path="/main/:path/:path" component={Header} />
        <Contents>
          <Switch>
            <PrivateRouter exact path="/main/home" component={Home} />
            <PrivateRouter exact path="/main/home_head" component={Home_head} />
            <PrivateRouter exact path="/main/home_dist" component={Home_dist} />
            <PrivateRouter exact path="/main/home_pub" component={Home_daeri} />
            {/* Admin */}
            <PrivateRouter exact path="/main/admin" component={Admin} />
            <PrivateRouter exact path="/main/admin_static_pub" component={StatisticPublish} />
            <PrivateRouter exact path="/main/admin_static_reg" component={StatisticRegion} />
            <PrivateRouter exact path="/main/admin_vn" component={VnListAdmin} />
            <PrivateRouter exact path="/main/test" component={Test} />
            {/* 협력사 */}
            <PrivateRouter exact path="/main/addpublish" component={PublishAdd} />
            <PrivateRouter exact path="/main/listpublish" component={PublishList} />
            <PrivateRouter exact path="/main/publish_list_1" component={PublishList_1} />
            <PrivateRouter exact path="/main/publish_list_2" component={PublishList_2} />

            <PrivateRouter exact path="/main/publish/:id" component={PublishDetail} />
            <PrivateRouter exact path="/main/editpublish/:id" component={PublishEdit} />
            <PrivateRouter exact path="/main/changepw" component={ChangePw} />
            {/* 가맹점 */}
            <PrivateRouter exact path="/main/addstore" component={StoreAdd} />
            <PrivateRouter exact path="/main/liststore" component={StoreList} />
            <PrivateRouter exact path="/main/store_list_1" component={StoreList_1} />
            <PrivateRouter exact path="/main/store_list_2" component={StoreList_Head} />
            <PrivateRouter exact path="/main/liststore/:id" component={StoreStatusList} />
            <PrivateRouter exact path="/main/liststore_app/:id" component={StoreStatusList_app} />

            <PrivateRouter exact path="/main/store/:id" component={StoreDetail} />
            <PrivateRouter exact path="/main/editstore/:id" component={StoreEdit} />
            {/* 주문조회 */}
            <PrivateRouter exact path="/main/ordermonth" component={OrderMonth} />
            <PrivateRouter exact path="/main/ordermonth_1" component={OrderMonth_1} />
            <PrivateRouter exact path="/main/ordermonth_2" component={OrderMonth_2} />
            <PrivateRouter exact path="/main/ordermonth_3" component={OrderMonth_3} />
            <PrivateRouter exact path="/main/orderpub" component={OrderPublish} />
            <PrivateRouter exact path="/main/order_pub_one" component={OrderPublishOne} />
            <PrivateRouter exact path="/main/orderstore" component={OrderStore} />
            <PrivateRouter exact path="/main/orderstore_head" component={OrderStore_head} />
            <PrivateRouter exact path="/main/orderstore/:id" component={OrderStoreId} />
            <PrivateRouter exact path="/main/order/:code" component={OrderOneStore} />
          </Switch>

          {/* 게시판 */}
          <Switch>
            <PrivateRouter exact path="/main/notice" component={Notice} />
            <PrivateRouter exact path="/main/addnotice" component={AddNotice} />
            <PrivateRouter exact path="/main/notice/:id" component={NoticeDetail} />
            <PrivateRouter exact path="/main/editnotice/:id" component={EditNotice} />
            <PrivateRouter exact path="/main/ask" component={Ask} />
            <PrivateRouter exact path="/main/addask" component={AddAsk} />
            <PrivateRouter exact path="/main/ask/:id" component={AskDetail} />
            <PrivateRouter exact path="/main/editask/:id" component={EditAsk} />
          </Switch>
        </Contents>
        <Route exact path="/" component={Login} />
        <Route exact path="/" component={Footer} />
      </>
    );
  }
}

export default App;
