// 공백 제거
export const deleteEmpty = str => {
  return str.replace(/ /gi, '');
};

// 전화번호 - 삭제
export const formatDeleteDash = number => {
  return number.replace(/-/gi, '');
};

export const formatPhone = number => {
  if (number) {
    const noEmptyNum = deleteEmpty(number);
    if (noEmptyNum.length === 12) {
      return noEmptyNum.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (noEmptyNum.length === 11) {
      return noEmptyNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (noEmptyNum.length === 10) {
      return noEmptyNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    } else if (noEmptyNum.length === 9) {
      return noEmptyNum.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
    } else {
      return noEmptyNum;
    }
  }
};

export const formatMiddleStar = (number, isReveal) => {
  if (isReveal) return formatPhone(number);
  if (number) {
    const noEmptyNum = deleteEmpty(number);
    if (noEmptyNum.length === 12) {
      const changed = noEmptyNum.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3').split('-');
      return `${changed[0]}-****-${changed[2]}`;
    } else if (noEmptyNum.length === 11) {
      const changed = noEmptyNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3').split('-');
      return `${changed[0]}-****-${changed[2]}`;
    } else if (noEmptyNum.length === 10) {
      const changed = noEmptyNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3').split('-');
      return `${changed[0]}-***-${changed[2]}`;
    } else if (noEmptyNum.length === 9) {
      const changed = noEmptyNum.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3').split('-');
      return `${changed[0]}-***-${changed[2]}`;
    }
  }
};

export const dateFormat = date => {
  if (typeof date === 'string') return date.split('.')[0].replace('T', ' ');
  const nineHoursBefore = new Date(date.toDate().getTime() - 32400000);

  return nineHoursBefore.toLocaleDateString() + nineHoursBefore.toLocaleTimeString();
};
