export const checkBn = value => {
  const valueMap = value
    .replace(/-/gi, "")
    .split("")
    .map(function(item) {
      return parseInt(item, 10);
    });

  if (valueMap.length === 10) {
    const keys = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let checkSum = 0;

    for (let i = 0; i < keys.length; i++) {
      checkSum += keys[i] * valueMap[i];
    }

    checkSum += parseInt((keys[8] * valueMap[8]) / 10, 10);
    // return Math.floor(valueMap[9]) === 10 - (checkSum % 10);
    return Math.floor(valueMap[9]) === (10 - (checkSum % 10)) % 10;
  }

  return false;
};
