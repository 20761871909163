import React, { useState, useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Table from '../../components/Table/Table';
import { Icon, Loader, Button } from 'semantic-ui-react';
import { FirebaseContext } from '../../firebase';
import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { formatPhone, dateFormat } from '../../common/format';
import { jsonToSheet } from '../Admin/Admin';

const Container = styled.div`
  width: 95%;
  padding: 2rem 2.5%;
`;

const StoreList = props => {
  const [data, setData] = useState(null);
  const { db } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.auth, []);
  const [checkedList, setCheckedList] = useState(null);

  const loadData = () => {
    let data = [];

    db.collection('stores')
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshots => {
        snapshots.forEach(doc =>
          data.push({
            ...doc.data(),
            publisherName: doc.data().publisher.company || doc.data().headquarter.company,
            numStatus: doc.data().changeLog
              ? doc.data().changeLog[doc.data().changeLog.length - 1].status
              : '미사용',
            appStatus:
              (doc.data().changeLog_app &&
                doc.data().changeLog_app[doc.data().changeLog_app.length - 1].status) ||
              '미사용'
          })
        );
        setData(data);
      });
  };

  useEffect(() => {
    if (user.level === '총판' || user.level === '대리점') {
      props.history.push('/main/store_list_1');
    } else if (user.level === '본사') {
      props.history.push('/main/store_list_2');
    } else {
      loadData();
    }
  }, []);

  const exportExcel = () => {
    const arr = [];
    data.forEach((
      item //arr.push(item)
    ) =>
      arr.push({
        협력사명: item.publisher.company,
        가맹점코드: item.code,
        회사명: item.company,
        대표자: item.ceo,
        일반전화번호: item.tel,
        [`050전화번호`]: item.phone050,
        [`050전화번호(앱)`]: item.phone050_app,
        사업자번호: item.businessNumber,
        [`050가입일자`]: item.changeLog && item.changeLog[0].createdAt,
        책자상태: item.numStatus,
        앱상태: item.appStatus
      })
    );
    jsonToSheet(arr, 'K배달_가맹점목록');
  };

  // 광고중지
  const stopAd = () => {
    if (!checkedList || checkedList.length === 0) return alert('선택 항목이 없습니다.');
    let result = window.confirm(`${checkedList.length}개 항목 광고중지 처리 하시겠습니까?`);
    if (!result) return null;
    const batch = db.batch();
    checkedList.map(item => {
      const ref = db.collection('stores').doc(item.code);
      const existLogArr = item.changeLog_app || [];
      batch.update(ref, {
        changeLog_app: [
          ...existLogArr,
          {
            createdAt: new Date(),
            detail: '상태변경',
            status: '광고중지',
            storeName: item.company,
            editedUser: user.id
          }
        ]
      });
      return null;
    });
    batch
      .commit()
      .then(() => {
        setData(null);
        setTimeout(() => {
          loadData();
        }, 200);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // 승인완료
  const completeAccess = () => {
    if (!checkedList || checkedList.length === 0) return alert('선택 항목이 없습니다.');
    let result = window.confirm(`${checkedList.length}개 항목 승인완료 처리 하시겠습니까?`);
    if (!result) return null;
    const batch = db.batch();
    checkedList.map(item => {
      const ref = db.collection('stores').doc(item.code);
      const existLogArr = item.changeLog_app || [];
      const changeLog_app = [
        ...existLogArr,
        {
          createdAt: new Date(),
          detail: '상태변경',
          status: '승인완료',
          storeName: item.company,
          editedUser: user.id
        }
      ];
      batch.update(ref, {
        changeLog_app
      });
      return null;
    });
    batch
      .commit()
      .then(() => {
        setData(null);
        setTimeout(() => {
          loadData();
        }, 200);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Container>
      <Helmet>
        <title>가맹점 - K배달 관리자시스템</title>
      </Helmet>
      <div
        style={{
          display: 'flex',
          marginBottom: '1rem',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: '600',
            color: '#0c8599',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Icon name="table" />
          <span>가맹점</span>
          {data && (
            <span style={{ fontSize: '12px', color: 'gray', marginLeft: '1rem' }}>
              총 {data.length} 개
            </span>
          )}
        </div>
        <div style={{ paddingRight: 0, marginRight: 0 }}>
          <Button
            size="small"
            onClick={stopAd}
            style={{
              fontWeight: '600',
              background: 'white',
              color: 'gray'
            }}
          >
            <Icon name="ban" />
            광고 중지
          </Button>
          <Button
            size="small"
            onClick={completeAccess}
            style={{
              fontWeight: '600',
              background: 'white',
              color: 'gray'
            }}
          >
            <Icon name="check square" />
            승인 완료
          </Button>

          <Button
            size="small"
            style={{ fontWeight: '600', background: 'white', color: 'gray' }}
            onClick={exportExcel}
          >
            <Icon name="file excel" /> 엑셀 저장
          </Button>
          <Link to="/main/addstore">
            <Button
              size="small"
              style={{
                fontWeight: '600',
                marginRight: 0,
                background: 'white',
                color: 'gray'
              }}
            >
              <Icon name="add" /> 가맹점 등록
            </Button>
          </Link>
        </div>
      </div>

      {data ? (
        <Table
          checkbox
          data={data}
          index
          perPage={20}
          setCheckedList={setCheckedList}
          headers={[
            {
              title: '협력사명',
              value: 'publisherName',
              render: item => item.publisher.company || `본사/${item.headquarter.company}`
            },
            { title: '가맹점코드', value: 'code', width: 100, noRender: true },
            {
              title: '가맹점명 [코드]',
              width: 130,
              value: 'company',
              render: item => (
                <>
                  <Link to={`/main/store/${item.code}`} target="_blank">
                    <div>{item.company}</div>
                  </Link>
                  <div style={{ color: 'gray', fontSize: '12px' }}>[{item.code}]</div>
                </>
              )
            },
            { title: '대표자', value: 'ceo', width: 80 },
            { title: '일반전화번호', value: 'tel' },
            {
              title: '050전화번호',
              value: 'phone050',
              render: item => formatPhone(item.phone050)
            },
            {
              title: '050가입일자',
              notSearch: true,
              render: item =>
                item.changeLog && dateFormat(item.changeLog[0].createdAt).split(' ')[0]
            },
            {
              title: '상태',
              value: 'numStatus',
              render: item => (
                <Link
                  to={`/main/liststore/${item.code}`}
                  style={{
                    color:
                      item.changeLog &&
                      item.changeLog[item.changeLog.length - 1].status !== '사용중' &&
                      '#ff6b6b'
                  }}
                >
                  {item.numStatus}
                </Link>
              ),
              notSearch: true,
              width: 80
            },
            {
              title: '콕콕114 상태',
              value: 'appStatus',
              render: item => {
                return <Link to={`/main/liststore_app/${item.code}`}>{item.appStatus}</Link>;
              }
            }
          ]}
        />
      ) : (
        <Loader active />
      )}
    </Container>
  );
};

export default withRouter(StoreList);
