import React, { Component } from "react";
import styled from "styled-components";
import { Input, Button, Icon, Loader } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  width: 90%;
  background: white;
  margin-top: 2rem;
  padding: 3rem 2rem;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #dfdfdf;
  .flex {
    display: flex;
  }
`;

class PublishEdit extends Component {
  state = {
    id: "",
    isLoading: false,
    pw: null,
    newPw: null,
    newPw2: null
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({
        id: nextProps.user.id
      });
    }
  }
  componentDidMount() {
    if (this.props.user) {
      this.setState({
        id: this.props.user.id
      });
    }
  }

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSelectChange = e => {
    this.setState({
      level: e.target.innerText
    });
  };

  onSubmit = () => {
    const { id, pw, newPw, newPw2 } = this.state;
    if (!pw || !newPw || !newPw2) {
      alert("빈칸을 모두 채워주세요");
      return null;
    }
    if (newPw !== newPw2) {
      alert("변경할 비밀번호가 다릅니다.");
      return null;
    }
    this.setState({
      isLoading: true
    });

    let newData = {
      pw,
      id
    };

    axios.defaults.baseURL =
      "https://us-central1-phone-fa49a.cloudfunctions.net/api";
    axios
      .post("/login", newData)
      .then(res => {
        // console.log(res);
        if (res.data.success) {
          axios
            .post("/edit", { id, pw: newPw })
            .then(result => {
              // console.log(result);
              this.setState({
                isLoading: false
              });
              if (result.data.success) {
                this.props.history.push(`/main/home`);
              } else {
                alert(result.data.error);
              }
            })
            .catch(err => console.log(err));
        } else {
          alert(res.data.error);
        }
      })
      .catch(err => console.log(err));
  };

  render() {
    return (
      <Container>
        <div
          style={{
            borderBottom: "1.5px solid lightgray",
            padding: "1rem 3rem",
            fontSize: "17px",
            marginBottom: "2rem",
            fontWeight: "600"
          }}
        >
          <Icon name="edit" style={{ marginRight: "1rem" }} />
          비밀번호 변경
        </div>

        <div className="flex">
          <InputSet
            name="아이디"
            id="id"
            onChange={this.onInputChange}
            value={this.state.id}
            disabled={true}
          />
          <InputSet name="기존비밀번호" id="pw" onChange={this.onInputChange} />
        </div>
        <div className="flex">
          <InputSet
            name="신규비밀번호"
            id="newPw"
            onChange={this.onInputChange}
          />
        </div>
        <div className="flex">
          <InputSet
            name="신규비밀번호확인"
            id="newPw2"
            onChange={this.onInputChange}
          />
        </div>
        <div
          className="flex"
          style={{ justifyContent: "center", marginTop: "1rem" }}
        >
          <Button
            primary
            style={{ minWidth: "120px", width: "40%" }}
            onClick={() => {
              this.onSubmit();
            }}
          >
            {this.state.isLoading ? (
              <Loader active inline size="mini" />
            ) : (
              "등록"
            )}
          </Button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(PublishEdit);

const InputSet = ({ name, onChange, id, placeholder, value, disabled }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "55%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      {" "}
      <div>{name}</div>
      <Input
        placeholder={placeholder || name}
        style={{ width: "75%", marginLeft: "1rem" }}
      >
        <input
          name={id}
          onChange={onChange}
          defaultValue={value}
          disabled={disabled}
          type={!disabled && "password"}
        />
      </Input>
    </div>
  );
};
