import React, { Component } from "react";
import styled from "styled-components";
import { Button, Icon, Loader } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";

const Container = styled.div`
  width: 90%;
  padding: 3rem 2rem;
  .flex {
    display: flex;
  }
`;

class PublishDetail extends Component {
  state = {
    isLoading: false
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id === ":id") {
      this.props.history.push(`/main/listpublish`);
    } else {
      this.loadData();
    }
  }
  loadData = () => {
    const { id } = this.props.match.params;
    this.props.firebase.db
      .collection("users")
      .doc(id)
      .get()
      .then(doc =>
        this.setState({
          data: { ...doc.data(), pw: "" }
        })
      );
  };

  render() {
    const { data } = this.state;
    return (
      <Container>
        <div
          style={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#0c8599",
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
            paddingBottom: "0.5rem",
            borderBottom: "2px solid #ccc"
          }}
        >
          <span style={{ marginLeft: "1rem", marginRight: "0.5rem" }}>
            협력사 상세
          </span>
        </div>
        {data ? (
          <>
            <div className="flex">
              <InputSet name="아이디" value={data.id} />
              <InputSet name="분류" value={data.level} />
            </div>
            <div className="flex">
              <InputSet
                name="소속 본사"
                value={data.headquarter && data.headquarter.company}
              />
              <InputSet
                name="소속 총판"
                value={data.publisher && data.publisher.company}
              />
            </div>
            <div className="flex">
              <InputSet name="지역" value={data.region} />
            </div>
            <div className="flex">
              <InputSet name="회사명" value={data.company} />
              <InputSet name="대표자명" value={data.ceo} />
            </div>
            <div className="flex">
              <InputSet name="전화번호" value={data.phone} />
              <InputSet name="사업자번호" value={data.businessNumber} />
            </div>
            <div className="flex">
              <InputSetLarge name="주소" value={data.address} />
            </div>
            <div className="flex">
              <InputSetLarge name="이메일주소" value={data.email} />
            </div>
            <div className="flex">
              <InputSet name="은행명" value={data.bank} />
              <InputSet name="계좌번호" value={data.account} />
            </div>
            <div className="flex">
              <InputSet
                name="상태"
                value={data.status ? "영업중" : "영업중지"}
              />
            </div>
            <div className="flex">
              <InputSet
                name="가입일"
                value={
                  data.createdAt && data.createdAt.toDate().toLocaleDateString()
                }
              />
              <InputSet
                name="수정일"
                value={
                  data.editedAt && data.editedAt.toDate().toLocaleDateString()
                }
              />
            </div>
            <div
              className="flex"
              style={{ justifyContent: "center", marginTop: "2rem" }}
            >
              <Link to={`/main/editpublish/${data.id}`}>
                <Button size="small" style={{ marginRight: "1rem" }}>
                  <Icon name="edit outline" />
                  수정하기
                </Button>
              </Link>
              <Link to={`/main/listpublish`}>
                <Button size="small">
                  <Icon name="th list" />
                  목록으로
                </Button>
              </Link>
            </div>
          </>
        ) : (
          <div
            className="flex"
            style={{ justifyContent: "center", marginTop: "2rem" }}
          >
            <Loader active />
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(PublishDetail);

const InputSet = ({ name, value }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "50%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      <div style={{ color: "gray" }}>{name}</div>
      <div
        style={{
          width: "75%",
          borderBottom: "1px solid lightgrey",
          textAlign: "center",
          marginLeft: "1rem",
          paddingBottom: "0.3rem",
          minHeight: "20px"
        }}
      >
        {value}
      </div>
    </div>
  );
};

const InputSetLarge = ({ name, value }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0.5rem 1rem",
        justifyContent: "flex-end"
      }}
    >
      {" "}
      <div style={{ color: "gray" }}>{name}</div>
      <div
        style={{
          minWidth: "200px",
          width: "88%",
          borderBottom: "1px solid lightgrey",
          textAlign: "left",
          paddingLeft: "11%",
          marginLeft: "1rem",
          paddingBottom: "0.3rem",
          minHeight: "20px"
        }}
      >
        {value}
      </div>
    </div>
  );
};
