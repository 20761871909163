import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { setPath } from "../store/modules/auth";
import { useSelector, useDispatch } from "react-redux";

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  const { isAuthenticated } = useSelector(state => state.auth, []);
  const dispatch = useDispatch();

  const setPrevPath = path => {
    dispatch(setPath(path));
  };

  useEffect(() => {
    setPrevPath(rest.path);
  }, []);

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
