import React, { Component } from "react";
import styled from "styled-components";
import { Button, Icon, Loader } from "semantic-ui-react";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import Table from "../../components/Table/Table";
import { sortBy } from "lodash";

const Container = styled.div`
  width: 85%;
  margin-bottom: 2rem;
  padding: 3rem 2rem;
  .flex {
    display: flex;
    align-items: center;
  }
`;

class Notice extends Component {
  state = {
    data: null
  };
  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { user } = this.props;
    let data = [];
    this.props.firebase.db
      .collection("notice")
      .get()
      .then(snapshots => {
        snapshots.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id });
        });
        let result = [];
        if (user.level === "관리자") {
          result = data;
        } else if (user.level === "본사") {
          // 여기서 user는 user level 임
          result.push(...data.filter(item => item.user === "관리자"));
          result.push(...data.filter(item => item.userId === user.id));
        } else {
          result.push(...data.filter(item => item.user === "관리자"));
          result.push(
            ...data.filter(item => item.userId === user.headquarter.id)
          );
        }

        this.setState({
          data: sortBy(result, ["date"]).reverse()
        });
      });
  };

  render() {
    const { user } = this.props;
    const { data } = this.state;
    return (
      <Container>
        <div
          style={{
            marginBottom: "2rem",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#0c8599",
            fontSize: "16px"
          }}
        >
          <div>
            <Icon name="bell" style={{ marginRight: "1rem" }} />
            <span>공지사항</span>
          </div>
        </div>

        <div
          className="flex"
          style={{ justifyContent: "flex-end", marginBottom: "1rem" }}
        >
          {user.level === "관리자" && (
            <Link to="/main/addnotice">
              <Button size="small" style={{ fontWeight: "600" }}>
                <Icon name="add" /> 등록
              </Button>
            </Link>
          )}
          {user.level === "본사" && (
            <Link to="/main/addnotice">
              <Button size="small" style={{ fontWeight: "600" }}>
                <Icon name="add" /> 등록
              </Button>
            </Link>
          )}
        </div>
        {data ? (
          <Table
            data={data}
            index
            perPage={15}
            headers={[
              {
                title: "제목",
                value: "title",
                render: item => (
                  <Link to={`/main/notice/${item.id}`}>{item.title}</Link>
                )
              },
              {
                title: "작성자",
                value: "user",
                width: 120
              },
              {
                title: "작성일",
                notSearch: true,
                width: 120,
                value: "date",
                render: item => item.date.toDate().toLocaleDateString()
              },
              {
                title: "상태",
                notSearch: true,
                align: "center",
                value: "status",
                width: 80,
                render: item => (item.status === true ? "Y" : "N")
              }
            ]}
          />
        ) : (
          <Loader active />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withRouter
)(Notice);
